import { Link, useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import defaultImage from "../../assets/images/defaultUser.png";
import moment from "moment";
import React, { useState } from "react";
import { setBgLoader, setPublicReceived } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import { getComments, getPublicDreamDonations } from "../../services/api";
import DreamMakersModal from "../DreamMakersModal";
import "../../styles/Sliders.css";
import { UsersModule } from "../../types/redux";
import { calculateDreamProgress } from "../../utils/calculateDreamProgress";
import { SliderCardProps } from "../../types/models";
import { generateTooltipKey } from "../../utils/gerenateTooltipKey";
import TooltipDetails from "../Tooltip/TooltipDetails";
import { fulfillCheck } from "src/utils/fulfillCheck";
import ResponsiveCommentsModal from "../Comments/ResponsiveCommentsModal";
import { setActiveReply, updateDreamCommentsCount } from "src/store/action";
import Carousel from "react-multi-carousel";
import { leftArrow, rightArrow } from "src/consts/SvgIcon";
import useIsMobile from "../../hooks/useIsMobile";
import { commentType } from "src/consts/commentsTypeEnum";

const DreamCard = ({ dream, isFulfilled }: SliderCardProps) => {
  const progress = calculateDreamProgress(dream);
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const isDreamFulfilled = fulfillCheck(allFulfilled, dream, dream?.user?.id);
  const avatarUrl = dream.images?.[0]?.avatarUrl || defaultImage;
  const isMobile = useIsMobile(768);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalProps, setModalProps] = useState(false);
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(Boolean);
  const [currentUserComment, setComment] = useState<string>("");
  const tooltipKey = generateTooltipKey(dream?.user?.id);
  const currentUser = useSelector(
    (state: UsersModule) => state.usersModule
  ).user;
  const mainImage = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;

  const progressClick = () => {
    dispatch(setPublicReceived({}));
    setShow((prev) => !prev);
    getPublicDreamDonations(dream?.id, 1).then((res) => {
      dispatch(setPublicReceived(res?.data));
    });
  };

  const openCommentModal = (dreamId) => {
    setComment("");
    dispatch(setActiveReply(""));
    dispatch(setBgLoader(true));
    setModalProps(true);
    getComments(dreamId, 1, 10).then((res) => {
      setComments(res?.data?.results);
      setHasMore(res?.data?.meta?.hasNextPage);
      setModalProps(true);
      dispatch(updateDreamCommentsCount(dreamId, res?.data?.meta?.itemCount));
    });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 1,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1440, min: 750 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 750, min: 660 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 660, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const ButtonGroupLeftDreams = (e) => {
    return (
      <div
        className="carousel-button-group cursor-pointer"
        onClick={() => e.onClick()}
      >
        <div className="left-button-dreams left-button-dream-card">
          {leftArrow("8", "16", "#fff")}
        </div>
      </div>
    );
  };
  const ButtonGroupRightDreams = (e) => (
    <div
      className="carousel-button-group cursor-pointer"
      onClick={() => e.onClick()}
    >
      <div className="right-button-dreams right-button-dream-card">
        {rightArrow("8", "16", "#fff")}
      </div>
    </div>
  );

  return (
    <div className="dream-card shadow-card rounded-3">
      <div className="dream-card-header">
        <Link to={`/dashboard/public-page/${dream?.user?.id}`}>
          <img
            src={dream?.user?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="dream-avatar"
          />
        </Link>
        <div className="dream-name-and-date">
          <Link to={`/dashboard/public-page/${dream?.user?.id}`}>
            <span
              className="dream-card-name text-underline"
              data-tooltip-id={tooltipKey}
            >
              {`${dream?.user?.firstName || "No Name"} ${
                dream.user?.lastName || ""
              }`}
            </span>
          </Link>
          <TooltipDetails
            userId={dream?.user?.id}
            isFulfilled={isFulfilled}
            allFulfilled={allFulfilled}
            tooltipKey={tooltipKey}
          />
          <p className="dream-card-time-ago">
            {moment(dream.createdAt)?.fromNow(true)} ago
          </p>
        </div>
      </div>
      {!isMobile && dream?.images?.length ? (
        <Carousel
          responsive={responsive}
          arrows
          className="dream-carousel-images"
          showDots={true}
          draggable={false}
          swipeable={false}
          customLeftArrow={<ButtonGroupLeftDreams />}
          customRightArrow={<ButtonGroupRightDreams />}
          dotListClass="dot-dream-style"
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {dream?.images.map((img) => {
            return (
              <img
                key={img?.id}
                className="dream-image cursor-pointer"
                src={img?.avatarUrl}
                alt="Dream"
                draggable={false}
                onClick={() => openCommentModal(dream?.id)}
              />
            );
          })}
        </Carousel>
      ) : (
        <img
          className="dream-image cursor-pointer"
          src={avatarUrl}
          alt="Dream"
          onClick={() => openCommentModal(dream?.id)}
        />
      )}
      <div className="dream-card-main-container">
        <div
          className="text-dark cursor-pointer"
          style={{ width: "-webkit-fill-available" }}
        >
          <span className="my-span lh-sm dream-description">
            {dream?.title || "No Title"}
          </span>
        </div>
        <div>
          <div className="dream-progress-container">
            <ProgressBar
              onClick={() => progressClick()}
              style={{ width: "80%", height: "10px", cursor: "pointer" }}
              now={progress}
              variant={"gradient"}
              className={"black-hover"}
            />
            <p className="dream-percentage">
              {isNaN(progress) ? 0 : progress}%
            </p>
          </div>
          {publicReceived?.results?.length ? (
            <DreamMakersModal
              donations={publicReceived}
              show={show}
              setShow={setShow}
              dreamId={dream.id}
              card={true}
            />
          ) : (
            <></>
          )}
          <div className="dream-fulfillment-info">
            <div className="dream-fulfillment-container">
              <p>Fulfilled</p>
              {typeof dream.donations !== "object" ? (
                <p>{(dream.donations as unknown as number) || "0"}</p>
              ) : (
                <p>{dream.donations.length || "0"}</p>
              )}
            </div>
            <div className="dream-fulfillment-container">
              <p>Received</p>
              <p>{dream.amountReceived || "0"}</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between sliders-button">
          <div
            className="custom-gradient sliders-button"
            onClick={() =>
              isDreamFulfilled
                ? navigate(`/dashboard/public-page/${dream?.user?.id}`)
                : isFulfilled(dream?.id)
            }
          >
            <div className="dashboard-card-buttons-text">
              <span>
                {isDreamFulfilled
                  ? "View Profile"
                  : `Fulfill ${
                      dream?.user?.gender === "FEMALE" ? "Her" : "His"
                    } Dream`}
              </span>
            </div>
          </div>
          <ResponsiveCommentsModal
            dream={dream}
            modalProps={modalProps}
            setModalProps={setModalProps}
            setComments={setComments}
            comments={comments}
            setHasMore={setHasMore}
            hasMore={hasMore}
            mainImage={mainImage}
            isFulfilled={isFulfilled}
            currentUser={currentUser}
            allFulfilled={allFulfilled}
            progress={progress}
            user={dream?.user}
            type={commentType.dream}
            currentUserComment={currentUserComment}
            setComment={setComment}
          />
        </div>
      </div>
    </div>
  );
};

export default DreamCard;
