import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { SimpleImageModalProps } from "src/types/components-props";

const SimpleImageModal = (props: SimpleImageModalProps) => {
  const [url] = useState(props.imageUrl);
  const [showModal, setShowModal] = props.childRef;

  React.useEffect(() => {
    if (props.drambigImages) {
      setShowModal(true);
    }
  }, [props.drambigImages, setShowModal]);

  return (
    <Modal
      size="lg"
      centered
      backdrop={true}
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <span
        onClick={() => setShowModal(false)}
        className="fa-close-image modal-default-close"
      />
      <img
        src={url}
        className="img-fluid"
        style={{
          width: "100%",
          height: "600px",
          objectFit: "contain",
          borderRadius: "10px 0 0 10px",
        }}
        alt="Responsive"
      />
    </Modal>
  );
};

export default SimpleImageModal;
