import { Card, Dropdown, Nav, Tab } from "react-bootstrap";
import Button from "@restart/ui/Button";
import {
  allread,
  closeIcon,
  notificationArrow,
  notificationIcon,
} from "../../consts/SvgIcon";
import React, { useEffect, useState } from "react";
import { getNotification, patchNotification } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setNotifications } from "../../store/users";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import Index from "../Notifications";
import moment from "moment";
import { UsersModule } from "src/types/redux";

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const data = useSelector(
    (state: UsersModule) => state.usersModule
  ).notifications;
  useDisableBodyScroll(show);

  useEffect(() => {
    getNotification(1).then((r) => {
      dispatch(setNotifications(r?.data?.results));
    });
  }, []);

  const markAllRead = () => {
    patchNotification().then(() =>
      getNotification(1).then((r) => {
        dispatch(setNotifications(r?.data?.results));
      })
    );
  };

  const isToday = (date) =>
    moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");

  const mapNotifications = (data) =>
    data?.map((el) => <Index el={el} key={el?.id} setShow={setShow} />);

  const sortedData = (notifications) => {
    const [today, thisWeek] = notifications?.reduce(
      ([today, thisWeek], elem) => {
        return isToday(elem?.createdAt)
          ? [[...today, elem], thisWeek]
          : [today, [...thisWeek, elem]];
      },
      [[], []]
    );

    return (
      <div className="d-flex flex-column gap-3">
        {!!today?.length && <span className="text-dark">Today</span>}
        {mapNotifications(today)}
        {!!thisWeek?.length && <span className="text-dark">This week</span>}
        {mapNotifications(thisWeek)}
      </div>
    );
  };

  //fix for mobile scroll under the modal for notifications.
  const preventScrollOnMobile = (e) => {
    if (show) {
      if (!document.querySelector("#touchmove").contains(e.target)) {
        e.preventDefault();
        e.stopPropagation();
        e.returnValue = false;
        return false;
      }
    }
  };

  useEffect(() => {
    const eventOptions = { passive: false } as EventListenerOptions;

    if (typeof window !== "undefined") {
      document
        .querySelector("body")
        ?.addEventListener("touchmove", preventScrollOnMobile, eventOptions);
      return () => {
        document
          .querySelector("body")
          ?.removeEventListener(
            "touchmove",
            preventScrollOnMobile,
            eventOptions
          );
      };
    }
  }, [show]);

  return (
    <Dropdown
      className="nav-item"
      onToggle={(e) => {
        setShow(e);
      }}
      show={show}
    >
      <Dropdown.Toggle
        bsPrefix="d-flex align-items-center"
        as={Button}
        href="#"
      >
        <div className="notify-container">
          {notificationIcon()}
          {data?.find((el) => !el.seen) && (
            <div className="notify-circle translate-middle" />
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="sub-drop notification-container">
        <Card
          className="m-0 notification-padding position-relative"
          style={{ lineHeight: "initial" }}
        >
          <div className="notification-arrow">{notificationArrow()}</div>
          <Tab.Container defaultActiveKey="first">
            <Card.Header className="d-flex flex-column p-0 pb-3 gap-3 ">
              <div className="d-flex justify-content-between w-100">
                <h4 className="fw-bold text-dark">Notification</h4>
                <div className="d-flex gap-2">
                  <div className="as-read gap-1" onClick={markAllRead}>
                    {allread()} mark all read
                  </div>
                  <div
                    className="as-read"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    {closeIcon()}
                  </div>
                </div>
              </div>
              <Nav id="notifyLink" className="switch-container">
                <Nav.Link eventKey="first" className="switch-button">
                  All
                </Nav.Link>
                <Nav.Link eventKey="second" className="switch-button">
                  Unread
                </Nav.Link>
              </Nav>
            </Card.Header>
            <Card.Body className="p-0 pt-3" id="touchmove">
              <Tab.Content>
                <Tab.Pane eventKey="first" className="notification-dropdown">
                  {!!data?.length && sortedData(data)}
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="notification-dropdown">
                  {!!data?.length &&
                    sortedData(data?.filter((el) => !el?.seen))}
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default NotificationDropdown;
