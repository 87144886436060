import React from "react";
import "../../../styles/Dashboard.css";
import { Row } from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import NavBarComponent from "../../../components/NavBar/NavBar";
import {useSelector} from "react-redux";
import { UsersModule } from "src/types/redux";

const HowItWorks = () => {
  const navigate = useNavigate();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  return (
    <div>
      {user?.id ? (
        <NavBarComponent />
      ) : (
        <header className="login-header">
          <Row className="p-0 m-0 d-flex w-100 align-items-center">
            <div className="d-flex justify-content-between">
              <div className="d-flex ms-5">
                <div
                  className="fa-logo-icon cursor-pointer"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </Row>
        </header>
      )}
      <div
        className="d-flex flex-column"
        style={{ width: "100vw", height: "100vh" }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "100%",
            height: "100px",
            marginBottom: "35px",
            marginTop: "35px",
          }}
        >
          <span className="faq-text">How it works</span>
        </div>
        <div className="d-flex flex-column about-us-padding">
          <div
            className="about-us-title"
            style={{ paddingTop: "15px", paddingBottom: "20px" }}
          >
            Beiing a Dreamer
          </div>
          <div style={{ marginBottom: "10px" }}>
            <ul className="abous-us-description">
              <li>The first step is to Register.</li>
              <li>
                Set up your personal information that is required and add your
                profile photos.
              </li>
              <li>Choose one of the subscription packages.</li>
              <li>
                Set your dream description, amount and add photos of your dream.
              </li>
              <li>Start your dream fulfilment campaign.</li>
              <li>
                Start Fulfilling other dreams by sharing 1 $tar for a dream.
              </li>
              <li>
                Collect fulfilled dreams to get the option to double the amount
                for your dream.
              </li>
              <li>
                To fulfill and cash out your dream you have to fulfil half of
                the dreams you’ve set.
              </li>
            </ul>
          </div>
        </div>

        <div
          className="d-flex flex-column about-us-padding"
          style={{ height: "100vh" }}
        >
          <div
            className="about-us-title"
            style={{ paddingTop: "10px", paddingBottom: "20px" }}
          >
            Beiing a Dream Angel
          </div>
          <div>
            <ul className="abous-us-description">
              <li>The first step is to Register.</li>
              <li>
                Set up your personal information required and add your profile
                photos.
              </li>
              <li> Choose one of the subscription packages.</li>
              <li>
                Start Fulfilling other's dreams sharing 1 $tar for a dream,
                collect fulfilled dreams.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
