import { Share } from "../../consts/SvgIcon";
import React from "react";

const ShareButton = ({ width, ...props }) => {
  return (
    <div style={{ width }} className="share-button gap-1" {...props}>
      <div>share</div> {Share()}
    </div>
  );
};
export default ShareButton;
