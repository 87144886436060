import { facebook, instagram, linkedin, youtube } from "../../consts/SvgIcon";
import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer-container d-flex flex-column gap-4">
        <div className="d-flex align-items-center justify-content-around flex-wrap gap-4 column-mobile">
          <div className="logo-footer" />
          <div className="d-flex cursor-pointer align-items-center gap-4 column-mobile">
            <a className="st-text lh-1" href="/how-it-works">
              How it works
            </a>
            <a className="st-text lh-1" href="/about-us">
              About us
            </a>
            <a className="st-text" href="/terms-conditions">
              Terms
            </a>
            <a className="st-text" href="/privacy-policy">
              Privacy
            </a>
            <a className="st-text" href="/faq">
              FAQ
            </a>
          </div>
          <div className="social-links-container">
            <span className="follow-us">Follow us:</span>
            <div className="d-flex align-items-center links-container">
              <a
                className="cursor-pointer"
                href="https://www.youtube.com/@dreamerz_net/featured"
              >
                {youtube()}
              </a>
              <a
                className="cursor-pointer"
                href="https://m.facebook.com/dreamerz.net"
              >
                {facebook()}
              </a>
              <a
                className="cursor-pointer"
                href="https://www.instagram.com/dreamerz_net/?igshid=YmMyMTA2M2Y="
              >
                {instagram()}
              </a>
              <a
                className="cursor-pointer"
                href="https://www.linkedin.com/company/102331009/admin/page-posts/published/"
              >
                {linkedin()}
              </a>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center text-center flex-column column-mobile"
          style={{ color: "#A8A6A6" }}
        >
          <span className="footer-year-text">{`© Dreamerz ${currentYear}, All rights reserved`}</span>
        </div>
      </div>
    </>
  );
};
export default Footer;
