import React, { useState } from "react";
import { ReactComponent as Open } from "../../assets/images/Accordion/OpenAccordion.svg";
import { ReactComponent as Close } from "../../assets/images/Accordion/CloseAccordion.svg";

interface InfoAccordionProps {
  title: string;
  description: string;
}

const InfoAccordion = ({ title, description }: InfoAccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={`accourdion-container ${isOpen ? "ease-transformation" : ""}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={`accourdion-text-container`}>
        <span className="accourdion-title">{title}</span>
        <span
          className={`accourdion-description  ${
            isOpen ? "see-description" : ""
          }`}
        >
          {description}
        </span>
      </div>
      <div className="accourdion-btn">
       {isOpen ? <Close /> : <Open />}
      </div>
    </div>
  );
};

export default InfoAccordion;
