import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setShowLoader } from "../../store/users";
import { getEmail } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { UsersModule } from "src/types/redux";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const notify = (message) => toast.success(message);
  const notifyWarning = (message) => toast.warning(message);
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const dispatch = useDispatch();
  const [isSend, setIsSend] = useState(false);
  const [event, setEvent] = useState("");

  const handleReset = (e) => {
    e.preventDefault();
    setEvent(e);
    dispatch(setShowLoader(true));
    const { email } = e.target.elements;
    if (!email.value) {
      dispatch(setShowLoader(false));
      toast.dismiss();
      toast.clearWaitingQueue();
      notifyWarning("Please provide an Email in order to reset your password");
    } else {
      getEmail(email.value).then((res) => {
        if (!res) {
          return;
        }
        setIsSend(true);
        toast.dismiss();
        toast.clearWaitingQueue();
        notify(`${res?.data}, check your email`);
      });
    }
  };
  return (
    <div className="login-wrapper">
      <header className="login-header">
        <Row className="p-0 m-0">
          <Col lg={12} sm={12} md={12} xxl={12} xl={12}>
            <div className="d-flex ms-5">
              <div className="fa-logo-icon" />
            </div>
          </Col>
        </Row>
      </header>
      <body className="bg-img-price" style={{ height: " 93vh" }}>
        <Row className="p-0 m-0">
          <Col
            lg={12}
            sm={12}
            md={12}
            xxl={12}
            xl={12}
            className="d-flex justify-content-center align-self-center"
          >
            <div className="reset-password-container">
              {isSend ? (
                <div className="p-5">
                  <div className="w-100 d-flex align-items-center mb-1">
                    <span className="reset-header-text">
                      Please check your email to continue password reset.
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p
                      className="cursor-pointer span-hover m-0"
                      onClick={() => handleReset(event)}
                    >
                      Resend email?
                    </p>
                    <p
                      className="cursor-pointer span-hover mb-0"
                      onClick={() => navigate("/login")}
                    >
                      Back
                    </p>
                  </div>
                </div>
              ) : (
                <div className="p-4">
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <span className="reset-header-text">Forgot password</span>
                    <p
                      className="cursor-pointer span-hover mb-0"
                      onClick={() => navigate("/login")}
                    >
                      Back
                    </p>
                  </div>
                  <Form className="mt-3" onSubmit={handleReset}>
                    <Form.Group className="form-group">
                      <Form.Label>
                        <span className="reset-password-spans">Email*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className="mb-0 text-black"
                        id="email"
                        placeholder="Enter email"
                      />
                    </Form.Group>
                    <div className="d-flex flex-column justify-content-start align-content-around w-100">
                      <button
                        className="dashboard-card-buttons custom-gradient p-0 mb-2"
                        style={{ width: "100%" }}
                        type="submit"
                      >
                        <span className="dashboard-card-buttons-text">
                          Send email
                        </span>
                      </button>
                      {showLoader && (
                        <div className="d-flex justify-content-center w-100">
                          <img
                            src={loader}
                            alt="loader"
                            style={{ height: "50px" }}
                          />
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </body>
    </div>
  );
};

export default ForgotPassword;
