import { useEffect, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import {
  getActivity,
  getDreamById,
  getAngelNewsfeedPostById,
  getCharitiesById,
  getDreamCommentByCommentId,
  getCharityCommentByCommentId,
  getDreamAngelCommentByCommentId,
} from "src/services/api";
import {
  CharityCommentDto,
  CharityDto,
  DreamCommentDto,
  DreamDto,
  LikedNewsFeedActivityDto,
  NewsFeedDto,
} from "src/services/api/data-contracts";

export const useFetchActivities = (user, activityType) => {
  const queryClient = useQueryClient();
  const [activities, setActivities] = useState([]);

  const {
    data: allActivity,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [activityType, user.id],
    queryFn: ({ pageParam = 1 }) =>
      getActivity(user.id, pageParam, activityType).then((res) => res.data),
    enabled: !!user.id,
    getNextPageParam: (lastPage) =>
      lastPage.meta.hasNextPage ? lastPage.meta.page + 1 : undefined,
  });

  useEffect(() => {
    if (user.id && allActivity?.pages) {
      const fetchActivities = async () => {
        const fetchedActivities = [];
        const idsToFetch = {
          dreams: new Set(),
          newsfeeds: new Set(),
          charities: new Set(),
          dreamComments: [],
          charityComments: [],
          dreamAngelComments: [],
        };

        for (const page of allActivity.pages) {
          for (const activity of page.results) {
            if (
              activity?.likedDream &&
              activity?.likedDream.length > 0 &&
              !idsToFetch?.dreams?.has((activity.likedDream[0] as DreamDto).id)
            ) {
              const dreamId = (activity.likedDream[0] as DreamDto).id;
              idsToFetch.dreams.add(dreamId);
            }
            if (
              activity?.likedCharity &&
              activity?.likedCharity.length > 0 &&
              !idsToFetch?.charities?.has(activity.likedCharity[0].id)
            ) {
              const charityId = activity.likedCharity[0].id;
              idsToFetch.charities.add(charityId);
            }
            if (
              activity.dreamCommentActivities &&
              activity.dreamCommentActivities.length > 0
            ) {
              const dreamId =
                activity.dreamCommentActivities[0]?.dreamComment?.dreamId;
              const commentId =
                activity.dreamCommentActivities[0]?.dreamComment?.parentId;
              if (!activity.dreamCommentActivities[0].dreamComment?.parentId) {
                idsToFetch.dreams.add(dreamId);
              } else {
                idsToFetch.dreams.add(dreamId);
                idsToFetch.dreamComments.push({ dreamId, commentId });
              }
            }
            if (
              activity.charityCommentActivity &&
              activity.charityCommentActivity.length > 0
            ) {
              const charityId =
                activity.charityCommentActivity[0]?.charityComment?.charityId;
              const commentId =
                activity.charityCommentActivity[0]?.charityComment?.parentId;
              if (
                !activity.charityCommentActivity[0]?.charityComment?.parentId
              ) {
                idsToFetch.charities.add(charityId);
              } else {
                idsToFetch.charities.add(charityId);
                idsToFetch.charityComments.push({ charityId, commentId });
              }
            }
            if (
              activity?.dreamAngelCommentActivities &&
              activity?.dreamAngelCommentActivities.length > 0 &&
              activity?.dreamAngelCommentActivities[0]?.dreamAngelComment
                ?.newsFeedId
            ) {
              const newsFeedId =
                activity?.dreamAngelCommentActivities[0]?.dreamAngelComment
                  ?.newsFeedId;
              const commentId =
                activity?.dreamAngelCommentActivities[0]?.dreamAngelComment
                  ?.parentId;
              if (
                !activity?.dreamAngelCommentActivities[0]?.dreamAngelComment
                  ?.parentId
              ) {
                idsToFetch.newsfeeds.add(newsFeedId);
              } else {
                idsToFetch.newsfeeds.add(newsFeedId);
                idsToFetch.dreamAngelComments.push({ newsFeedId, commentId });
              }
            }
            if (
              activity.donation &&
              activity.donation.length > 0 &&
              !idsToFetch.dreams.has(activity.donation[0].dreamId)
            ) {
              const dreamId = activity.donation[0].dreamId;
              idsToFetch.dreams.add(dreamId);
            }
            if (
              activity.likedNewsFeed &&
              activity.likedNewsFeed.length > 0 &&
              !idsToFetch.newsfeeds.has(
                (activity.likedNewsFeed[0] as LikedNewsFeedActivityDto).newsFeed
                  .id
              )
            ) {
              const newsFeedId = (
                activity.likedNewsFeed[0] as LikedNewsFeedActivityDto
              ).newsFeed.id;
              idsToFetch.newsfeeds.add(newsFeedId);
            }
            if (
              activity.wingDonation &&
              activity.wingDonation.length > 0 &&
              !idsToFetch.charities.has(activity.wingDonation[0].charityId)
            ) {
              const charityId = activity.wingDonation[0].charityId;
              idsToFetch.charities.add(charityId);
            }
          }
        }
        const [
          dreams,
          newsfeeds,
          charities,
          dreamComments,
          charityComments,
          dreamAngelComments,
        ] = await Promise.all([
          Promise.all(
            [...idsToFetch.dreams].map((id) =>
              queryClient.fetchQuery(["dream", id], () =>
                getDreamById(id as string)
              )
            )
          ),
          Promise.all(
            [...idsToFetch.newsfeeds].map((id) =>
              queryClient.fetchQuery(["newsFeed", id], () =>
                getAngelNewsfeedPostById(id as string)
              )
            )
          ),
          Promise.all(
            [...idsToFetch.charities].map((id) =>
              queryClient.fetchQuery(["charity", id], () =>
                getCharitiesById(id as string)
              )
            )
          ),
          Promise.all(
            idsToFetch.dreamComments.map(({ dreamId, commentId }) =>
              queryClient.fetchQuery(["dreamComment", dreamId, commentId], () =>
                getDreamCommentByCommentId(dreamId, commentId)
              )
            )
          ),
          Promise.all(
            idsToFetch.charityComments.map(({ charityId, commentId }) =>
              queryClient.fetchQuery(
                ["charityComment", charityId, commentId],
                () => getCharityCommentByCommentId(charityId, commentId)
              )
            )
          ),
          Promise.all(
            idsToFetch.dreamAngelComments.map(({ newsFeedId, commentId }) =>
              queryClient.fetchQuery(
                ["dreamAngelComment", newsFeedId, commentId],
                () => getDreamAngelCommentByCommentId(newsFeedId, commentId)
              )
            )
          ),
        ]);

        const dreamMap = new Map<string, DreamDto>(
          dreams.map((dream) => [dream.data.id, dream.data])
        );
        const newsfeedMap = new Map<string, NewsFeedDto>(
          newsfeeds.map((post) => [post.data.id, post.data])
        );
        const charityMap = new Map<string, CharityDto>(
          charities.map((charity) => [charity.data.id, charity.data])
        );
        const dreamCommentMap: Map<string, DreamCommentDto> = new Map(
          dreamComments.flatMap((comment) =>
            comment.data.results.map((result) => [result.parent.id, result])
          )
        );
        const charityCommentMap: Map<string, CharityCommentDto> = new Map(
          charityComments.flatMap((comment) =>
            comment.data.results.map((result) => [result.parent.id, result])
          )
        );
        const dreamAngelCommentMap: Map<string, any> = new Map(
          dreamAngelComments.flatMap((comment) =>
            comment.data.results.map((result) => [result.parentId, result])
          )
        );

        for (const page of allActivity.pages) {
          for (const activity of page.results) {
            if (
              activity.likedDream &&
              activity.likedDream.length > 0 &&
              dreamMap.has((activity.likedDream[0] as DreamDto).id)
            ) {
              const dreamId = (activity.likedDream[0] as DreamDto).id;
              fetchedActivities.push({
                type: "dream_liked",
                data: dreamMap.get(dreamId),
                timestamp: activity.createdAt,
              });
            }
            if (
              activity.likedCharity &&
              activity.likedCharity.length > 0 &&
              charityMap.has(activity.likedCharity[0].id)
            ) {
              const charityId = activity.likedCharity[0].id;
              fetchedActivities.push({
                type: "charity_liked",
                charity: charityMap.get(charityId),
                timestamp: activity.createdAt,
                charityId: charityId,
              });
            }
            if (
              activity.likedNewsFeed &&
              activity.likedNewsFeed.length > 0 &&
              (activity.likedNewsFeed[0] as LikedNewsFeedActivityDto).newsFeed
                .type === "DREAM_ANGEL" &&
              newsfeedMap.has(
                (activity.likedNewsFeed[0] as LikedNewsFeedActivityDto).newsFeed
                  .id
              )
            ) {
              const newsFeedId = (
                activity.likedNewsFeed[0] as LikedNewsFeedActivityDto
              ).newsFeed.id;
              fetchedActivities.push({
                type: "angel_card_liked",
                data: newsfeedMap.get(newsFeedId),
                timestamp: activity.createdAt,
              });
            }
            if (
              activity.dreamCommentActivities &&
              activity.dreamCommentActivities.length > 0 &&
              dreamMap.has(
                activity.dreamCommentActivities[0]?.dreamComment?.dreamId
              )
            ) {
              const dreamId =
                activity.dreamCommentActivities[0].dreamComment?.dreamId;
              const commentId =
                activity.dreamCommentActivities[0].dreamComment?.parentId;
              const replyData = !commentId
                ? null
                : dreamCommentMap.get(commentId);

              const replyParent = replyData ? replyData.parent : null;

              fetchedActivities.push({
                type: commentId ? "reply_dream_comment" : "dream_comment",
                data: dreamMap.get(dreamId),
                timestamp: activity.createdAt,
                reply: replyParent,
              });
            }
            if (
              activity.charityCommentActivity &&
              activity.charityCommentActivity.length > 0 &&
              charityMap.has(
                activity.charityCommentActivity[0]?.charityComment?.charityId
              )
            ) {
              const charityId =
                activity.charityCommentActivity[0]?.charityComment?.charityId;
              const commentId =
                activity.charityCommentActivity[0]?.charityComment?.parentId;
              const replyData = !commentId
                ? null
                : charityCommentMap.get(commentId);

              const replyParent = replyData ? replyData.parent : null;
              fetchedActivities.push({
                type: commentId ? "reply_charity_comment" : "charity_comment",
                charity: charityMap.get(charityId),
                timestamp: activity.createdAt,
                reply: replyParent,
                charityId: charityId,
              });
            }
            if (
              activity.dreamAngelCommentActivities &&
              activity.dreamAngelCommentActivities.length > 0 &&
              newsfeedMap.has(
                activity.dreamAngelCommentActivities[0]?.dreamAngelComment
                  ?.newsFeedId
              )
            ) {
              const newsFeedId =
                activity.dreamAngelCommentActivities[0]?.dreamAngelComment
                  ?.newsFeedId;
              const commentId =
                activity.dreamAngelCommentActivities[0]?.dreamAngelComment
                  ?.parentId;
              const replyData = !commentId
                ? null
                : dreamAngelCommentMap.get(commentId);

              const replyParent = replyData ? replyData.parent : null;
              fetchedActivities.push({
                type: commentId ? "reply_angel_comment" : "angel_comment",
                data: newsfeedMap.get(newsFeedId),
                timestamp: activity.createdAt,
                reply: replyParent,
              });
            }
            if (
              activity.donation &&
              activity.donation.length > 0 &&
              dreamMap.has(activity.donation[0].dreamId)
            ) {
              const dreamId = activity.donation[0].dreamId;
              fetchedActivities.push({
                type: "donation",
                data: dreamMap.get(dreamId),
                timestamp: activity.createdAt,
              });
            }
            if (
              activity.wingDonation &&
              activity.wingDonation.length > 0 &&
              charityMap.has(activity.wingDonation[0].charityId)
            ) {
              const charityId = activity.wingDonation[0].charityId;
              fetchedActivities.push({
                type: "wing_donation",
                charity: charityMap.get(charityId),
                timestamp: activity.createdAt,
                charityId: charityId,
              });
            }
          }
        }
        setActivities(fetchedActivities);
      };

      fetchActivities();
    }
  }, [allActivity]);

  return {
    activities,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
