import React from "react";
import { useParams } from "react-router-dom";
import { getPublicCharityById, getWingDonations } from "../../services/api";
import Layout from "../Public/components/Layout";
import WingPost from "../../components/PublicandInternShared/WingPost";
import { setBgLoader } from "../../store/users";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { CharityDto, WingDonationDto } from "src/services/api/data-contracts";
import { useCheckIsAuth } from "src/hooks/useCheckIsAuth";
import PublicWingMakersCarousel from "src/components/PublicandInternShared/PublicWingMakersCarousel";
import LiveYourDreamSection from "src/components/LandingPageComponents/LiveYourDreamSection";

const SharedWingDreamPage = () => {
  const { wingId } = useParams();
  const dispatch = useDispatch();
  const token = useCheckIsAuth(`/wing-donation/${wingId}`, wingId);

  const { data: charity, isLoading: isCharityLoading } = useQuery<CharityDto>({
    queryKey: ["publicSharedCharity"],
    queryFn: () => getPublicCharityById(wingId).then((res) => res?.data),
    enabled: !!wingId && !token,
  });

  const { data: users, isLoading: isDonationsLoading } = useQuery<
    WingDonationDto[]
  >({
    queryKey: ["publicWingPageDonationsUsers"],
    queryFn: () =>
      getWingDonations(charity?.id, 1, 12).then((res) => res.data.results),
    enabled: !!charity?.id,
  });

  if (isCharityLoading || isDonationsLoading) {
    dispatch(setBgLoader(true));
    return <></>;
  }

  return (
    <Layout>
      <div className="wing-donation-page-card mt-2 mb-5">
        <WingPost post={charity} />
      </div>
      <PublicWingMakersCarousel users={users} />
      <LiveYourDreamSection />
    </Layout>
  );
};
export default SharedWingDreamPage;
