import {
  CharityCommentDto,
  DreamCommentDto,
} from "src/services/api/data-contracts";
import {
  SET_CHARITY_COMMENTS,
  UPDATE_CHARITY_COMMENTS_COUNT,
  SET_DREAM_COMMENTS,
  UPDATE_DREAM_COMMENTS_COUNT,
  CommentsActionTypes,
  SET_ANGEL_COMMENTS,
  UPDATE_ANGEL_COMMENTS_COUNT,
  SET_ACTIVE_REPLY,
  UPDATE_ANGEL_LIKES,
  UPDATE_DREAM_LIKES,
  UPDATE_CHARITY_LIKES,
} from "./action";

const profileId = localStorage.getItem("profileId");

export interface CommentsState {
  charityComments: {
    [charityId: string]: {
      comments: CharityCommentDto[];
      count: number;
      likesCount: number;
      userLiked: boolean;
      likedBy: string[];
    };
  };
  dreamComments: {
    [dreamId: string]: {
      comments: DreamCommentDto[];
      count: number;
      likesCount: number;
      userLiked: boolean;
      likedBy: string[];
    };
  };
  angelComments: {
    [dreamAngelId: string]: {
      comments;
      count: number;
      likesCount: number;
      userLiked: boolean;
      likedBy: string[];
    };
  };
  commentsReply: {
    parentReplyId: string;
  };
}

const initialState: CommentsState = {
  charityComments: {},
  dreamComments: {},
  angelComments: {},
  commentsReply: { parentReplyId: "" },
};

const commentsReducer = (
  state = initialState,
  action: CommentsActionTypes
): CommentsState => {
  switch (action.type) {
    case SET_CHARITY_COMMENTS:
      return {
        ...state,
        charityComments: {
          ...state.charityComments,
          [action.payload.charityId]: {
            ...state.charityComments[action.payload.charityId],
            comments: action.payload.comments,
          },
        },
      };
    case UPDATE_CHARITY_COMMENTS_COUNT:
      return {
        ...state,
        charityComments: {
          ...state.charityComments,
          [action.payload.charityId]: {
            ...state.charityComments[action.payload.charityId],
            count: action.payload.count,
          },
        },
      };
    case SET_DREAM_COMMENTS:
      return {
        ...state,
        dreamComments: {
          ...state.dreamComments,
          [action.payload.dreamId]: {
            ...state.dreamComments[action.payload.dreamId],
            comments: action.payload.comments,
          },
        },
      };
    case UPDATE_DREAM_COMMENTS_COUNT:
      return {
        ...state,
        dreamComments: {
          ...state.dreamComments,
          [action.payload.dreamId]: {
            ...state.dreamComments[action.payload.dreamId],
            count: action.payload.count,
          },
        },
      };
    case SET_ANGEL_COMMENTS:
      return {
        ...state,
        angelComments: {
          ...state.angelComments,
          [action.payload.dreamAngelId]: {
            ...state.angelComments[action.payload.dreamAngelId],
            comments: action.payload.comments,
          },
        },
      };
    case UPDATE_ANGEL_COMMENTS_COUNT:
      return {
        ...state,
        angelComments: {
          ...state.angelComments,
          [action.payload.dreamAngelId]: {
            ...state.angelComments[action.payload.dreamAngelId],
            count: action.payload.count,
          },
        },
      };
    case UPDATE_CHARITY_LIKES:
      return {
        ...state,
        charityComments: {
          ...state.charityComments,
          [action.payload.charityId]: {
            ...state.charityComments[action.payload.charityId],
            likesCount: action.payload.likesCount,
            userLiked: action.payload.userLiked,
            likedBy: action.payload.likedBy,
          },
        },
      };

    case UPDATE_DREAM_LIKES:
      return {
        ...state,
        dreamComments: {
          ...state.dreamComments,
          [action.payload.dreamId]: {
            ...state.dreamComments[action.payload.dreamId],
            likesCount: action.payload.likesCount,
            userLiked: action.payload.userLiked,
            likedBy: action.payload.likedBy,
          },
        },
      };

    case UPDATE_ANGEL_LIKES:
      return {
        ...state,
        angelComments: {
          ...state.angelComments,
          [action.payload.dreamAngelId]: {
            ...state.angelComments[action.payload.dreamAngelId],
            likesCount: action.payload.likesCount,
            userLiked: action.payload.userLiked,
            likedBy: action.payload.likedBy,
          },
        },
      };

    case SET_ACTIVE_REPLY:
      return {
        ...state,
        commentsReply: {
          parentReplyId: action.payload.parentReplyId,
        },
      };
    default:
      return state;
  }
};

export default commentsReducer;
