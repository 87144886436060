import React from "react";
import InfoAccordion from "../Accordion/InfoAccordion";

const questions = [
  {
    title: "How does it work?",
    description:
      "Everyone has to donate in order to fulfill their dreams. For every $1 you donate, it creates a 10x higher visibility worldwide, which helps you attract more donors to your dream, allowing you to achieve your goal while only contributing half the total cost yourself. The more you give, the more likely you are to receive!",
  },
  {
    title: "Why to join Dreamerz? ",
    description:
      "Join Dreamerz to be part of a global social network where your dreams are supported by others. Make a meaningful impact with small donations, gain visibility for your own aspirations, and experience the joy of helping others achieve their goals. Together, we can turn dreams into reality.",
  },
  {
    title: "Why Should I start fulfilling other’s dreams?",
    description:
      "You should start fulfilling others' dreams because, by doing so, you collect fulfilled dreams that directly contribute to realizing your own aspirations. This creates a supportive cycle where each small act of giving amplifies impact, promotes both the receiver's and giver's dreams on a global scale, builds a stronger community, and brings you closer to achieving your dreams at half the cost. So make that first step!",
  },
  {
    title: "How does my dream help others on Dreamerz? ",
    description:
      "Everyone needs to collect fulfilled dreams to realize their own. Your dream is important to us because it helps others achieve theirs. Start collecting fulfilled dreams today!",
  },
  {
    title: "Why Consistent Donations Matter?",
    description:
      "Regular donations, even just 1-3 per day, have a far greater impact than making large, one-time contributions. By consistently donating, you help keep dreams moving forward and create a steady flow of support for everyone. Your daily contributions build momentum and drive progress for all dreams, making a significant difference in the Dreamerz community.",
  },
  {
    title: "How do I get paid?",
    description:
      "At Dreamerz, you get paid once you’ve received 100% of the donations for your dream. It's important to ensure your contributions to the community have been meaningful, as giving back is key to our mutual support system. If your fulfilled section shows at least half of your dream amount, you can then withdraw your funds and start living your dream.",
  },
];

const QuestionsSection = () => (
  <div className="questions-section-container">
    <div className="section-container-accordion ">
      <div className="questions-text-container">
        <span className="question-title">Frequently Asked Questions</span>
        <span className="question-description">
          Find answears to common questions about our social network platform
          and comunity.
        </span>
      </div>
      <div className="questions-container">
        {questions.map((q, index) => {
          return (
            <div className="question-card" key={index}>
              <InfoAccordion title={q.title} description={q.description} />
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default QuestionsSection;
