import { GradientButtonProps } from "src/types/components-props";
import "../../styles/Buttons.css";
import React from "react";

const GradientButton = ({
  className,
  minWidth,
  textClassName,
  text,
  ...props
}: GradientButtonProps) => {
  return (
    <div
      className={className ? className : "gradient-button"}
      style={{ minWidth }}
      {...props}
    >
      <span className={textClassName ? textClassName : ""}>{text}</span>
    </div>
  );
};
export default GradientButton;
