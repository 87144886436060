import { Modal } from "react-bootstrap";
import { close } from "../../consts/SvgIcon";
import React, { useEffect, useState } from "react";
import { setBgLoader, setUser } from "../../store/users";
import {
  checkoutSession,
  deleteSubscription,
  getPricesId,
  getSubscription,
  getUser,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { pushEvent } from "../../utils";
import { useQuery } from "../../hooks/useQuery";
import { UsersModule } from "../../types/redux";
import { SubscriptionModel } from "../../types/components-props";
import { PricingModalProps } from "src/types/pages";

const successUrl = "https://dreamerz.net/pricing-page?subscription=success";
const cancelUrl = "https://dreamerz.net/pricing-page?subscription=canceled";
let secondQuery;

const PricingModal = ({
  setCustomModal,
  customModals,
  dreamId,
}: PricingModalProps) => {
  const query = useQuery();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const [idCard, setId] = useState("");
  const [subscription, setSubscription] = useState<SubscriptionModel | null>(
    null
  );

  useEffect(() => {
    if (secondQuery !== query) {
      secondQuery = query;
      if (query === "success") pushEvent("success_subscription", "modal");
    }
  }, [query]);

  useEffect(() => {
    dispatch(setBgLoader(true));
    getPricesId().then((res) => {
      setCards([
        {
          category: "Basic",
          price: "$11",
          dreams: 10,
          active: false,
          id: res.data?.basicPrice,
        },
        {
          category: "Standard",
          price: "$110",
          dreams: 100,
          active: false,
          id: res.data?.standardPrice,
        },
        {
          category: "Premium",
          price: "$1100",
          dreams: 1000,
          active: false,
          id: res.data?.premiumPrice,
        },
      ]);
    });
    if (user.monthlySubscriptionId) {
      getSubscription().then((res) => {
        setId(
          res.data.data?.filter(
            (sub) =>
              sub.id === user.monthlySubscriptionId && sub.status === "active"
          )?.[0]?.plan.id
        );
        setSubscription(
          res.data.data?.filter(
            (sub) => sub.id === user.monthlySubscriptionId
          )?.[0]?.latest_invoice?.lines.data[0]
        );
      });
    }
  }, []);
  useEffect(
    () => () => {
      setCards([]);
    },
    []
  );

  const cancelSubscription = async () => {
    dispatch(setBgLoader(true));
    await deleteSubscription(user.monthlySubscriptionId);
    const res = await getSubscription();
    setId(
      res.data.data?.filter(
        (sub) =>
          sub.id === user.monthlySubscriptionId && sub.status === "active"
      )?.[0]?.plan.id
    );
    setSubscription(
      res.data.data?.filter((sub) => sub.id === user.monthlySubscriptionId)
        .latest_invoice?.lines.data[0]
    );
    pushEvent("canceled_subscription", "modal");
    const response = await getUser();
    dispatch(setUser(response?.data));
    return response;
  };
  const setPayment = (card) => {
    if (dreamId) localStorage.setItem("dreamId", dreamId);
    const data = { priceId: card.id, successUrl, cancelUrl };
    checkoutSession(data).then((res) => {
      window.location = res.data.checkoutSessionUrl;
    });
  };
  const refreshSubscription = async (card) => {
    cancelSubscription().then(() => {
      setPayment(card);
    });
  };
  return (
    <Modal
      size="lg"
      backdrop={true}
      centered
      show={!!customModals}
      onHide={() => setCustomModal("")}
      animation={true}
      contentClassName="border-0"
      style={{ borderRadius: "8px" }}
    >
      <div className="price-modal-img d-flex flex-column justify-content-start p-4">
        <div className="mb-4 d-flex flex-row justify-content-between">
          <div style={{ cursor: "default" }} className="pricing-text">
            <h5>Pricing</h5>
          </div>
          <div
            onClick={() => setCustomModal("")}
            className="mb-2"
            style={{ cursor: "pointer" }}
          >
            {close()}
          </div>
        </div>
        <div className="price-modal-content  mb-md-3">
          {cards?.map((el) => (
            <div
              className="d-flex flex-column m-2 justify-content-between price-modal-card"
              key={el.category}
            >
              <div className="p-4 d-flex flex-column justify-content-between h-100">
                <div className="w-100 d-flex justify-content-start fw-bold">
                  <span>{el.category}</span>
                </div>
                <div className="price-text w-100  d-flex flex-column justify-content-center text-center">
                  <div className="d-flex justify-content-center mb-3">
                    <h5>
                      {el.price}
                      <span className="price-modal-month">/Month</span>
                    </h5>
                  </div>
                  <div className="fulfill-price text-center d-flex justify-content-center">
                    <h3>Fulfill {el.dreams} dreams</h3>
                  </div>
                </div>
                {idCard === el.id && subscription?.period ? (
                  <div className="mt-1">
                    <h6 className="mb-3 text-center text-dark">
                      {new Date(subscription?.period?.start * 1000)
                        ?.toISOString()
                        ?.split("T")[0]
                        ?.replaceAll("-", "/")
                        ?.split("/")
                        ?.reverse()
                        ?.join("/")}{" "}
                      -
                      {" " +
                        new Date(subscription?.period?.end * 1000)
                          ?.toISOString()
                          ?.split("T")[0]
                          ?.replaceAll("-", "/")
                          ?.split("/")
                          ?.reverse()
                          ?.join("/")}
                    </h6>
                    <div
                      className={"dashboard-card-buttons custom-gradient"}
                      style={{ width: "100%" }}
                      onClick={() => cancelSubscription()}
                    >
                      <div className={"dashboard-card-buttons-text"}>
                        <span>Cancel subscription</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={
                    idCard !== el.id
                      ? "dashboard-card-buttons custom-gradient mt-5"
                      : "dashboard-card-buttons custom-gradient mt-1"
                  }
                  style={{ width: "100%" }}
                  onClick={() =>
                    idCard !== el.id ? setPayment(el) : refreshSubscription(el)
                  }
                >
                  <div className={"dashboard-card-buttons-text"}>
                    <span>{idCard !== el.id ? "Subscribe" : "Refresh"}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
export default PricingModal;
