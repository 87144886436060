import React from "react";
import wingDonation from "../../assets/images/WingsImage.png";

const WingDonationSection = () => (
  <div className="d-flex align-items-center justify-content-center wing-container">
    <div className="section-container-row section-container-row-wing">
      <div>
        <img
          src={wingDonation}
          alt="Happy children with their grandmother in the house of their dreams"
          className="wing-image"
        />
      </div>
      <div className="wing-info">
        <p className="wing-title-section">Wings Donations</p>
        <span className="wing-text">
          Wing Donations are a pure charity section where we unite as a
          community to raise money for emergencies and special cases. United,
          your donations make a big difference quickly.
        </span>
      </div>
    </div>
  </div>
);

export default WingDonationSection;
