import CommentsModalMobile from "./CommentsModalMobile";
import CommentsModal from "./CommentsModal";
import React from "react";
import { ResponsiveCommentsModalProps } from "src/types/comments-props";

const ResponsiveCommentsModal = ({
  ...props
}: ResponsiveCommentsModalProps) => {
  return (
    <>
      {window.innerWidth < 700 ? (
        <CommentsModalMobile {...props} />
      ) : (
        <CommentsModal {...props} />
      )}
    </>
  );
};
export default ResponsiveCommentsModal;
