import React, { useState, useEffect } from "react";
import PopularDreams from "./PopularDream";
import TodaysBirthday from "./TodaysBirthday";
import TopDonors from "./TopDonors";
import NewDreams from "./NewDreams";
import { calculateSlidersWidth } from "src/utils/calculateSlidersWidth";

const Sliders = ({ index, isFulfilled }) => {
  const [sliderWidth, setSliderWidth] = useState(calculateSlidersWidth());

  useEffect(() => {
    const handleResize = () => {
      setSliderWidth(calculateSlidersWidth());
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <>
      {index === 2 && (
        <PopularDreams isFulfilled={isFulfilled} sliderWidth={sliderWidth} />
      )}
      {index === 5 && (
        <NewDreams isFulfilled={isFulfilled} sliderWidth={sliderWidth} />
      )}
      {index === 8 && (
        <TodaysBirthday isFulfilled={isFulfilled} sliderWidth={sliderWidth} />
      )}
      {index === 11 && (
        <TopDonors isFulfilled={isFulfilled} sliderWidth={sliderWidth} />
      )}
    </>
  );
};

export default Sliders;
