import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setIsAuth } from "../../store/users";
import { confirmEmail } from "../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UsersModule } from "src/types/redux";

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const notify = (message) => toast.success(message);
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profileId");
    confirmEmail(searchParams?.get("token")).then((res) => {
      if (!res) return;
      localStorage.setItem("accessToken", res.config.data);
      dispatch(setIsAuth(true));
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Success");
    });
  }, []);
  return (
    <div className="login-wrapper">
      <header className="login-header">
        <Row className="p-0 m-0">
          <Col lg={12} sm={12} md={12} xxl={12} xl={12}>
            <div className="d-flex ms-5">
              <div className="fa-logo-icon" />
            </div>
          </Col>
        </Row>
      </header>
      <body className="bg-img-price">
        <Row className="p-0 m-0">
          <Col
            lg={12}
            sm={12}
            md={12}
            xxl={12}
            xl={12}
            className="d-flex justify-content-center align-self-center"
          >
            <div className="reset-password-container">
              <div className="p-4">
                <div className="w-100 d-flex align-items-center mb-3">
                  <span className="reset-header-text">Email is confirmed</span>
                </div>
                <div className="d-flex flex-column justify-content-start align-content-around w-100">
                  <button
                    className="dashboard-card-buttons custom-gradient p-0 mb-2 mt-1"
                    style={{ width: "100%" }}
                    type="submit"
                    onClick={() => navigate("/login")}
                  >
                    <span className="dashboard-card-buttons-text">Log In</span>
                  </button>
                  {showLoader && (
                    <div className="d-flex justify-content-center w-100">
                      <img
                        src={loader}
                        alt="loader"
                        style={{ height: "50px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </body>
    </div>
  );
};

export default ConfirmEmail;
