import Carousel from "react-multi-carousel";
import React, { useEffect, useRef, useState } from "react";
import {
  getAllProfileDonations,
  getDonationByUserId,
} from "../../services/api";
import { responsive } from "../../consts/breakpoints";
import FulfilledDreams from "./FulfilledDreams";
import useIsMobile from "src/hooks/useIsMobile";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setAllProfileFulfilled, setBgLoader } from "src/store/users";
import { DonationDto, DreamDto } from "src/services/api/data-contracts";
import DreamCard from "../Sliders/DreamCard";
import { debounce } from "lodash";
import { fetchUserDonationDream, processPublicDreamResponses } from "src/utils";
import { SLIDERS_ENUM } from "src/consts/slidersEnum";
import { scrollCarousel } from "src/utils/scrollCarousel";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right-icon.svg";
import { useNavigate } from "react-router-dom";
import InfiniteViewAllModal from "./InfiniteViewAllModal";

export interface FulfilledDreamsCarouselProps {
  userId: string;
  isFulfilled?;
}

const FulfilledDreamsCarousel = ({
  userId,
  isFulfilled,
}: FulfilledDreamsCarouselProps) => {
  const isCompleted = useIsMobile(860);
  const isMobile = useIsMobile(768);
  const [fulfilledDream, setFulfilledDream] = useState<DreamDto[]>();
  const [paddingLeft, setPaddingLeft] = useState(0);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const profileId = localStorage.getItem("profileId");

  const { isLoading } = useQuery<DonationDto[]>({
    queryKey: ["fulfilledDreamsAngelPage", userId],
    queryFn: () =>
      getDonationByUserId(userId, 1).then((res) => res?.data?.results),
    onSuccess: (data) => {
      fetchDreamDetails(data);
    },
  });

  const fetchDreamDetails = async (fulfilledDreams) => {
    const dreamDetails = await fetchUserDonationDream(fulfilledDreams);
    const dreames = processPublicDreamResponses(dreamDetails);
    setFulfilledDream(dreames);
  };

  useEffect(() => {
    if (profileId) {
      getAllProfileDonations().then((res) => {
        dispatch(setAllProfileFulfilled(res?.data));
      });
    }
    const updateMarginLeft = () => {
      const screenWidth = window.innerWidth;
      const calculatedMargin = (screenWidth - 695) / 2;
      const marginInPercentage = (calculatedMargin / screenWidth) * 100;
      setPaddingLeft(marginInPercentage);
    };
    updateMarginLeft();
    window.addEventListener("resize", updateMarginLeft);
    return () => window.removeEventListener("resize", updateMarginLeft);
  }, []);

  useEffect(() => {
    const handleScroll = debounce(() => {}, 100);

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  if (isLoading) {
    dispatch(setBgLoader(true));
    return <></>;
  }

  return (
    <div className="mb-4">
      <div className="d-flex justify-content-center">
        <div
          className="dream-header"
          style={{
            width: isCompleted ? "96vw" : "680px",
          }}
        >
          <p className="dream-slider-title">Fulfilled Dreams:</p>
          <div className="view-all-button-container">
            {fulfilledDream?.length >= 12 && (
              <div
                onClick={() =>
                  profileId ? handleShowModal() : navigate("/login")
                }
                className="view-all-button"
              >
                <p className="view-all">view all</p>
              </div>
            )}
            {!isMobile ? (
              <div className="carousel-arrow-container">
                <div
                  className="icon-container-hover-right cursor-pointer"
                  onClick={() =>
                    scrollCarousel("left", "angel-page-fulfilled-slider")
                  }
                >
                  <ArrowLeft className="img-arrow-hover" />
                </div>
                <div
                  className="icon-container-hover-left cursor-pointer"
                  onClick={() =>
                    scrollCarousel("right", "angel-page-fulfilled-slider")
                  }
                >
                  <ArrowRight className="img-arrow-hover" />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <InfiniteViewAllModal
            show={showModal}
            handleClose={handleCloseModal}
            id={userId}
            isFulfilled={isFulfilled}
            type={SLIDERS_ENUM.ANGEL_PAGE}
          />
        </div>
      </div>

      {isMobile ? (
        <div className="carousel-wrapper">
          <Carousel
            responsive={responsive}
            className="slider-carousel"
            sliderClass="d-flex align-items-center justify-content-between"
            itemClass="dream-card-mobile"
            arrows={false}
            swipeable
          >
            {fulfilledDream !== undefined &&
              fulfilledDream.length > 0 &&
              fulfilledDream
                ?.slice(0, 12)
                .map((el) =>
                  profileId ? (
                    <DreamCard
                      key={el?.id}
                      dream={el}
                      isFulfilled={isFulfilled}
                    />
                  ) : (
                    <FulfilledDreams dream={el} key={el?.id} />
                  )
                )}
          </Carousel>
        </div>
      ) : (
        <div
          className="angel-page-fulfilled-slider"
          ref={containerRef}
          style={{
            paddingLeft: isCompleted ? "0" : `${paddingLeft}%`,
            width: "99%",
            marginLeft: "5px",
          }}
        >
          {fulfilledDream &&
            fulfilledDream.length > 0 &&
            fulfilledDream
              ?.slice(0, 12)
              .map((el) =>
                profileId ? (
                  <DreamCard
                    key={el?.id}
                    dream={el}
                    isFulfilled={isFulfilled}
                  />
                ) : (
                  <FulfilledDreams dream={el} key={el?.id} />
                )
              )}
        </div>
      )}
    </div>
  );
};
export default FulfilledDreamsCarousel;
