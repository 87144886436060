import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setShowLoader } from "../../store/users";
import { resetPassword } from "../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UsersModule } from "src/types/redux";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const notify = (message) => toast.success(message);
  const notifyWarning = (message) => toast.warning(message);
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const dispatch = useDispatch();

  const handleReset = (e) => {
    e.preventDefault();
    dispatch(setShowLoader(true));
    const { password, confirmPassword } = e.target.elements;
    if (!password.value || !confirmPassword.value) {
      dispatch(setShowLoader(false));
      toast.dismiss();
      toast.clearWaitingQueue();
      notifyWarning("Please fill password");
    } else {
      resetPassword(
        password.value,
        confirmPassword.value,
        searchParams?.get("token")
      ).then((res) => {
        if (!res) return;
        toast.dismiss();
        toast.clearWaitingQueue();
        notify(res.data);
        navigate("/");
      });
    }
  };
  return (
    <div className="login-wrapper">
      <header className="login-header">
        <Row className="p-0 m-0">
          <Col lg={12} sm={12} md={12} xxl={12} xl={12}>
            <div className="d-flex ms-5">
              <div className="fa-logo-icon" />
            </div>
          </Col>
        </Row>
      </header>
      <body className="bg-img-price" style={{ height: " 93vh" }}>
        <Row className="p-0 m-0">
          <Col
            lg={12}
            sm={12}
            md={12}
            xxl={12}
            xl={12}
            className="d-flex justify-content-center align-self-center"
          >
            <div className="reset-password-container">
              <div className="p-4">
                <div className="w-100 d-flex align-items-center">
                  <span className="reset-header-text">Reset password</span>
                </div>
                <Form className="mt-3" onSubmit={handleReset}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="reset-password-spans">Password*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="mb-0 text-black"
                      id="password"
                      placeholder="Enter new password"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="reset-password-spans">
                        Confirm Password*
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="mb-0 text-black"
                      id="confirmPassword"
                      placeholder="Enter new password"
                    />
                  </Form.Group>
                  <div className="d-flex flex-column justify-content-start align-content-around w-100">
                    <button
                      className="dashboard-card-buttons custom-gradient p-0 mb-2 mt-1"
                      style={{ width: "100%" }}
                      type="submit"
                    >
                      <span className="dashboard-card-buttons-text">
                        Reset password
                      </span>
                    </button>
                    {showLoader && (
                      <div className="d-flex justify-content-center w-100">
                        <img
                          src={loader}
                          alt="loader"
                          style={{ height: "50px" }}
                        />
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </body>
    </div>
  );
};

export default ResetPassword;
