import Card from "../Partials/Card";
import defaultImage from "../../assets/images/defaultUser.png";
import moment from "moment";
import ShareButton from "../Buttons/ShareButton";
import GradientButton from "../Buttons/GradientButton";
import { Carousel, ProgressBar } from "react-bootstrap";
import coverImg from "../../assets/images/coverImg.png";
import PublicPostComments from "./PublicPostComments";
import React from "react";
import { useNavigate } from "react-router-dom";
import { commentType } from "src/consts/commentsTypeEnum";
import PublicSocials from "../Partials/PublicSocials";
import { useSelector } from "react-redux";
import { DreamCommentData } from "src/types/redux";

const DreamPost = ({ post }) => {
  const navigate = useNavigate();
  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img}>
        <img src={img} className="post-image" alt="img" />
      </Carousel.Item>
    );
  };

  const dreamComments = useSelector(
    (state: DreamCommentData) => state.comments.dreamComments[post?.id] || {}
  );

  return (
    <Card className="rounded-3 gap-card pb-0 shadow m-0">
      <Card.Body className="p-0 gap-post">
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
          <div
            className="d-flex align-items-center gap-2 cursor-pointer user-image"
            onClick={() => navigate("/login")}
          >
            <div>
              <img
                src={post?.user?.mainImageUrl || defaultImage}
                alt="profile-img"
                className="avatar"
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className="d-flex flex-column ">
              <span id="username" className="user-name">
                {`${post?.user?.firstName || "No name"} 
              ${post.user?.lastName || ""}`}
              </span>
              <span className="time-ago">
                {moment(post?.createdAt)?.fromNow(true)} ago
              </span>
            </div>
          </div>
          <div className="buttons-container" onClick={() => navigate("/login")}>
            <ShareButton width="72px" />
            <GradientButton minWidth="137px" text="Fulfill" />
          </div>
        </div>
        <Carousel
          indicators={post?.images?.length > 1 && window.innerWidth < 500}
          interval={null}
          controls={post?.images?.length > 1 && window.innerWidth > 500}
        >
          {post?.images?.length
            ? post?.images?.map((img) => carouselItem(img?.url))
            : carouselItem(coverImg)}
        </Carousel>
        <span className="my-span lh-sm text-dark">
          {post?.title || "No Title"}
        </span>
        <div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <ProgressBar
              now={post?.progress}
              variant={"gradient"}
              className="black-hover post-progress-bar"
            />
            <span className="fw-bold text-dark fa-sm">
              {Math.round(post?.progress) || 0}%
            </span>
          </div>
          <div className="d-flex justify-content-between text-dark">
            <span>
              Fulfilled <b>{post.donations || 0}</b>
            </span>
            <span>
              Received <b>{post?.amountReceived || 0}</b>
            </span>
          </div>
          <PublicSocials
            commentCount={dreamComments.count}
            likesCount={post?.dreamLikesCount}
          />
          <PublicPostComments dreamId={post?.id} type={commentType.dream} />
        </div>
      </Card.Body>
    </Card>
  );
};
export default DreamPost;
