import React from "react";
import Card from "../Partials/Card";
import { Carousel, ProgressBar } from "react-bootstrap";
import coverImg from "../../assets/images/coverImg.png";
import "../../styles/Dashboard.css";
import moment from "moment/moment";
import GradientButton from "../Buttons/GradientButton";
import ShareButton from "../Buttons/ShareButton";
import PublicPostComments from "./PublicPostComments";
import { useNavigate } from "react-router-dom";
import { commentType } from "src/consts/commentsTypeEnum";
import { CharityCommentData } from "src/types/redux";
import { useSelector } from "react-redux";
import PublicSocials from "../Partials/PublicSocials";

const WingPost = ({ post }) => {
  const progress = Math.round((post.amountReceived * 100) / post.amount) || 0;
  const navigate = useNavigate();

  const charityComments = useSelector(
    (state: CharityCommentData) =>
      state.comments.charityComments[post?.id] || {}
  );

  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img}>
        <img src={img} className="post-image" alt="img" />
      </Carousel.Item>
    );
  };

  return (
    <Card className="rounded-3 gap-card pb-0 shadow m-0">
      <Card.Body className="p-0 gap-post">
        <div className="gradient-bg-charity w-100">
          <span className="text-dark">🕊 Wings Donation</span>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
          <div className="d-flex align-items-center gap-2 cursor-pointer">
            <div className="d-flex flex-column ">
              <span className="user-name" onClick={() => navigate("/login")}>
                {post?.title || ""}
              </span>
              <span className="time-ago">
                {moment(post?.createdAt)?.fromNow(true)} ago
              </span>
            </div>
          </div>
          <div className="buttons-container" onClick={() => navigate("/login")}>
            <ShareButton width="72px" />
            <GradientButton minWidth="137px" text="Donate" />
          </div>
        </div>
        <Carousel
          indicators={post?.images?.length > 1 && window.innerWidth < 500}
          interval={null}
          controls={post?.images?.length > 1 && window.innerWidth > 500}
        >
          {post?.images?.length
            ? post?.images?.map((img) => carouselItem(img?.url))
            : carouselItem(coverImg)}
        </Carousel>
        <span className="my-span lh-sm text-dark">
          {post?.description || "No description"}
        </span>
        <div>
          <div className="d-flex align-items-center justify-content-between gap-2 mb-1">
            <ProgressBar
              now={progress}
              variant={"gradient"}
              className="black-hover post-progress-bar"
            />
            <span className="fw-bold text-dark" style={{ fontSize: "12px" }}>
              {progress}%
            </span>
          </div>
          <span className="text-dark">
            Received <b>{post?.amountReceived || 0}</b>
          </span>
          <PublicSocials
            commentCount={charityComments.count}
            likesCount={post?.likedCharitiesByUsers.length}
          />
          <PublicPostComments dreamId={post?.id} type={commentType.charity} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default WingPost;
