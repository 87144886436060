import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import "../../../styles/EditProfileModal.css";
import useIsMobile from "src/hooks/useIsMobile";
import {
  deleteImage,
  getUser,
  updateUser,
  uploadImages,
  uploadDreamImages,
  updateDream,
  getProfileDream,
  deleteDreamImage,
} from "src/services/api";
import {
  setBgLoader,
  setErrorMessage,
  setUser,
  setDream,
} from "src/store/users";
import { useDispatch } from "react-redux";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import GradientButton from "src/components/Buttons/GradientButton";
import Carousel from "react-multi-carousel";
import {
  editProfileDreamImagesResponsive,
  editProfileImagesResponsive,
} from "src/consts/breakpoints";
import { EditProfileModalProps } from "src/types/profile-props";
import { Image } from "src/types/redux";
import { adjustTooltipHeight } from "src/utils/tooltipHeight";
import { DreamImageDto } from "src/services/api/data-contracts";
import { toast } from "react-toastify";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const EditProfileModal = ({
  show,
  handleClose,
  user,
  dream,
}: EditProfileModalProps) => {
  const notify = (message) => toast.warning(message);
  const isMobile = useIsMobile(768);
  const imageIsMobile = useIsMobile(440);
  const dreamIsMobile = useIsMobile(490);
  const dispatch = useDispatch();
  const textbox = useRef(null);
  const dreamdesc = useRef(null);
  const hasDream = useRoleCheck(user);

  useEffect(() => {
    adjustTooltipHeight(textbox);
    adjustTooltipHeight(dreamdesc);
  }, [textbox.current, dreamdesc.current]);

  const [userData, setUserData] = useState(user);
  const [city, setCity] = useState(user?.city);
  const [country, setCountry] = useState(user?.country);
  const [addedImages, setAddedImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [dreamTitle, setDreamTitle] = useState(dream?.title || "");
  const [addedDreamImages, setAddedDreamImages] = useState([]);
  const [deletedDreamImages, setDeletedDreamImages] = useState<string[]>([]);

  useEffect(() => {
    setUserData(user);
    setCity(user?.city);
    setCountry(user?.country);
    setAddedImages([]);
    setDeletedImages([]);
    setDreamTitle(dream?.title || "");
    setAddedDreamImages([]);
    setDeletedDreamImages([]);
  }, [show, user, dream]);

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setErrorMessage(""));
    const images = [...e.target.files];
    setAddedImages((prev) => [...prev, ...images]);
  };

  const handleDeleteProfileImg = (image: Image) => {
    if (image.id) {
      setDeletedImages((prev) => [...prev, image.id]);
    } else {
      setAddedImages((prev) => prev.filter((img) => img !== image));
    }
  };

  const handleDreamImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setErrorMessage(""));
    const images = [...e.target.files];
    setAddedDreamImages((prev) => [...prev, ...images]);
  };

  const handleDeleteDreamImg = (image: DreamImageDto) => {
    if (image.id) {
      setDeletedDreamImages((prev) => [...prev, image.id]);
    } else {
      setAddedDreamImages((prev) => prev.filter((img) => img !== image));
    }
  };

  const handleSaveChanges = () => {
    if (
      hasDream &&
      dream.images.length -
        deletedDreamImages.length +
        addedDreamImages.length <
        3
    ) {
      return notify("Please add at least 3 dream images.");
    }

    dispatch(setBgLoader(true));

    const uploadProfileImagePromises = addedImages.map((image) =>
      uploadImages([image])
    );
    const deleteProfileImagePromises = deletedImages.map((imageId) =>
      deleteImage(imageId)
    );

    const uploadDreamImagePromises = hasDream
      ? addedDreamImages.map((image) => uploadDreamImages(dream.id, [image]))
      : [];
    const deleteDreamImagePromises = hasDream
      ? deletedDreamImages.map((imageId) => deleteDreamImage(dream.id, imageId))
      : [];

    Promise.allSettled([
      ...uploadProfileImagePromises,
      ...deleteProfileImagePromises,
      ...uploadDreamImagePromises,
      ...deleteDreamImagePromises,
    ]).then(() => {
      const userUpdatePromise = updateUser({
        firstName: userData.firstName,
        lastName: userData.lastName,
        description: userData.description,
        phoneNumber: userData.phoneNumber,
        gender: userData.gender.toUpperCase() as "MALE" | "FEMALE",
        birthday: userData.birthday,
        city: city,
        country: country,
      });

      const dreamUpdatePromise = hasDream
        ? updateDream(dream.id, dreamTitle, dream.amount)
        : Promise.resolve();

      Promise.all([userUpdatePromise, dreamUpdatePromise]).then(() => {
        getUser().then((response) => {
          dispatch(setUser(response.data));
          if (hasDream) {
            getProfileDream().then((res) => {
              dispatch(setDream(res.data));
              dispatch(setBgLoader(false));
              handleClose();
            });
          } else {
            dispatch(setBgLoader(false));
            handleClose();
          }
        });
      });
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
    adjustTooltipHeight(textbox);
  };

  const handleDreamTitleChange = (e) => {
    setDreamTitle(e.target.value);
    adjustTooltipHeight(dreamdesc);
  };

  const renderDefaultImages = (count: number, type: "user" | "dream") => {
    const defaultImages = [];
    for (let i = 0; i < count; i++) {
      defaultImages.push(
        <div
          key={`default-img-${i}`}
          className={
            type === "user"
              ? "profile-img-round-box"
              : "profile-dream-img-round-box"
          }
        >
          <div
            className={
              type === "user"
                ? "default-img-edit-profile"
                : "default-dream-img-edit-profile"
            }
          >
            <div>
              <label style={{ cursor: "pointer" }}>
                <input
                  className="file-upload"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    opacity: 0,
                    zIndex: 1,
                    cursor: "pointer",
                  }}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  name="image"
                  id="image"
                  multiple
                  onChange={
                    type === "user"
                      ? handleProfileImageChange
                      : handleDreamImageChange
                  }
                />
                <img
                  src="/interact/add-profile-image.svg"
                  alt="Add profile"
                  className={
                    type === "user"
                      ? "profile-add-new-image"
                      : "profile-add-new-image-dream"
                  }
                />
              </label>
            </div>
          </div>
        </div>
      );
    }
    return defaultImages;
  };

  return (
    <Modal
      backdrop={true}
      centered
      show={show}
      onHide={handleClose}
      animation={true}
      contentClassName="border-0"
      dialogClassName="modal-600"
      style={{ borderRadius: "8px" }}
    >
      <div>
        <div className="edit-profile-header">
          <h4 className="dream-slider-title">Edit Profile</h4>
          <img
            onClick={handleClose}
            src="/interact/close.svg"
            alt="Close"
            style={{ cursor: "pointer", position: "absolute", right: "20px" }}
          />
        </div>
        <div className="edit-profile-content">
          <div className="edit-profile-info-advise">
            <span>* Indicates required</span>
          </div>
          <div className="edit-profile-img-name">
            <h5 className="edit-title-for-boxes">Profile</h5>
            {imageIsMobile ? (
              <div className="carousel-wrapper slider-margin-mobile">
                <Carousel
                  responsive={editProfileImagesResponsive}
                  className="carousel-width slider-carousel"
                  sliderClass="d-flex align-items-center justify-content-between"
                  itemClass="dream-card-mobile"
                  arrows={false}
                  swipeable
                  infinite={false}
                >
                  {userData.images
                    .filter((img) => !deletedImages.includes(img.id))
                    .map((img, index) => (
                      <div
                        key={img.id}
                        className={`profile-img-round-box img-${index + 1}`}
                      >
                        <img
                          src={img.avatarUrl}
                          alt="Profile"
                          className="profile-img-circle"
                        />
                        <img
                          src="/interact/delete-image.svg"
                          alt="Delete"
                          className="profile-edit-delete-image"
                          style={{ right: "1px" }}
                          onClick={() => handleDeleteProfileImg(img)}
                        />
                      </div>
                    ))}
                  {addedImages.map((img, index) => (
                    <div
                      key={`added-img-${index}`}
                      className={`profile-img-round-box img-${index + 1}`}
                    >
                      <img
                        src={URL.createObjectURL(img)}
                        alt="New"
                        className="profile-img-circle"
                      />
                      <img
                        src="/interact/delete-image.svg"
                        alt="Delete"
                        className="profile-edit-delete-image"
                        style={{ right: "1px" }}
                        onClick={() => handleDeleteProfileImg(img)}
                      />
                    </div>
                  ))}
                  {renderDefaultImages(
                    5 -
                      userData.images.length +
                      deletedImages.length -
                      addedImages.length,
                    "user"
                  )}
                </Carousel>
              </div>
            ) : (
              <div className="edit-profile-img-container d-flex">
                {userData.images
                  .filter((img) => !deletedImages.includes(img.id))
                  .map((img, index) => (
                    <div
                      key={img.id}
                      className={`profile-img-round-box img-${index + 1}`}
                    >
                      <img
                        src={img.avatarUrl}
                        alt="Profile"
                        className="profile-img-circle"
                      />
                      <img
                        src="/interact/delete-image.svg"
                        alt="Delete"
                        className={
                          isMobile
                            ? "profile-edit-delete-image"
                            : "delete-icon-edit-profile"
                        }
                        onClick={() => handleDeleteProfileImg(img)}
                      />
                    </div>
                  ))}
                {addedImages.map((img, index) => (
                  <div
                    key={`added-img-${index}`}
                    className={`profile-img-round-box img-${index + 1}`}
                  >
                    <img
                      src={URL.createObjectURL(img)}
                      alt="New"
                      className="profile-img-circle"
                    />
                    <img
                      src="/interact/delete-image.svg"
                      alt="Delete"
                      className={
                        isMobile
                          ? "profile-edit-delete-image"
                          : "delete-icon-edit-profile"
                      }
                      onClick={() => handleDeleteProfileImg(img)}
                    />
                  </div>
                ))}
                {renderDefaultImages(
                  5 -
                    userData.images.length +
                    deletedImages.length -
                    addedImages.length,
                  "user"
                )}
              </div>
            )}

            <div className="edit-name-user-container">
              <div className="edit-first-last-name-box">
                <span>First Name*</span>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="edit-profile-name"
                  onChange={handleInputChange}
                  value={userData.firstName}
                />
              </div>
              <div className="edit-first-last-name-box">
                <span>Last Name*</span>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="edit-profile-name"
                  onChange={handleInputChange}
                  value={userData.lastName}
                />
              </div>
            </div>
          </div>

          <div className="edit-profile-biography-box">
            <h5 className="edit-title-for-boxes">Biography</h5>
            <div className="d-flex justify-content-between profile-edit-country-region">
              <div className="edit-first-last-name-box">
                <span>Country*</span>
                <CountryDropdown
                  classes="form-control border border-light form-select countries-region-dropdown"
                  value={country || ""}
                  onChange={(country) => setCountry(country)}
                />
              </div>
              <div className="edit-first-last-name-box">
                <span>City*</span>
                <RegionDropdown
                  country={country}
                  classes="form-control border border-light form-select countries-region-dropdown"
                  value={city || ""}
                  onChange={(city) => setCity(city)}
                />
              </div>
            </div>
            <div className="edit-profile-about d-flex">
              <span>About Me</span>
              <textarea
                id="description"
                name="description"
                ref={textbox}
                value={userData.description}
                onChange={handleInputChange}
                className="edit-profile-textarea"
              />
            </div>
          </div>

          {Object.keys(dream).length !== 0 && dream.progress !== 100 && (
            <div className="edit-profile-dream-container d-flex">
              <h5 className="edit-title-for-boxes">Dream Details</h5>
              {dreamIsMobile ? (
                <div className="carousel-wrapper slider-margin-mobile">
                  <Carousel
                    responsive={editProfileDreamImagesResponsive}
                    className="carousel-width slider-carousel edit-profile-dream-images-carousel-height"
                    sliderClass="d-flex align-items-center justify-content-between"
                    itemClass="dream-card-mobile"
                    arrows={false}
                    swipeable
                    infinite={false}
                  >
                    {dream.images
                      .filter((img) => !deletedDreamImages.includes(img.id))
                      .map((img, index) => (
                        <div
                          key={img.id}
                          className={`profile-dream-img-round-box img-${
                            index + 1
                          }`}
                        >
                          <img
                            src={img.avatarUrl}
                            alt="Dream"
                            className="profile-dream-img-circle"
                          />
                          <img
                            src="/interact/delete-image.svg"
                            alt="Delete"
                            className="profile-edit-delete-image"
                            style={{ left: "4px", right: "0" }}
                            onClick={() => handleDeleteDreamImg(img)}
                          />
                        </div>
                      ))}
                    {addedDreamImages.map((img, index) => (
                      <div
                        key={`added-dream-img-${index}`}
                        className={`profile-dream-img-round-box img-${
                          index + 1
                        }`}
                      >
                        <img
                          src={URL.createObjectURL(img)}
                          alt="New Dream"
                          className="profile-dream-img-circle"
                        />
                        <img
                          src="/interact/delete-image.svg"
                          alt="Delete"
                          className="profile-edit-delete-image"
                          style={{ left: "4px", right: "0" }}
                          onClick={() => handleDeleteDreamImg(img)}
                        />
                      </div>
                    ))}
                    {renderDefaultImages(
                      5 -
                        dream.images.length +
                        deletedDreamImages.length -
                        addedDreamImages.length,
                      "dream"
                    )}
                  </Carousel>
                </div>
              ) : (
                <div className="edit-profile-img-container d-flex">
                  {dream.images
                    .filter((img) => !deletedDreamImages.includes(img.id))
                    .map((img, index) => (
                      <div
                        key={img.id}
                        className={`profile-dream-img-round-box img-${
                          index + 1
                        }`}
                      >
                        <img
                          src={img.avatarUrl}
                          alt="Dream"
                          className="profile-dream-img-circle"
                        />
                        <img
                          src="/interact/delete-image.svg"
                          alt="Delete"
                          className={
                            isMobile
                              ? "profile-edit-delete-image"
                              : "delete-icon-edit-profile "
                          }
                          onClick={() => handleDeleteDreamImg(img)}
                        />
                      </div>
                    ))}
                  {addedDreamImages.map((img, index) => (
                    <div
                      key={`added-dream-img-${index}`}
                      className={`profile-dream-img-round-box img-${index + 1}`}
                    >
                      <img
                        src={URL.createObjectURL(img)}
                        alt="New Dream"
                        className="profile-dream-img-circle"
                      />
                      <img
                        src="/interact/delete-image.svg"
                        alt="Delete"
                        className={
                          isMobile
                            ? "profile-edit-delete-image"
                            : "delete-icon-edit-profile"
                        }
                        onClick={() => handleDeleteDreamImg(img)}
                      />
                    </div>
                  ))}
                  {renderDefaultImages(
                    5 -
                      dream.images.length +
                      deletedDreamImages.length -
                      addedDreamImages.length,
                    "dream"
                  )}
                </div>
              )}

              <div className="edit-profile-about d-flex">
                <span>Dream Description</span>
                <textarea
                  name="dreamTitle"
                  ref={dreamdesc}
                  className="edit-profile-textarea"
                  onChange={handleDreamTitleChange}
                  value={dreamTitle}
                ></textarea>
              </div>
            </div>
          )}

          <div className="edit-profile-modal-footer d-flex justify-content-end">
            <GradientButton
              minWidth="149px"
              text="Save Changes"
              onClick={handleSaveChanges}
            ></GradientButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
