export const responsive = {
  mobileSmall: {
    breakpoint: { max: 520, min: 200 },
    items: 2,
  },
  mobileMedium: {
    breakpoint: { max: 610, min: 521 },
    items: 2.3,
  },
  mobileBig: {
    breakpoint: { max: 700, min: 611 },
    items: 2.4,
  },
  tablet: {
    breakpoint: { max: 770, min: 701 },
    items: 2.77,
  },
};

export const responsivePublicShared = {
  desktop: {
    breakpoint: { max: 3000, min: 800 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 900, min: 200 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const navbarResponsive = {
  mobileSmall: {
    breakpoint: { max: 500, min: 200 },
    items: 2.7,
  },
};

export const birthdayResponsive = {
  mobileSmall: {
    breakpoint: { max: 400, min: 200 },
    items: 2.18,
  },
  mobileMedium: {
    breakpoint: { max: 500, min: 401 },
    items: 2.28,
  },
  mobileBig: {
    breakpoint: { max: 600, min: 501 },
    items: 2.5,
  },
  miniTablet: {
    breakpoint: { max: 650, min: 601 },
    items: 2.7,
  },
  mediumTablet: {
    breakpoint: { max: 700, min: 651 },
    items: 2.9,
  },
  tablet: {
    breakpoint: { max: 768, min: 701 },
    items: 3.2,
  },
};

export const dreamPhotoResponsive = [
  {
    breakpoint: 550,
    cols: 2,
    rows: 1,
    gap: 10,
  },
  {
    breakpoint: 800,
    cols: 3,
    rows: 1,
    gap: 10,
  },
];

export const dreamMakersResponsive = {
  mobileSmall: {
    breakpoint: { max: 450, min: 200 },
    items: 2.1,
  },
  mobileMedium: {
    breakpoint: { max: 500, min: 451 },
    items: 2.28,
  },
  mobileBig: {
    breakpoint: { max: 600, min: 501 },
    items: 3,
  },
  miniTablet: {
    breakpoint: { max: 635, min: 601 },
    items: 3.1,
  },
  mediumTablet: {
    breakpoint: { max: 730, min: 636 },
    items: 3.3,
  },
  tablet: {
    breakpoint: { max: 768, min: 731 },
    items: 3.6,
  },
};

export const editProfileImagesResponsive = {
  mobileBig: {
    breakpoint: { max: 440, min: 411 },
    items: 4.8,
  },
  mobile: {
    breakpoint: { max: 410, min: 391 },
    items: 4.6,
  },
  mobileMedium: {
    breakpoint: { max: 390, min: 371 },
    items: 4.3,
  },
  mobileSmall: {
    breakpoint: { max: 370, min: 351 },
    items: 4,
  },
  mobileXSmall: {
    breakpoint: { max: 350, min: 200 },
    items: 3.8,
  },
};

export const editProfileDreamImagesResponsive = {
  mobileBig: {
    breakpoint: { max: 490, min: 451 },
    items: 4.6,
  },
  mobile: {
    breakpoint: { max: 450, min: 431 },
    items: 4.4,
  },
  mobileMedium: {
    breakpoint: { max: 430, min: 401 },
    items: 4.2,
  },
  mobileSmall: {
    breakpoint: { max: 400, min: 371 },
    items: 3.8,
  },
  mobileXSmall: {
    breakpoint: { max: 370, min: 341 },
    items: 3.6,
  },
  mobileXsSmall: {
    breakpoint: { max: 370, min: 341 },
    items: 3.4,
  },
};
