import React, { useState } from "react";
import Card from "../../components/Partials/Card";
import { Carousel, ProgressBar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import coverImg from "../../assets/images/coverImg.png";
import defaultImage from "../../assets/images/defaultUser.png";
import "../../styles/Dashboard.css";
import DreamMakersModal from "../../components/DreamMakersModal";
import {
  getComments,
  getPublicDreamDonations,
  shareNotification,
} from "../../services/api";
import { setBgLoader, setPublicReceived } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import { Comments } from "../../consts/SvgIcon";
import ResponsiveCommentsModal from "../../components/Comments/ResponsiveCommentsModal";
import { UsersModule } from "src/types/redux";
import { DashboardCardProps } from "src/types/pages";
import { ShareTo } from "src/types/models";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import { DreamDto } from "src/services/api/data-contracts";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "src/components/Tooltip/TooltipDetails";
import { fulfillCheck } from "src/utils/fulfillCheck";
import { setActiveReply, updateDreamCommentsCount } from "src/store/action";
import { commentType } from "src/consts/commentsTypeEnum";

const DashboardCard = ({
  dream,
  isFulfilled,
  mainImage,
  fulfilled,
}: DashboardCardProps) => {
  const [modalProps, setModalProps] = useState(false);
  const [show, setShow] = useState(false);
  const currentUser = useSelector(
    (state: UsersModule) => state.usersModule
  ).user;
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const progress = calculateDreamProgress(dream as DreamDto);
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(Boolean);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tooltipKey = generateTooltipKey(dream?.user?.id);
  const isDreamFulfilled = fulfillCheck(allFulfilled, dream, dream?.user?.id);
  const [currentUserComment, setComment] = useState<string>("");

  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img}>
        <img src={img} className="profile-list-image-style" alt="img" />
      </Carousel.Item>
    );
  };

  const openCommentModal = (dreamId) => {
    setComment("");
    dispatch(setActiveReply(""));
    dispatch(setBgLoader(true));
    setModalProps(true);
    getComments(dreamId, 1, 10).then((res) => {
      setComments(res?.data?.results);
      setHasMore(res?.data?.meta?.hasNextPage);
      setModalProps(true);
      dispatch(updateDreamCommentsCount(dreamId, res?.data?.meta?.itemCount));
    });
  };

  const progressClick = () => {
    dispatch(setPublicReceived({}));
    setShow((prev) => !prev);
    getPublicDreamDonations(dream?.id, 1).then((res) => {
      dispatch(setPublicReceived(res?.data));
    });
  };
  const shareTo: ShareTo = async ({
    link,
    type,
    dreamId,
    replyUserId,
    media,
    dreamAngelId,
    setShareModal,
  }) => {
    if (
      (!!navigator.canShare &&
        navigator.share &&
        /android/i.test(navigator.userAgent)) ||
      /iPad|iPhone|iPod/.test(navigator.userAgent)
    ) {
      await navigator.share({
        url: link,
      });
      type &&
        shareNotification({ type, dreamId, replyUserId, media, dreamAngelId });
    } else {
      setShareModal(true);
    }
  };

  return (
    <Card className="rounded-3 mb-0 shadow-card" style={{ height: "300px" }}>
      <Card.Body className="profile-page p-0">
        <div className="card-width profile-header-image d-flex flex-column align-items-center">
          <div className="cover-container" style={{ width: "100%" }}>
            <Carousel
              indicators={false}
              interval={null}
              controls={dream?.images?.length > 1}
            >
              {dream.images?.length
                ? dream.images?.map((img) => carouselItem(img?.avatarUrl))
                : carouselItem(coverImg)}
            </Carousel>
          </div>
          <div className="card-width profile-info p-2">
            <div className="user-detail">
              <div className="text-center">
                <div className="profile-detail">
                  <div
                    style={{ marginTop: "-2.5rem" }}
                    className="profile-img d-flex align-items-center flex-column"
                  >
                    <Link to={`/dashboard/public-page/${dream?.user?.id}`}>
                      <div
                        className={
                          "d-flex justify-content-center align-items-center bg-white avatar-60"
                        }
                        style={{ borderRadius: "50%", width: "61px" }}
                      >
                        <img
                          src={dream?.user?.mainImageUrl || defaultImage}
                          alt="profile-img"
                          className={"avatar-55"}
                        />
                      </div>
                    </Link>
                    <div
                      className="text-dark cursor-pointer"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <span
                        className="fw-bolder my-title text-underline"
                        data-tooltip-id={tooltipKey}
                        onClick={() =>
                          navigate(`/dashboard/public-page/${dream?.user?.id}`)
                        }
                      >
                        {`${dream?.user?.firstName || "No Name"} 
                        ${dream.user?.lastName || ""}`}
                      </span>
                      <TooltipDetails
                        userId={dream?.user?.id}
                        isFulfilled={isFulfilled}
                        allFulfilled={allFulfilled}
                        tooltipKey={tooltipKey}
                      />
                      <span
                        style={{ height: "35px" }}
                        className="my-span lh-sm"
                      >
                        {dream?.title || "No Title"}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="w-100"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div style={{ height: "30px", marginBottom: "10px" }}>
                    <div className="w-100 d-flex justify-content-end">
                      <span
                        className="me-1 fw-bold text-dark"
                        style={{ fontSize: "12px" }}
                      >
                        {isNaN(progress) ? 0 : progress}%
                      </span>
                    </div>
                    <ProgressBar
                      onClick={() => progressClick()}
                      style={{ height: "10px", cursor: "pointer" }}
                      now={progress}
                      variant={"gradient"}
                      className={!fulfilled ? "black-hover" : ""}
                    />
                  </div>
                  {publicReceived?.results?.length ? (
                    <DreamMakersModal
                      donations={publicReceived}
                      show={show}
                      setShow={setShow}
                      dreamId={dream.id}
                      card={true}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="d-flex justify-content-between mt-2 mb-2">
                    <div
                      className="dashboard-card-buttons custom-gradient"
                      style={{ width: "65%" }}
                      onClick={() =>
                        isDreamFulfilled
                          ? navigate(
                              `/dashboard/public-page/${dream?.user?.id}`
                            )
                          : isFulfilled(dream?.id)
                      }
                    >
                      <div className="dashboard-card-buttons-text">
                        <span>
                          {isDreamFulfilled ? "View Profile" : "Fulfill"}
                        </span>
                      </div>
                    </div>
                    <div
                      className="dashboard-card-buttons custom-gradient"
                      style={{ width: "30%" }}
                      onClick={() => openCommentModal(dream?.id)}
                    >
                      <span className="dashboard-card-buttons-text">
                        {Comments()}
                      </span>
                    </div>
                  </div>
                  <ResponsiveCommentsModal
                    dream={dream}
                    modalProps={modalProps}
                    setModalProps={setModalProps}
                    setComments={setComments}
                    comments={comments}
                    setHasMore={setHasMore}
                    hasMore={hasMore}
                    mainImage={mainImage}
                    isFulfilled={isFulfilled}
                    currentUser={currentUser}
                    allFulfilled={allFulfilled}
                    progress={progress}
                    user={dream?.user}
                    shareTo={shareTo}
                    type={commentType.dream}
                    currentUserComment={currentUserComment}
                    setComment={setComment}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DashboardCard;
