import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/defaultUser.png";
import { wingTypes } from "../../consts/notifications";

const Wing = ({ el, setShow }) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    if (el.type) {
      navigate(`/${wingTypes[el.type]}/${el?.charityId}`);
    }
    setShow(false);
  };
  return (
    <div className="notification-item-container" onClick={navigateTo}>
      <div className="d-flex gap-2">
        <div>
          <img
            src={
              el?.notifications_charity?.images?.[0]?.avatarUrl || defaultImage
            }
            alt="profile"
            className="wing-avatar-notice"
          />
        </div>
        <div className="d-flex flex-column ">
          <div>
            <span
              className="fs-5 text-dark fw-bold"
              style={{ color: "#A8A6A6" }}
            >
              "{el?.notifications_charity?.title}"
            </span>{" "}
            <span className="fs-5" style={{ color: "#A8A6A6" }}>
              wing donation {el?.title}
            </span>
          </div>
          <span className="text-dark">
            {moment(`${el?.createdAt}`)?.fromNow(true)} ago
          </span>
        </div>
      </div>
      {!el?.seen && <div className="notify-circle-card" />}
    </div>
  );
};
export default Wing;
