import { Carousel, CarouselItem, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import defaultImage from "../../assets/images/defaultUser.png";
import React from "react";
import { RandomModalProps } from "src/types/components-props";

const RandomModal = ({
  customModals,
  setCustomModal,
  randomDonationId,
}: RandomModalProps) => {
  return (
    <Modal
      key={randomDonationId?.id}
      backdrop={true}
      centered
      show={!!customModals}
      onHide={() => setCustomModal("")}
      animation={true}
    >
      <div className="d-flex flex-row flex-wrap random-container">
        <div
          style={{ width: "100%" }}
          className="mb-2 d-flex justify-content-between"
        >
          <div>
            <span className="random-header-span">
              Your donation was successful!
            </span>
          </div>
          <div onClick={() => setCustomModal("")}>
            <span className="fa-close-icon" />
          </div>
        </div>
        <div
          className="mb-4"
          style={{ height: "auto", width: "100%", borderRadius: "8px" }}
        >
          <Carousel
            slide={true}
            fade={true}
            interval={3000}
            controls={randomDonationId?.images.length > 1}
          >
            {randomDonationId?.images?.map((el) => (
              <CarouselItem
                style={{ maxHeight: "300px", borderRadius: "8px" }}
                key={el.id}
              >
                <img
                  className="img-fluid random-image"
                  src={el?.avatarUrl}
                  alt="el.name"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    maxHeight: "290px",
                    borderRadius: "8px",
                  }}
                />
              </CarouselItem>
            ))}
          </Carousel>
        </div>

        <div className="random-donation-details">
          <div>
            <span className="random-details-header">Donation Details</span>
          </div>
          <div className="d-flex align-items-center">
            <Link to={`/dashboard/public-page/${randomDonationId?.user?.id}`}>
              <img
                className="fluid rounded-circle avatar-50 "
                style={{ objectFit: "cover", marginRight: "10px" }}
                src={randomDonationId?.user?.mainImageUrl || defaultImage}
                alt="randomDonationId.user.imageURL"
              />
            </Link>
            <div>
              <span className="random-user-name">
                {randomDonationId?.user?.firstName}{" "}
                {randomDonationId?.user?.lastName}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column overflow-scroll">
            <p className="random-title">Dream Title:</p>
            <span>{randomDonationId?.title}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RandomModal;
