import React, { useEffect, useRef, useState } from "react";
import {
  createDreamAngelComments,
  getDreamAngelComments,
} from "../../services/api";
import Comment from "./Comment";
import InputComment from "./InputComment";
import { useDispatch, useSelector } from "react-redux";
import { AngelCommentData, UsersModule } from "../../types/redux";
import { useNavigate } from "react-router-dom";
import { commentType } from "src/consts/commentsTypeEnum";
import { updateAngelCommentsCount } from "src/store/action";

const AngelCommentsFeed = ({ post, user, isFulfilled }) => {
  const [commentInput, setCommentInput] = useState("");
  const [commentData, setCommentData] = useState([]);
  const scrollref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emojiPicker, showEmojiPicker] = useState(false);
  const angelComments = useSelector(
    (state: AngelCommentData) => state.comments.angelComments[post?.id] || {}
  );
  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;

  useEffect(() => {
    getDreamAngelComments(post?.id, 1, 10).then((res) => {
      setCommentData(res.data.results);
      dispatch(updateAngelCommentsCount(post?.id, res?.data?.meta?.itemCount));
    });
  }, [post?.id]);

  useEffect(() => {
    return () => {
      setCommentData([]);
    };
  }, [post?.id]);

  const addComment = () => {
    showEmojiPicker(false);
    if (commentInput?.length) {
      createDreamAngelComments({
        message: commentInput,
        dreamAngelId: post?.dreamAngelId,
        newsFeedId: post?.id,
      }).then(() => {
        getDreamAngelComments(post?.id, 1, 10).then((res) => {
          setCommentData(res?.data?.results);
          dispatch(
            updateAngelCommentsCount(post?.id, res?.data?.meta?.itemCount)
          );
        });
      });

      setCommentInput("");
      let scrollDiv = document.getElementById("commentScroll");
      scrollDiv.scrollTop = 0;
    }
  };

  const handleComment = (event) => {
    setCommentInput(event.target.value);
    if (!commentInput.length && event.key === "Enter") {
      event.preventDefault();
    } else if (event.key === "Enter" && commentInput.length) {
      event.preventDefault();
      addComment();
    }
  };

  const commentsList = commentData.map((elem, index) => {
    if (index === 0) {
      return (
        <div key={`comment-${index}-${elem?.id}`}>
          <Comment
            autoFocus={false}
            user={user}
            dreamId={post?.dreamAngelId}
            newsFeedId={post?.id}
            addLines
            elem={elem}
            isFulfilled={isFulfilled}
            type={commentType.angel}
            textFormatted
          />
        </div>
      );
    }
    return <></>;
  });

  return (
    <>
      <div>
        {!!commentsList?.length && (
          <div className="comment-line m-0">
            <ul className="post-comments p-0 m-0 container-fluid mb-1">
              <ul
                style={{ height: "80px" }}
                className="ps-0 overflow-hidden"
                id="dream-comment-scroll"
                ref={scrollref}
              >
                {commentsList}
              </ul>
            </ul>
            {commentData?.length > 1 && (
              <div className="mt-2">
                <span
                  className="span-hover-underline text-dark cursor-pointer"
                  onClick={() => navigate(`/angel/${post?.id}`)}
                >
                  Read all {angelComments.count} comments
                </span>
              </div>
            )}
          </div>
        )}
        <InputComment
          postId={post.id}
          currentUserComment={commentInput}
          setComment={setCommentInput}
          emojiPicker={emojiPicker}
          showEmojiPicker={showEmojiPicker}
          mainImage={mainImg}
          handleComment={(e) => handleComment(e)}
          addComment={() => addComment()}
          style={{
            position: "unset",
            border: "none",
            marginTop: 0,
          }}
        />
      </div>
    </>
  );
};

export default AngelCommentsFeed;
