import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useFetchActivities } from "src/hooks/useActivity";
import loaderGif from "../../../assets/images/page-img/page-load-loader.gif";
import ActivityDreamCard from "./ActivityDreamCard";
import Charity from "src/pages/NewsFeed/Posts/Charity";
import { ActivityProps } from "src/types/profile-props";

export default function ActivityFulfilled({
  user,
  isFulfilled,
}: ActivityProps) {
  const { activities, fetchNextPage, hasNextPage } = useFetchActivities(
    user,
    "DONATION"
  );
  return (
    <div style={{ overflow: "auto" }}>
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        useWindow={true}
        loader={
          <div className="d-flex justify-content-center w-100" key="loader">
            <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
          </div>
        }
      >
        <div
          style={{ padding: "20px", gap: "20px" }}
          className="d-flex flex-column"
        >
          {activities.map((activity, index: number) => {
            if (activity.type === "donation") {
              return (
                <ActivityDreamCard
                  key={index}
                  post={activity.data}
                  user={user}
                  type={activity.type}
                  timestamp={activity.timestamp}
                  isFulfilled={isFulfilled}
                />
              );
            } else if (activity.type === "wing_donation") {
              return (
                <Charity
                  key={index}
                  post={activity}
                  user={user}
                  type={activity.type}
                  timestamp={activity.timestamp}
                  isFulfilled={isFulfilled}
                />
              );
            }
            return <></>;
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
}
