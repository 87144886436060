import React, { useState } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import DonorsCard from "./DonorsCard";
import { getTopDonorsThisMonth } from "../../services/api";
import { useQuery } from "@tanstack/react-query";
import ViewAll from "./ViewAll";
import { SLIDERS_ENUM } from "src/consts/slidersEnum";
import Carousel from "react-multi-carousel";
import { birthdayResponsive } from "../../consts/breakpoints";
import { useSelector } from "react-redux";
import { UsersModule } from "../../types/redux";
import { TopDonorDto } from "src/services/api/data-contracts";
import { scrollCarousel } from "src/utils/scrollCarousel";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right-icon.svg";

const TopDonors = ({ isFulfilled, sliderWidth }) => {
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const isMobile = useIsMobile(768);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { data: topDonors, isLoading } = useQuery<TopDonorDto[]>({
    queryKey: ["topDonors"],
    queryFn: async () => {
      const res = await getTopDonorsThisMonth();
      return res.data || [];
    },
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {topDonors.length >= 5 && (
        <div className="birthday-app">
          <div className="dream-header">
            <h4 className="dream-slider-title">Top Dream Makers:</h4>
            <div className="d-flex align-items-center">
              {topDonors.length >= 12 ? (
                <div onClick={handleShowModal} className="view-all-button">
                  <p className="view-all">view all</p>
                </div>
              ) : (
                <></>
              )}
              {!isMobile ? (
                <div className="carousel-arrow-container">
                  <div
                    className="icon-container-hover-right"
                    onClick={() => scrollCarousel("left", "top-donors-scroll")}
                  >
                    <ArrowLeft className="img-arrow-hover" />
                  </div>
                  <div
                    className="icon-container-hover-left"
                    onClick={() => scrollCarousel("right", "top-donors-scroll")}
                  >
                    <ArrowRight className="img-arrow-hover" />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <ViewAll
              show={showModal}
              handleClose={handleCloseModal}
              items={topDonors}
              isFulfilled={isFulfilled}
              type={SLIDERS_ENUM.TOP_DONORS}
            />
          </div>
          {isMobile ? (
            <div className="carousel-wrapper slider-margin-mobile">
              <Carousel
                responsive={birthdayResponsive}
                className="carousel-width slider-carousel"
                sliderClass="d-flex align-items-center justify-content-between"
                itemClass="dream-card-mobile"
                arrows={false}
                swipeable
                infinite={false}
                draggable={true}
              >
                {topDonors.slice(0, 12).map((donation) => (
                  <DonorsCard
                    key={donation.user.id}
                    donations={donation}
                    isFulfilled={isFulfilled}
                    allFulfilled={allFulfilled}
                  />
                ))}
              </Carousel>
            </div>
          ) : (
            <div
              className="dream-dreams-container top-donors-scroll"
              style={{ width: sliderWidth }}
            >
              {topDonors.slice(0, 12).map((donation) => (
                <DonorsCard
                  key={donation.user.id}
                  donations={donation}
                  isFulfilled={isFulfilled}
                  allFulfilled={allFulfilled}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default TopDonors;
