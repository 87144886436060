import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { postFeedback } from "../../services/api";
import { toast } from "react-toastify";
import GradientButton from "../Buttons/GradientButton";
import { LeaveFeedbackProps } from "src/types/components-props";

const LeaveFeedback = ({ setFeedback, feedback }: LeaveFeedbackProps) => {
  const notify = (message) => toast.success(message);
  const [message, setMessage] = useState("");
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const sendMessage = () => {
    postFeedback(message).then((res) => {
      if (!res) return;
      setFeedback(false);
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Success!");
    });
  };
  return (
    <Modal
      backdrop={true}
      centered
      show={feedback}
      onHide={() => setFeedback(false)}
      animation={true}
      contentClassName="border-radius-feedback border-0"
    >
      <div className="d-flex flex-column justify-content-between align-items-center">
        <h4 className="fw-bold mb-1 text-black">Leave feedback</h4>
        <p>Your feedback will help us improve our services</p>
        <textarea
          id="message"
          name="message"
          rows={4}
          placeholder="Leave feedback..."
          className="form-control border border-light mt-1 mb-4"
          style={{
            backgroundColor: "#f8f9fa",
            resize: "none",
            width: "100%",
          }}
          value={message}
          onChange={handleMessageChange}
        />
        <GradientButton
          text="Send"
          minWidth="206px"
          onClick={() => sendMessage()}
        />
      </div>
    </Modal>
  );
};
export default LeaveFeedback;
