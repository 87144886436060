import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
const carouselCard = [
  "Inspire others",
  "Create a lasting cycle of goodness",
  "Get recognized through charity",
  "Enjoy making a difference",
  "Achieve your goals for half the price",
  "Help others' dreams come true",
  "Achieve your goals",
  "Make a difference with just 1$ ",
  "Create a 10x times visibility impact, worldwide",
  "Build Your Collection of Fulfilled Dreams",
  "Count the Lives You`ve Impacted.",
  "Grow and dream together",
  "Get Motivation by supporting others",
  "United and Helpfull Community",
  "Inspire others",
  "Create a lasting cycle of goodness",
  "Get recognized through charity",
  "Enjoy making a difference",
  "Achieve your goals for half the price",
  "Help others' dreams come true",
  "Achieve your goals",
  "Make a difference with just 1$ ",
  "Create a 10x times visibility impact, worldwide",
  "Build Your Collection of Fulfilled Dreams",
  "Count the Lives You`ve Impacted.",
  "Grow and dream together",
  "Get Motivation by supporting others",
  "United and Helpfull Community",
];

const LiveYourDreamSection = () => {
  const isMobile = useIsMobile(660);

  return (
    <div className="live-dream-section">
      <div className="blurred-background" />
      <div className="section-container-live-dream">
        <div className="live-dream-text-container">
          <h2 className="live-dream-title">Join Our Dream Community!</h2>
          <p className="live-dream-description">
            Small ripples create mighty waves. Your $1 today could change the
            world tomorrow – including your own! Every small donation you make
            brings you halfway closer to your own dream's realization.
          </p>
          <a className="live-dream-btn" href="/login">
            Join Today
          </a>
        </div>
        {isMobile ? (
          <div className="slider" style={{ height: "32px" }}>
            <div className="slide-track-to-right">
              {carouselCard.map((card, index) => {
                return (
                  <div
                    className="live-dream-carousel-item"
                    key={index}
                    style={{ margin: "0 10px" }}
                  >
                    <span>{card}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="slider-live-dream">
            <div className="slide-track-to-right-live-dream ">
              {carouselCard.map((card, index) => {
                return (
                  <div className="live-dream-carousel-item" key={index}>
                    <span>{card}</span>
                  </div>
                );
              })}
              {carouselCard.map((card, index) => {
                return (
                  <div className="live-dream-carousel-item" key={index}>
                    <span>{card}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveYourDreamSection;
