import React from "react";
import GradientButton from "../Buttons/GradientButton";
import myDream from "../../assets/images/myDream.png";
import { useNavigate } from "react-router-dom";

const ProjectPurposeSection = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center justify-content-center project-purpose-container column-card">
      <div className="section-container-row section-container-row-secured">
        <div className=" purpose-info d-flex flex-column">
          <p className="wing-title-section">Project Purpose</p>
          <span className="wing-text">
            At Dreamerz, we're building a platform where people help each
            other's dreams come true — a place with millions of friends ready to
            help you. It's not just a nice idea — it's happening now. Together,
            we're making dreams a reality.
          </span>
          <div className="purpose-btn">
            <GradientButton
              onClick={() => navigate("/login")}
              text="Donate now"
              minWidth={"150px"}
              textClassName="purpose-button-text"
              className="start-gradient-button-project-purpose"
            />
          </div>
        </div>
        <div className="purpose-image">
          <img src={myDream} alt="I want to buy a house" className="w-100" />
        </div>
      </div>
    </div>
  );
};

export default ProjectPurposeSection;
