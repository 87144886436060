import React, { useState, useEffect } from "react";
import { likeDream, likeNewsfeedDream } from "../../services/api";
import { ReactComponent as Like } from "../../assets/images/socials/like.svg";
// import { ReactComponent as Send } from "../../assets/images/socials/send.svg";
// import { ReactComponent as Share } from "../../assets/images/socials/share.svg";
// import { ReactComponent as Save } from "../../assets/images/socials/save.svg";
import { toast } from "react-toastify";
import "../../styles/Dreams.css";
import _ from "lodash";
import { SOCIALS_ENUM } from "../../consts/socialsEnum";

const SocialInteraction = ({ post, type }) => {
  const notify = (message) => toast.error(message);
  const profileId = localStorage.getItem("profileId");
  const [liked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);

  useEffect(() => {
    const typeToPropertyPath = {
      [SOCIALS_ENUM.NEWSFEED_DREAM]: "newsFeedDream.likedDreamsByUsers",
      [SOCIALS_ENUM.PROFILE_DREAM]: "likedDreamsByUsers",
      [SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL]: "likedNewsFeedByUsers",
      [SOCIALS_ENUM.NEWSFEED_CHARITY]: "likedNewsFeedByUsers",
    };

    const propertyPath = typeToPropertyPath[type];
    const users = propertyPath ? _.get(post, propertyPath, []) : [];

    const userHasLiked = users.some((user) => user.id === profileId);
    setLiked(userHasLiked);
    setLikesCount(users.length);
  }, [post, type, profileId]);

  const handleLikeClick = async () => {
    try {
      const response = await (() => {
        switch (type) {
          case SOCIALS_ENUM.NEWSFEED_DREAM:
            return likeDream(post.dreamId);
          case SOCIALS_ENUM.PROFILE_DREAM:
            return likeDream(post.id);
          case SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL:
            return likeNewsfeedDream(post.id);
          case SOCIALS_ENUM.NEWSFEED_CHARITY:
            return likeNewsfeedDream(post.id);
          default:
            return null;
        }
      })();

      if (response) {
        const userHasLiked = !liked;
        setLiked(userHasLiked);
        setLikesCount(response.data.likedDreamsByUsers.length);
      } else {
        throw new Error("Like failed");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="public-dream-social-parts d-flex">
      <div className="dream-social-action d-flex justify-content-between">
        <div className="socials-left">
          <Like
            fill={liked ? "#B0E8E8" : "white"}
            stroke={liked ? "#7EC6CE" : "#828282"}
            className={liked ? "" : "like-icon-default-styles"}
            style={{ cursor: "pointer" }}
            onClick={handleLikeClick}
          />
          {/* <Send
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
          /> */}
        </div>
        <div className="socials-right">
          {/* <Share fill="white" stroke="#828282" style={{ cursor: "pointer" }} />
          <Save
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
          /> */}
        </div>
      </div>
      <div className="dream-stats-row d-flex">
        <div className="d-flex dream-profile-first-container">
          <p className="fw-bold">{likesCount}</p>
          <p>Likes</p>
        </div>
        {/* <div className="d-flex dream-profile-second-container">
          <p>Shared</p>
          <p>0 times</p>
        </div>
        <div className="d-flex dream-profile-third-container">
          <p>Saved</p>
          <p>0 times</p>
        </div> */}
      </div>
    </div>
  );
};

export default SocialInteraction;
