import React from "react";

const VideoAboutDreamerz = () => {
  return (
    <div className="dreamerz-video-container">
      <div className="section-container-dreamerz-video">
        <h2 className="video-title">Why Choose Dreamerz</h2>
        <div className="dreamerz-video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/b1FmT-p5BKI"
            title="Dreamerz - The Social Network For Dreams. Join Now!"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoAboutDreamerz;
