import React, { useEffect, useState } from "react";
import { Card, Col, Container } from "react-bootstrap";
import {
  checkoutSession,
  deleteSubscription,
  getPricesId,
  getSubscription,
  getUser,
} from "../../services/api";
import { useNavigate } from "react-router-dom";
import "../../styles/dreamerzLogin.css";
import "../../styles/Dashboard.css";
import { setBgLoader, setUser } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../hooks/useQuery";
import { pushEvent } from "../../utils";
import { UsersModule } from "src/types/redux";
import { SubscriptionModel } from "src/types/components-props";

const successUrl = "https://dreamerz.net/pricing-page?subscription=success";
const cancelUrl = "https://dreamerz.net/pricing-page?subscription=canceled";

let secondQuery;

const Pricing = () => {
  const query = useQuery();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [subscription, setSubscription] = useState<SubscriptionModel | null>(
    null
  );

  useEffect(() => {
    if (secondQuery !== query) {
      secondQuery = query;
      if (query === "success")
        pushEvent("success_subscription", "pricing_page");
    }
  }, [query]);

  useEffect(() => {
    dispatch(setBgLoader(true));
    getPricesId().then((res) => {
      setCards([
        {
          category: "Basic",
          price: "$11",
          dreams: 10,
          active: false,
          id: res.data?.basicPrice,
        },
        {
          category: "Standard",
          price: "$110",
          dreams: 100,
          active: false,
          id: res.data?.standardPrice,
        },
        {
          category: "Premium",
          price: "$1100",
          dreams: 1000,
          active: false,
          id: res.data?.premiumPrice,
        },
      ]);
    });
    if (user.monthlySubscriptionId) {
      getSubscription().then((res) => {
        setId(
          res.data?.data?.filter(
            (sub) =>
              sub.id === user.monthlySubscriptionId && sub.status === "active"
          )?.[0]?.plan.id
        );
        setSubscription(
          res.data.data?.filter(
            (sub) => sub.id === user.monthlySubscriptionId
          )?.[0]?.latest_invoice?.lines.data[0]
        );
      });
    }
  }, []);

  const setPayment = (card) => {
    const data = { priceId: card.id, successUrl, cancelUrl };
    checkoutSession(data).then((res) => {
      window.location = res.data?.checkoutSessionUrl;
    });
  };
  const cancelSubscription = async () => {
    dispatch(setBgLoader(true));
    await deleteSubscription(user.monthlySubscriptionId);
    const res = await getSubscription();
    setId(
      res.data.data?.filter(
        (sub) =>
          sub.id === user.monthlySubscriptionId && sub.status === "active"
      )?.[0]?.plan.id
    );
    setSubscription(
      res.data.data?.filter((sub) => sub.id === user.monthlySubscriptionId)
        .latest_invoice?.lines.data[0]
    );
    pushEvent("canceled_subscription", "pricing_page");
    const response = await getUser();
    dispatch(setUser(response?.data));
    return response;
  };
  const refreshSubscription = async (card) => {
    cancelSubscription().then(() => {
      setPayment(card);
    });
  };
  return (
    <>
      <div className="bg-img-price padding-60-responsive h-100">
        <Container>
          <Col lg="12">
            <Card
              className="bg-white d-flex flex-column p-4 shadow-card "
              style={{
                height: "auto",
              }}
            >
              <div className="d-flex justify-content-between mb-4">
                <h3 className="text-dark">Pricing</h3>
                <span
                  onClick={() => navigate("/newsfeed")}
                  style={{ cursor: "pointer" }}
                >
                  Skip
                </span>
              </div>
              <div className="d-flex flex-row flex-wrap">
                {cards.map((card, index) => (
                  <Col
                    onMouseOver={() => {
                      card.active = true;
                      setCards([...cards]);
                    }}
                    onMouseOut={() => {
                      card.active = false;
                      setCards([...cards]);
                    }}
                    key={index}
                    xs="12"
                    sm="6"
                    md="4"
                  >
                    <Card
                      className="me-2 border-gradient"
                      style={
                        card.active && id !== card.id
                          ? {
                              boxShadow:
                                "0px 33px 20px rgba(0, 0, 0, 0.06), 0px 7.371px 4.467px rgba(0, 0, 0, 0.01), 0px 2.195px 1.33px rgba(0, 0, 0, 0.01)",
                            }
                          : { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)" }
                      }
                    >
                      <Card.Body className=" text-start pt-4 pb-4">
                        <div
                          style={
                            id !== card.id
                              ? { marginBottom: "112px" }
                              : { marginBottom: "0" }
                          }
                        >
                          <p>{card.category}</p>
                          <div className="d-flex align-items-end justify-content-center">
                            <h2 className="display-3">
                              <b>{card.price}</b>
                            </h2>
                            <small className="mb-3 text-dark">/ Month</small>
                          </div>
                          <p className="text-center mb-4 text-dark">
                            Fulfill {card.dreams} dreams
                          </p>
                        </div>
                        {id === card.id && subscription?.period ? (
                          <div className="mt-3">
                            <h6 className="mb-3 text-center">
                              {new Date(subscription?.period?.start * 1000)
                                ?.toISOString()
                                ?.split("T")[0]
                                ?.replaceAll("-", "/")
                                ?.split("/")
                                ?.reverse()
                                ?.join("/")}{" "}
                              -
                              {" " +
                                new Date(subscription?.period?.end * 1000)
                                  ?.toISOString()
                                  ?.split("T")[0]
                                  ?.replaceAll("-", "/")
                                  ?.split("/")
                                  ?.reverse()
                                  ?.join("/")}
                            </h6>
                            <div
                              className={
                                "dashboard-card-buttons custom-gradient"
                              }
                              style={{ width: "100%" }}
                              onClick={() => cancelSubscription()}
                            >
                              <div className={"dashboard-card-buttons-text"}>
                                <span>Cancel subscription</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          className={
                            id !== card.id
                              ? "dashboard-card-buttons custom-gradient mt-5"
                              : "dashboard-card-buttons custom-gradient mt-1"
                          }
                          style={{ width: "100%" }}
                          onClick={() =>
                            id !== card.id
                              ? setPayment(card)
                              : refreshSubscription(card)
                          }
                        >
                          <div className={"dashboard-card-buttons-text"}>
                            <span>
                              {id !== card.id ? "Subscribe" : "Refresh"}
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </div>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Pricing;
