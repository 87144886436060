import { getRefreshToken, getUser } from "./api";
import {
  setBgLoader,
  setErrorMessage,
  setShowLoader,
  setUser,
} from "../store/users";
import { store } from "../store";
import { toast } from "react-toastify";
import { HttpClient } from "./api/http-client";

const notify = (message) => toast.warning(message);
const api = new HttpClient({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    authorization: "Bearer " + localStorage.getItem("accessToken"),
  },
});

api.instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.authorization = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.instance.interceptors.response.use(
  (res) => {
    store.dispatch(setErrorMessage(""));
    store.dispatch(setShowLoader(false));
    store.dispatch(setBgLoader(false));
    return res;
  },
  async (err) => {
    store.dispatch(setBgLoader(false));
    store.dispatch(setShowLoader(false));
    const originalConfig = err.config;
    if (err.response.status === 413) {
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("The picture is too large");
    }
    if (err.response.status === 400) {
      toast.dismiss();
      toast.clearWaitingQueue();
      const errorMessage =
        err.response.data?.exception?.message || err.response.data?.exception;
      if (typeof errorMessage === "string") {
        notify(errorMessage);
        store.dispatch(setErrorMessage(errorMessage));
      } else if (
        !errorMessage &&
        originalConfig.url.includes("/api/v1/auth/login")
      ) {
        notify("Wrong credentials provided");
        store.dispatch(setErrorMessage("Wrong credentials provided"));
      } else {
        notify(errorMessage.toString());
        store.dispatch(setErrorMessage(errorMessage.toString()));
      }
    }
    if (err.response.status === 401) {
      getRefreshToken()
        .then((rs) => {
          const { accessToken, refreshToken } = rs.data;
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("accessToken", accessToken);
          getUser().then((response) => {
            store.dispatch(setUser(response.data));
          });
          return api.instance(originalConfig);
        })
        .catch((error) => {
          store.dispatch(setBgLoader(true));
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("profileId");
          window.location.pathname = "/";
          store.dispatch(setBgLoader(false));
          Promise.reject(error);
        });
      return Promise.reject(err);
    }
  }
);

export default api.instance;
