import {Row} from "react-bootstrap";
import React from "react";
import {useNavigate} from "react-router-dom";
import NavBarComponent from "src/components/NavBar/NavBar";
import {useSelector} from "react-redux";
import { UsersModule } from "src/types/redux";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const user = useSelector((state: UsersModule) => state.usersModule).user;

  return (
    <div>
      {user?.id ? (
        <NavBarComponent />
      ) : (
        <header className="login-header">
          <Row className="p-0 m-0 d-flex w-100 align-items-center">
            <div className="d-flex justify-content-between">
              <div className="d-flex ms-5">
                <div
                  className="fa-logo-icon cursor-pointer"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </Row>
        </header>
      )}
      <div className="mt-4">
        <div className="d-flex justify-content-center mb-5">
          <h1 className="text-black fw-bold">Privacy policy</h1>
        </div>
        <div className="terms-padding">
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Welcome to Dreamerz!</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              Dreamerz is a social platform where every user can realize it's
              own dream and help others fulfill their dreams by interacting with
              other Dreamerz and Dream Angels sharing one $tar (dollar) for a
              dream. This updated Privacy Policy applies to Dreamerz, Dream
              Angels, Wing Donations and all users of our platform, and is part
              of our{" "}
              <a href="/terms-conditions">
                <u>Terms of Use</u>
              </a>
              . Dreamerz is a global company. By using our platform, you agree
              that your personal information that you provide directly to us, or
              that we collect through your use of the platform, may be
              transferred to and stored in the United States and handled as
              described in this Policy.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Information You Provide Through Your Account
            </h3>
            <ul className="text-dark p-0" style={{ fontSize: "16px" }}>
              This is information that you provide to us through text fields,
              such as your name, payment information and benefits. The
              information we collect is the same if you make an account, become
              a Dreamer, or become a Dream Angel
            </ul>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              First Name
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              Last Name
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              Birth date
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              Email Address
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              Phone number
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              Password
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              State and Country of Residence
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              City
            </li>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold  mb-2">Dreamer</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              A Dreamer is a person who has its own dream mentioned and
              described clearly on the platform, that has already subscribed
              with one of the three packages and started to fulfill other dreams
              with sharing only one star (dollar) for the dream. All the dreams
              that you will fulfill will be collected on your profile and you
              will have the option to double your own dream amount. The more you
              give, the more you receive. As a Dreamer you must provide your
              payment information to our payment processors. You can see the
              privacy policy for these payment processors on the Stripe site.
              Payment processors provide us with a token that represents your
              account, your card’s expiration date, card type and the last four
              digits of your card number. If you are required to provide your
              name and email address to the payment processor, then they also
              provide us with that information. We collect and process
              information about the users that fulfilled your dream, the dreams
              you have fulfilled and wings donations that you support. We may
              also require your bank account information to process payouts.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold  mb-2">Dream Angel</h3>
            <p className=" text-dark" style={{ fontSize: "16px" }}>
              A Dream Angel is a person who doesnt have it's own dream. To
              become a Dream Angel you just have to subscribe with one of the
              three packages and start to fulfill other dreams with sharing only
              one star (dollar) for the dream. All the dreams that you fulfilled
              will be collected on your profile. As a Dream Angel you must
              provide your payment information to our payment processors. You
              can see the privacy policy for these payment processors on the
              Stripe site. We collect and process information about the users
              that dreams you have fulfilled and wings donations that you
              support.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Wings Donations</h3>
            <p className=" text-dark" style={{ fontSize: "16px" }}>
              Wings Donations are campaignes organized by Dreamerz with the aim
              of cases from all over the world. Wings Donations are not
              collecteble for Dreamerz and Dream Angels, so the donations will
              be maid without anny expectaitions. Our payment processors collect
              your credit card details and other information necessary to
              process the donation such as your home address. You can see the
              privacy policy for these payment processors on the Stripe site.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Additional Information We Collect
            </h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              Automatically Collected Information We collect information
              automatically as you navigate the site or through our third party
              analytics providers. We may store usage information such as the
              type of device you use to access Dreamerz, your operating system,
              browser type, IP address, and device ID, the pages you visit or
              request, links clicked, referring sites, user interactions and
              your search terms. We also derive your location from your
              self-disclosed country, your IP address, and from your payment
              card. Comments We keep track of how many times users come, what
              they do on the site, where they respond, who they connect with,
              their IP address, and where they checked in.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Information Collected by Third Parties
            </h3>
            <p className="text-dark fw-bold mb-0" style={{ fontSize: "16px" }}>
              Cookies
            </p>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              We use Cookies on our website that collect information to allow us
              and third parties to understand your use of Dreamerz. Cookies are
              not strictly necessary for the website to work, but it will
              provide you with a better browsing experience. If you choose to
              delete or block these cookies, some features of this site may not
              work as intended. Cokies are small pieces of information that are
              issued to your computer or mobile device when you visit a website,
              or access or use a mobile application. That information regarding
              your use of the Platform is stored and sometimes tracked. We use
              cookies or other electronic technologies ( such as pixel tags )
              for various purposes. Unlike Cookies, pixel tags are embedded in
              web pages. Pixel tags are tiny graphic images with unique
              identifiers that track online movements of our users.
            </p>
            <p className=" text-dark fw-bold mb-0" style={{ fontSize: "16px" }}>
              Analytics
            </p>
            <p className=" text-dark" style={{ fontSize: "16px" }}>
              We use third-party web analytics services on our Services, such as
              those of Google Analytics, to help us analyze how users use the
              Services, including by noting the third-party website from which
              you arrive, and providing certain features to you including Google
              Signal features such as user id tracking, dynamic remarketing,
              interest-based advertising, audience targeting, behavioral
              reporting, demographics and interests reporting, user segment
              analysis, device reporting, display advertising, and video ads
              reporting. The information acquired by the technology (including
              an anonymized IP address) will be shared to or collected directly
              by these service providers, who will use it to assess your use of
              the Services. This information can be used to target adverts on
              the Platform depending on your interests. You may see adverts for
              our Platform on other websites as a result of this. Google’s
              ability to use and share information collected by Google Analytics
              about your visits to the Site is restricted by the Google
              Analytics terms of use and the Google policy.
            </p>
            <p className=" text-dark fw-bold mb-0" style={{ fontSize: "16px" }}>
              Social Media Features and Widgets
            </p>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              We use social networking elements like the Instagram and Facebook
              Like buttons, as well as widgets like the Share button and other
              interactive Dreamerz mini-programs. We may use your social media
              information to interact with you on these social networks if you
              offer it to us. These features may collect your IP address and
              other personal information, such as the page of our site you are
              visiting, and may set a cookie to enable the features to function
              efficiently. Social media features and widgets are hosted by a
              third party or by Dreamerz directly. The privacy policies of the
              companies that provide these features govern your interactions
              with them. Please see this page for further information on the
              technologies that these social networks employ.
            </p>
            <p className=" text-dark fw-bold mb-0" style={{ fontSize: "16px" }}>
              Why We Use Cookies
            </p>
            <ul className="text-dark m-0 p-0" style={{ fontSize: "16px" }}>
              We use Cookies to collect information about your access to and use
              of the Platform, including to:
            </ul>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              allow you to navigate and use all the features provided by our
              Platform
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              customize elements of the layout and/or content within the
              Platform and remember that you have visited us before
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              identify the number of unique visitors we receive
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              improve the Platform and learn which functions of the Platform are
              most popular with users;
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              understand how you use the Platform (e.g., by learning how long
              you spend on the Platform and where you have come to the Platform
              from).
            </li>
            <p className="text-dark mb-0" style={{ fontSize: "16px" }}>
              As we adopt additional technologies, we may gather additional
              information through other methods. We will notify you of such
              changes with updates to this Policy
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              How We Use Your Information
            </h3>
            <ul className="text-dark m-0 p-0" style={{ fontSize: "16px" }}>
              We process your information to:
            </ul>
            <li className=" text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              verify your identity to comply with US federal, state, and
              international laws
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              allow you to sign in to your account
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              allow you to fulfill peoples dreams
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              allow you to donate for the Wings Donations campaignes
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              process all the dreams and wings donations payments and payouts
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              send you emails relevant to your usage, as controlled by your
              email preferences
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              reply to your questions
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              promote your Dreamerz account for greater discoverability
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              understand how you use the service, and how to manage your dream
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              prevent fraud and abuse on Dreamerz
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              provide you with reasonable accommodation, if you notify us of a
              disability
            </li>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Information We Share with a Dreamer or a Dream Angel
            </h3>
            <ul className="text-dark m-0 p-0" style={{ fontSize: "16px" }}>
              By becoming a dreamer or a dream angel, you agree to have the
              following information shared.
            </ul>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              your name and email address, and other profile information you’ve
              provided
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              any comments you send to dreamers or wings donations throught
              Dreamerz
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              your city, and country
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              your phone number if it would be mentioned
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              all details about your dream or wings donations, such as the
              amount and start date, but not your credit card information
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              some aggregated and anonymized data about how you use Dreamerz
              that cannot be linked back to you or to any individual user
            </li>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Information We Share with Third Parties
            </h3>
            <ul className="text-dark p-0 m-0" style={{ fontSize: "16px" }}>
              We never sell your information to third parties. We will only
              share data with third parties, under the following circumstances:
            </ul>
            <li className=" text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              With our service providers, which are companies that are
              contractually obligated to provide us with services like order
              fulfillment, email management, data analysis, credit card
              processing, multi-currency settlement solutions, increasing our
              brand awareness and user engagement with marketing initiatives,
              and fraud detection and prevention. These companies may need
              access to your data to provide their services, and they are
              required by contract to protect any of your data they receive from
              Dreamerz to the same level that Dreamerz does.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              to protect the security or integrity of Dreamerz, and to protect
              the rights, property, or safety of Dreamerz, its employees, users,
              or others, if we believe that disclosure is reasonably necessary
              to comply with a law, regulation, or other valid legal process
              (e.g., subpoenas or warrants served on Dreamerz). If we are going
              to release your data, we will do our best to provide you promptly
              with notice by email, unless we are prohibited by law from doing
              so.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              in connection with the sale, merger, bankruptcy, sale of assets or
              reorganization of our company. We will notify you if a different
              company receives your data. The promises in this privacy policy
              apply to any data transferred to a new entity.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              with third party apps used by creators to help run their
              membership programs.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              with partners who may have access to your data to perform their
              services, and who are obligated by contract to safeguard any of
              your data they receive from us to the same extent that Dreamerz
              protects it.
            </li>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Information Dreamerz Collects from Third Party{" "}
            </h3>
            <ul className=" text-dark m-0 p-0" style={{ fontSize: "16px" }}>
              When you create a Dreamerz account, you can elect to connect your
              social account(s) (e.g. Facebook, Instagram, Google ) with
              Dreamerz, and we will collect and store some social information
              from those platforms, such as:
            </ul>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              follower or subscriber counts
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              post or upload counts
            </li>
            <li className="text-dark ms-2" style={{ fontSize: "16px" }}>
              view, like, and comment counts
            </li>
            <ul className="text-dark m-0 mt-3 p-0" style={{ fontSize: "16px" }}>
              This social information allows us to provide you a better Dreamerz
              experience, and also helps guide future development of Dreamerz.
              We use this data to:
            </ul>
            <li className=" text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              help dreamers and dream angel find each other on Dreamerz
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              assess how to make dreamers more successful on Dreamerz
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              help wings donations become more famous and accessible
            </li>
            <li className=" text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              analyze and describe our business
            </li>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Information We Share with the Public
            </h3>
            <ul className="text-dark p-0 m-0" style={{ fontSize: "16px" }}>
              The following information is publicly accessible:
            </ul>
            <li className=" text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              your profile, and your social media links and location if you add
              that information
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              by default the Dreamer or Wing Donation you support are publicly
              displayed
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              any dream, or comments you make
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              the amount for the dream that you expect
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              number of fulfilled dreams
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              number of recieving stars for your dream
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              the number of the Wing Donations that you support
            </li>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Your Preferences and Rights over Data
            </h3>
            <p className="text-dark fw-bold m-0" style={{ fontSize: "16px" }}>
              Choosing Your Preferences
            </p>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              The Settings link is located by clicking on your avatar or profile
              at the top right hand of your screen, after your log into your
              Dreamerz account. Settings lets you see your account preferences.
              You can see and adjust your settings by viewing your preferences
              and, if you wish, by changing your selections.
            </p>
            <p className="text-dark fw-bold m-0" style={{ fontSize: "16px" }}>
              Marketing Activities
            </p>
            <ul className="text-dark m-0 p-0" style={{ fontSize: "16px" }}>
              By agreeing to our terms of use, you expressly agree that Dreamerz
              may:
            </ul>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              provide you with information about your service, service
              enhancements, or new Dreamerz products, while you are on our web
              site.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              send you messages regarding your existing service, or enhancements
              related to your existing service, when you are off our platform,
              via email, or via text, if you have signed up for a service or
              event notification that uses text messages.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              market Dreamerz to you and audiences similar to you based on your
              networks and common factors that others have with you, unless you
              opt out.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              send you marketing emails or texts if you don’t have a Dreamerz
              account but have consented to receiving such messages, from which
              you may opt out.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              promote your account for greater discoverability.
            </li>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Exercising Your Data Rights
            </h3>
            <ul className="text-dark p-0 m-0" style={{ fontSize: "16px" }}>
              Users in certain locations may have certain rights under the
              General Data Protection Regulation (GDPR) regarding data that
              Dreamerz controls as a Data Controller as defined under the GDPR,
              including:
            </ul>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              the right of access to their personal data,
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              the right to correct or rectify any inaccurate personal data,
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              the right to restrict or oppose the processing of personal data,
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              the right to erase or delete their personal data,
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              the right to personal data portability, and
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              the right to opt-out of the sale of personal information.
            </li>
            <ul className="text-dark p-0 m-0 mt-3" style={{ fontSize: "16px" }}>
              You can exercise rights over your data on Dreamerz in the
              following ways:
            </ul>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              accessing, reviewing, modifying, and updating your data by logging
              into your account at Dreamerz and going to your account settings
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              viewing our privacy policy or viewing our data practices by going
              online to our Dreamerz Privacy Center, where the Data Practices
              tab describes how we use your data, and the Policies tab shows you
              our privacy policy.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              deleting a previously-disabled account by emailing Dreamerz at
              privacy@dreamerz.net.
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              going to FAQ online for data-related issues, or contact us by
              email.
            </li>
            <p className="text-dark mt-3" style={{ fontSize: "16px" }}>
              If you are unable to log into your account, and are unable to
              recover your account with a password reset in order to lodge your
              privacy request, then you may reach out to privacy@dreamerz.net.
              We reserve the right to decline you access to or recovery of your
              account, at our discretion, to prevent an unauthorized takeover of
              your account.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Our Data Retention Period
            </h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              We retain your account information for ten years after your
              account is last active, unless you delete, or you request us to
              delete, your account. We may continue to retain some information,
              even after you delete your account if we are required to do so in
              order to comply with various laws.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Security</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              While no organization can guarantee perfect security, Dreamerz has
              implemented and seeks to continuously improve technical and
              organizational security measures to protect the information
              provided via the Services from loss, misuse, unauthorized access,
              disclosure, alteration, or destruction. For example, limiting
              access to information only to employees and authorized service
              providers who need to know such information for the purposes
              described in this Policy.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Cross Border Data Transfers
            </h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              Dreamerz is a global organization headquartered in the United
              States, which processes your information in servers in a number of
              countries, including the United States. Dreamerz has implemented
              other appropriate cross-border transfer solutions, such as
              Standard Contractual Clauses approved by appropriate regulatory
              authorities, to provide adequate protection for transfers of
              certain personal data from the European Economic Area, United
              Kingdom and Switzerland to the United States.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Users from Outside the United States
            </h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              The Platform is hosted in the United States. If you are visiting
              the Platform from outside the United States, please be aware that
              your information may be transferred to, stored and processed in
              the United States where our servers are located and our central
              database is operated. The data protection and other laws of the
              United States and other countries might not be as comprehensive as
              those in your country. By using the Platform, you consent to your
              information being transferred to our facilities and to the
              facilities of those third parties with whom we share it as
              described in this Policy.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Children</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              Dreamerz is not directed at children under the age of 13 and 16
              years in the EU, you may use Dreamerz, only with the approval of
              your parent or guardian, otherwise children may not create an
              account or use Dreamerz.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Changes</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              We may sometimes make changes to this policy. If we make material
              changes that adversely affect your rights under this policy, we
              will let you know by posting an announcement on the site or
              sending you an email in advance of the changes coming into effect.
              Continuing to use Dreamerz after a change to this policy means you
              accept the new policy. If you have any questions, please email{" "}
              <a href="src/pages/Public/pages/About/PrivacyPolicy#">
                <u>privacy@dreamerz.net</u>
              </a>
              .
            </p>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              Effective immediately for users joining Dreamerz after July 20,
              2022
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
