import React, { useState } from "react";
import moment from "moment";
import ShareButton from "../Buttons/ShareButton";
import GradientButton from "../Buttons/GradientButton";
import { Carousel } from "react-bootstrap";
import { ProgressBar } from "react-bootstrap";
import coverImg from "../../assets/images/coverImg.png";
import DreamMakersModal from "../DreamMakersModal";
import ShareModal from "./ShareModal";
import { shareTo } from "src/utils/share";
import { useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import { useNavigate } from "react-router-dom";

export default function CharityCard({
  wingDonationsProfile,
  charity,
  isFulfilled,
  progressClick,
  carouselItem,
  wingDonations,
  setShow,
  show,
}) {
  const progress = calculateDreamProgress(charity);
  const navigate = useNavigate();

  const user = useSelector((state: UsersModule) => state.usersModule).user;

  const [link, setLink] = useState(process.env.REACT_APP_BASE_URL);
  const [shareModal, setShareModal] = useState(false);

  const share = () => {
    setLink(`${link}/wing/${charity?.id}`);
    shareTo({
      link: `${link}/wing/${charity?.id}`,
      setShareModal,
    });
  };

  return (
    <>
      <ShareModal
        customModals={shareModal}
        setCustomModal={setShareModal}
        sharedLink={link}
        post={charity}
        mainUser={user}
      />
      <div className="gradient-bg-charity w-100">
        <span className="text-dark">🕊 Wings Donation</span>
      </div>
      <div className="posts-buttons-container">
        <div className="d-flex align-items-center gap-2 cursor-pointer">
          <div className="d-flex flex-column ">
            <span
              className="user-name"
              onClick={() => navigate(`/wing-donation/${charity?.id}`)}
            >
              {charity?.title || ""}
            </span>
            <span className="time-ago">
              {moment(charity?.createdAt)?.fromNow(true)} ago
            </span>
          </div>
        </div>
        <div className="buttons-container">
          <ShareButton width="72px" onClick={() => share()} />
          <GradientButton
            minWidth="137px"
            text={
              wingDonationsProfile?.find(
                (d) =>
                  d === charity?.id ||
                  charity?.amount === charity?.amountReceived
              )
                ? "View"
                : "Donate"
            }
            onClick={() => {
              wingDonationsProfile?.find(
                (d) =>
                  d === charity?.id ||
                  charity?.amount === charity?.amountReceived
              )
                ? navigate(`/wing-donation/${charity?.id}`)
                : isFulfilled(charity?.id);
            }}
          />
        </div>
      </div>
      <Carousel
        indicators={charity?.images?.length > 1}
        interval={null}
        controls={charity?.images?.length > 1 && window.innerWidth > 500}
      >
        {charity?.images?.length
          ? charity?.images?.map((img) => carouselItem(img?.url))
          : carouselItem(coverImg)}
      </Carousel>
      <span className="my-span lh-sm text-dark">
        {charity?.description || "No description"}
      </span>
      <div>
        <div className="d-flex align-items-center justify-content-between gap-2 mb-1">
          <ProgressBar
            onClick={() => progressClick()}
            now={progress}
            variant={"gradient"}
            className="black-hover post-progress-bar"
          />
          <span className="fw-bold text-dark" style={{ fontSize: "12px" }}>
            {isNaN(progress) ? 0 : progress}%
          </span>
        </div>
        <span className="text-dark">
          Received <b>{charity?.amountReceived || 0}</b>
        </span>
      </div>
      {wingDonations?.results?.length > 0 && (
        <DreamMakersModal
          donations={wingDonations}
          show={show}
          setShow={setShow}
          dreamId={charity?.id}
          wingCard={true}
        />
      )}
    </>
  );
}
