import React, { useEffect, useRef, useState } from "react";
import {
  createCharitiesComments,
  createComments,
  getCharityComments,
  getComments,
} from "../../services/api";
import Comment from "./Comment";
import InputComment from "./InputComment";
import { FeedCommentsProps } from "src/types/comments-props";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCharityCommentsCount,
  updateDreamCommentsCount,
} from "src/store/action";
import { CharityCommentData, DreamCommentData } from "../../types/redux";

const FeedComments = ({
  postId,
  user,
  mainImg,
  dream,
  dreamId,
  openCommentModal,
  type,
  isFulfilled,
  commentInput,
  setCommentInput,
}: FeedCommentsProps) => {
  const [commentData, setCommentData] = useState([]);
  const scrollref = useRef(null);
  const dispatch = useDispatch();
  const charityComments = useSelector(
    (state: CharityCommentData) => state.comments.charityComments[dreamId] || {}
  );
  const dreamComments = useSelector(
    (state: DreamCommentData) => state.comments.dreamComments[dreamId] || {}
  );
  const [emojiPicker, showEmojiPicker] = useState(false);

  useEffect(() => {
    type === "charity"
      ? getCharityComments(dreamId, 1, 3).then((res) => {
          setCommentData(res.data.results);
          dispatch(
            updateCharityCommentsCount(dreamId, res?.data?.meta?.itemCount)
          );
        })
      : getComments(dreamId, 1, 3).then((res) => {
          setCommentData(res?.data?.results);
          dispatch(
            updateDreamCommentsCount(dreamId, res?.data?.meta?.itemCount)
          );
        });
  }, [dreamId]);

  useEffect(() => {
    return () => {
      setCommentData([]);
    };
  }, [dreamId]);

  const addComment = () => {
    showEmojiPicker(false);
    if (commentInput?.length) {
      type === "charity"
        ? createCharitiesComments({
            message: commentInput,
            charityId: dreamId,
          }).then(() => {
            getCharityComments(dreamId, 1, 3).then((res) => {
              setCommentData(res?.data?.results);
              dispatch(
                updateCharityCommentsCount(dreamId, res?.data?.meta?.itemCount)
              );
            });
          })
        : createComments({ message: commentInput, dreamId: dreamId }).then(
            () => {
              getComments(dreamId, 1, 3).then((res) => {
                setCommentData(res?.data?.results);
                dispatch(
                  updateDreamCommentsCount(dreamId, res?.data?.meta?.itemCount)
                );
              });
            }
          );
      setCommentInput("");
      let scrollDiv = document.getElementById("commentScroll");
      scrollDiv.scrollTop = 0;
    }
  };

  const handleComment = (event) => {
    setCommentInput(event.target.value);
    if (!commentInput.length && event.key === "Enter") {
      event.preventDefault();
    } else if (event.key === "Enter" && commentInput.length) {
      event.preventDefault();
      addComment();
    }
  };

  const commentsList = commentData
    ?.filter((el) => el.parentId === null)
    .map((elem, index) => {
      if (index === 0) {
        return (
          <div
            key={`comment-${index}-${elem?.id}`}
            onClick={() => openCommentModal(dream?.id)}
          >
            <Comment
              autoFocus={false}
              user={user}
              dreamId={dream?.id}
              elem={elem}
              isFulfilled={isFulfilled}
              type={type}
              textFormatted
            />
          </div>
        );
      }
      return <div key={index}></div>;
    });

  return (
    <>
      <div>
        {!!commentsList?.length && (
          <div className="comment-line">
            <ul className="post-comments p-0 m-0 container-fluid mb-1">
              <ul
                style={{ height: "80px" }}
                className="ps-0 overflow-hidden"
                id="dream-comment-scroll"
                ref={scrollref}
              >
                {commentsList}
              </ul>
            </ul>
            {commentData?.length > 1 && (
              <div className="mt-2">
                <span
                  className="span-hover-underline text-dark cursor-pointer"
                  onClick={() => openCommentModal(dream?.id)}
                >
                  Read all{" "}
                  {type === "charity"
                    ? charityComments.count
                    : dreamComments.count}{" "}
                  comments
                </span>
              </div>
            )}
          </div>
        )}
        <InputComment
          postId={postId}
          currentUserComment={commentInput}
          setComment={setCommentInput}
          emojiPicker={emojiPicker}
          showEmojiPicker={showEmojiPicker}
          mainImage={mainImg}
          handleComment={(e) => handleComment(e)}
          addComment={() => addComment()}
          style={{
            position: "unset",
            border: "none",
            marginTop: 0,
          }}
        />
      </div>
    </>
  );
};

export default FeedComments;
