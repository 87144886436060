import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../assets/images/defaultUser.png";
import TooltipDetails from "../../Tooltip/TooltipDetails";
import { useSelector } from "react-redux";
import { UsersModule } from "../../../types/redux";
import { generateTooltipKey } from "../../../utils/gerenateTooltipKey";
import { ActivityTypeProps } from "src/types/profile-props";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const typeTextMap = {
  dream_liked: "liked this dream",
  angel_card_liked: "liked this post",
  charity_liked: "liked this wing donation ",
  dream_comment: "commented on this post",
  angel_comment: "commented on this post",
  reply_dream_comment: "replied to ",
  reply_charity_comment: "replied to ",
  reply_angel_comment: "replied to ",
  charity_comment: "commented on this post",
  donation: "fulfilled this dream",
  wing_donation: "donated for",
};

const ActivityType = ({
  contributor,
  type,
  isFulfilled,
  timestamp,
  reply,
}: ActivityTypeProps) => {
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const navigate = useNavigate();
  const contributorTooltipKey = generateTooltipKey(contributor?.id);
  const replyTooltipKey = reply ? generateTooltipKey(reply.user?.id) : "";
  const activityText = typeTextMap[type] || "";
  const hasDream = useRoleCheck(contributor);

  return (
    <div className="d-flex justify-content-between align-items-center fulfilled-by-container">
      <div className="d-flex align-items-center gap-2 cursor-pointer">
        <div className={`${!hasDream ? "gradient-border" : ""}`}>
          <img
            src={contributor?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="avatar-35 rounded-circle cursor-pointer"
            style={{ objectFit: "cover" }}
          />
        </div>
        <div
          className="d-flex activity-type-content-name"
          style={{ gap: "4px", alignItems: "baseline" }}
        >
          <div className="fulfilled-by-name-badge-box">
            <span
              className="user-name"
              data-tooltip-id={contributorTooltipKey}
              onClick={() => {
                navigate(`/dashboard/public-page/${contributor.id}`);
              }}
            >
              {`${contributor?.firstName || "No name"} 
                        ${contributor?.lastName || ""}`}
            </span>
            {!hasDream && (
              <div className="tooltip-angel-badge" style={{ marginTop: 0 }}>
                Dream Angel
              </div>
            )}
          </div>
          <div style={{ display: "flex", gap: "4px" }}>
            <span className="time-ago" data-tooltip-id={replyTooltipKey}>
              {activityText}
            </span>
            <span
              className="text-underline activity-commented-span"
              onClick={() => {
                navigate(`/dashboard/public-page/${reply?.user?.id}`);
              }}
            >
              {reply ? `${reply.user.firstName} ${reply.user.lastName}` : null}
            </span>
          </div>
        </div>
      </div>
      <TooltipDetails
        userId={contributor?.id}
        isFulfilled={isFulfilled}
        allFulfilled={allFulfilled}
        tooltipKey={contributorTooltipKey}
      />
      {reply && reply.user && (
        <TooltipDetails
          userId={reply.user.id}
          tooltipKey={replyTooltipKey}
          isFulfilled={isFulfilled}
          allFulfilled={allFulfilled}
        />
      )}
      <span className="time-ago">{moment(timestamp)?.fromNow(true)} ago</span>
    </div>
  );
};
export default ActivityType;
