import React from "react";
import PublicNavBar from "../../../components/NavBar/PublicNavBar";

const Layout = ({children}) => {
  return (
    <div className="d-flex flex-column justify-content-center mt-2">
      <PublicNavBar/>
      {children}
    </div>
  );
};
export default Layout;
