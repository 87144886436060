import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const useCheckIsAuth = (url,id) => {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate()

  useEffect( () => {
    if (token && id) navigate(url)
  },[id])

  return token
}