import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ActivityDreamCard } from "./index";
import { useFetchActivities } from "src/hooks/useActivity";
import loaderGif from "../../../assets/images/page-img/page-load-loader.gif";
import Charity from "src/pages/NewsFeed/Posts/Charity";
import DreamAngel from "src/pages/NewsFeed/Posts/DreamAngel";
import { ActivityProps } from "src/types/profile-props";

const AllActivity = ({ user, isFulfilled }: ActivityProps) => {
  const { activities, fetchNextPage, hasNextPage } = useFetchActivities(
    user,
    "ALL"
  );

  return (
    <div style={{ overflow: "auto" }}>
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        useWindow={true}
        loader={
          <div className="activity-content">
            <div className="d-flex justify-content-center w-100" key="loader">
              <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
            </div>
          </div>
        }
      >
        <div
          style={{ padding: "20px", gap: "20px" }}
          className="d-flex flex-column"
        >
          {activities.length > 0 &&
            activities.map((activity, index: number) => {
              if (activity.type === "dream_liked") {
                return (
                  <ActivityDreamCard
                    key={index}
                    post={activity.data}
                    user={user}
                    type={activity.type}
                    timestamp={activity.timestamp}
                    isFulfilled={isFulfilled}
                  />
                );
              } else if (activity.type === "angel_card_liked") {
                return (
                  <DreamAngel
                    key={index}
                    post={activity.data}
                    user={user}
                    type={activity.type}
                    timestamp={activity.timestamp}
                    isFulfilled={isFulfilled}
                  />
                );
              } else if (activity.type === "charity_liked") {
                return (
                  <Charity
                    key={index}
                    post={activity}
                    user={user}
                    type={activity.type}
                    timestamp={activity.timestamp}
                    isFulfilled={isFulfilled}
                  />
                );
              } else if (
                activity.type === "dream_comment" ||
                activity.type === "reply_dream_comment"
              ) {
                return (
                  <ActivityDreamCard
                    key={index}
                    post={activity.data}
                    user={user}
                    type={activity.type}
                    timestamp={activity.timestamp}
                    isFulfilled={isFulfilled}
                    reply={activity.reply}
                  />
                );
              } else if (
                activity.type === "charity_comment" ||
                activity.type === "reply_charity_comment"
              ) {
                return (
                  <Charity
                    key={index}
                    post={activity}
                    user={user}
                    type={activity.type}
                    timestamp={activity.timestamp}
                    isFulfilled={isFulfilled}
                    reply={activity.reply}
                  />
                );
              } else if (
                activity.type === "angel_comment" ||
                activity.type === "reply_angel_comment"
              ) {
                return (
                  <DreamAngel
                    key={index}
                    post={activity.data}
                    user={user}
                    type={activity.type}
                    timestamp={activity.timestamp}
                    isFulfilled={isFulfilled}
                  />
                );
              } else if (activity.type === "donation") {
                return (
                  <ActivityDreamCard
                    key={index}
                    post={activity.data}
                    user={user}
                    type={activity.type}
                    timestamp={activity.timestamp}
                    isFulfilled={isFulfilled}
                  />
                );
              } else if (activity.type === "wing_donation") {
                return (
                  <Charity
                    key={index}
                    post={activity}
                    user={user}
                    type={activity.type}
                    timestamp={activity.timestamp}
                    isFulfilled={isFulfilled}
                  />
                );
              }
              return <></>;
            })}
        </div>
      </InfiniteScroll>
    </div>
  );
};
export default AllActivity;
