import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./users";
import commentsReducer from "./comments";
import thunk from "redux-thunk";

const store = configureStore({
  reducer: {
    usersModule: userReducer,
    comments: commentsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
