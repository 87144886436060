import { Modal } from "react-bootstrap";
import React from "react";
import { warning } from "../../consts/SvgIcon";

const SubmitModal = ({ submitModal, setModal }) => {
  return (
    <Modal
      backdrop={true}
      centered
      show={submitModal}
      onHide={() => setModal(false)}
      animation={true}
    >
      <div className="d-flex flex-column p-3 gap-4 align-items-center justify-content-center">
        <div className="d-flex flex-column align-items-center gap-2 w-100">
          <div className="warning-icon">{warning()}</div>
          <span className="text-dark fw-bold">
            Are you sure you want to submit?
          </span>
          <span className="text-dark text-center">
            The amount necessary for your dream that you entered cannot be
            changed in the future.
          </span>
        </div>
        <div className="d-flex justify-content-between w-100">
          <div
            className="dashboard-card-buttons custom-gradient"
            onClick={() => setModal(false)}
            style={{ height: "40px" }}
          >
            <div className="dashboard-card-buttons-text">
              <span>Cancel</span>
            </div>
          </div>
          <button
            type="submit"
            form="createDream"
            className="custom-btn text-black m-0"
            style={{ height: "40px" }}
          >
            <div className="dashboard-card-buttons-text fw-bold">Submit</div>
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default SubmitModal;
