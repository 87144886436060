import { Spinner } from "react-bootstrap";
import React from "react";

const Loader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        zIndex: 100000,
        backgroundColor: "black",
        opacity: "0.5",
      }}
    >
      <Spinner animation="border" role="status" variant="light">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
export default Loader;
