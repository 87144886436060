import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UsersModule } from "src/types/redux";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import { handleFulfillment } from "src/utils/isFulfilled";
import TooltipDetails from "../Tooltip/TooltipDetails";
import defaultImage from "../../assets/images/defaultUser.png";
import coverImage from "../../assets/images/coverImg.png";

export default function AngelsFulfilledCard({ angel }) {
  const profileId = localStorage.getItem("profileId");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;

  const [dreamId, setDreamId] = useState("");
  const [customModals, setCustomModal] = useState("");

  const tooltipKey = generateTooltipKey(angel?.id);
  const user = useSelector((state: UsersModule) => state.usersModule).user;

  const isFulfilled = (id) => {
    handleFulfillment(id, user, setDreamId, setCustomModal, dispatch);
  };

  return (
    <div className="public-dream-card shadow-card rounded-3 d-flex flex-column">
      <div
        className="profile-bg-card d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: `url(${angel?.coverImage?.url || coverImage})`,
        }}
      >
        <div className="angel-img-border-gradients">
          <div className="d-flex justify-content-center align-items-center bg-white rounded-circle">
            <img
              src={angel?.mainImageUrl || defaultImage}
              alt="profile-img"
              className="public-dream-user-img-card rounded-circle"
            />
          </div>
        </div>
      </div>
      <div className="public-dream-angel-card-box text-center d-flex flex-column">
        <div className="public-dream-card-name-angel-box">
          <span
            className="my-title public-dream-card-user-name-angel"
            data-tooltip-id={tooltipKey}
            onClick={() =>
              navigate(
                profileId ? `/dashboard/public-page/${angel?.id}` : `/login`
              )
            }
          >
            {`${angel?.firstName || "No Name"} 
                        ${angel?.lastName || ""}`}
          </span>
          <span className="my-span lh-sm angel-tag-card">Dream Angel</span>
        </div>
        <div className="public-dream-card-below-box">
          <div className="public-dream-card-angel-fulfill d-flex justify-content-between">
            <span> Fulfilled </span>
            <span className="fw-bold">
              {angel?.donations?.length || 0} Dreams
            </span>
          </div>
          <div
            onClick={() =>
              navigate(
                profileId ? `/dashboard/public-page/${angel?.id}` : `/login`
              )
            }
            className="dashboard-card-buttons custom-gradient"
            style={{ width: "100%", height: "40px" }}
          >
            <div className="dashboard-card-buttons-text">
              <span>View Profile</span>
            </div>
          </div>
        </div>
      </div>

      {profileId ? (
        <TooltipDetails
          userId={angel?.id}
          isFulfilled={isFulfilled}
          allFulfilled={allFulfilled}
          tooltipKey={tooltipKey}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
