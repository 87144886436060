import React from "react";
import bgSvg from "../../assets/images/bg-landing-star.svg";
import "../../styles/carousel/carousel-styles.scss";

const firstCarouselData = [
  "Community 1$ Power",
  "Self-Promotion Through Charity",
  "Straight Dream Backing",
  "Global 10x Impact",
  "Mutual Supportive Community",
  "Fulfilled Dreams Collection",
  "Charity with Recognition",
  "Joy of Helping",
  "Unified Growth",
  "Affordable Dream Achievement",
  "Impact Multiplier ",
  "Continuous Giving Cycle ",
  "Global Contribution Boost",
  "Mutual Support Network",
];

const secondCarouselData = [
  "Half-Price Dream Realization",
  "Mutual Supportive Community",
  "Self-Promotion Through Charity",
  "Sustainable Giving Cycle",
  "Fulfilled Dreams Collection",
  "Charity with Recognition",
  "Joy of Helping",
  "Unified Growth",
  "Affordable Dream Achievement",
  "Impact Multiplier ",
  "Continuous Giving Cycle ",
  "Global Contribution Boost",
  "Mutual Support Network",
  "Community 1$ Power",
];

const CarouselPurposeSection = () => {
  return (
    <div className="main-container">
      <img src={bgSvg} alt="bgsvg" className="landing-page-svg" />
      <div className="info-container">
        <h3>Help Others — Help Yourself</h3>
        <span>
          Inspire others by making the first step towards your dreams. 
        </span>
        <span>
          Help others and see the whole world move forward, one dream at a time.
        </span>
      </div>
      <div className="carousel-container-section">
        <div className="first-carousel">
          <div className="slider">
            <div className="slide-track-to-right">
              {firstCarouselData.map((card, index) => {
                return (
                  <div className="slide" key={index}>
                    <span className="carousel-card-text" key={index}>
                      {card}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="first-carousel">
          <div className="slider">
            <div className="slide-track-to-left">
              {secondCarouselData.map((card, index) => {
                return (
                  <div className="slide" key={index}>
                    <span className="carousel-card-text" key={index}>
                      {card}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselPurposeSection;
