import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Carousel,
  CarouselItem,
  Col,
  Image,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Card from "../../components/Partials/Card";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import CashOut from "../../components/Partials/CashOut";
import {
  fulfill,
  getAllCharities,
  getAllDreams,
  getAllProfileDonations,
  getAllProfileWingDonations,
  getDreamById,
  getDreamDonations,
  getPayOutsByDreamId,
  getProfileDream,
  getUser,
  lastTenDonations,
  randomDonation,
  wingDonation,
} from "../../services/api";
import {
  setAddDreams,
  setAllDreamPayments,
  setAllDreams,
  setAllProfileFulfilled,
  setAllProfileWing,
  setBgLoader,
  setCurrentPage,
  setDream,
  setOrder,
  setProfileReceived,
  setSpliceDreams,
  setUser,
} from "../../store/users";
import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/defaultUser.png";
import coverImg from "../../assets/images/coverImg.png";
import DreamMakersModal from "../../components/DreamMakersModal";
import PricingModal from "../Pricing/PricingModal";
import loaderGif from "../../assets/images/page-img/page-load-loader.gif";
import DashboardCard from "./DashboardCard";
import GradientButton from "../../components/Buttons/GradientButton";
import { UsersModule } from "src/types/redux";
import { DreamDto } from "src/services/api/data-contracts";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "src/components/Tooltip/TooltipDetails";

let page2d = 1;
let loader = false;

const Dashboard = () => {
  const notify = (message) => toast.success(message);
  const notifyWarning = (message) => toast.warning(message);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const currentPage = useSelector(
    (state: UsersModule) => state.usersModule
  ).currentPage;
  const order = useSelector((state: UsersModule) => state.usersModule).order;
  const dream = useSelector((state: UsersModule) => state.usersModule)
    .dream as DreamDto;
  const profileReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).profileReceived;
  const allDreams = useSelector(
    (state: UsersModule) => state.usersModule
  ).dreams;
  const mainImage = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  const [wingDonations, setWingDonations] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [randomDonationId, setRandomDonationId] = useState<DreamDto | null>(
    null
  );
  const [customModals, setCustomModal] = useState("");
  const [lastDonations, setLastDonations] = useState([]);
  const [show, setShow] = useState(false);
  const [dreamId, setDreamId] = useState("");
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const wingDonationsProfile = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileWing;
  const allPayments = useSelector(
    (state: UsersModule) => state.usersModule
  ).allDreamPayments;
  const take = 16;
  const [hasNextPage, setHasNextPage] = useState(true);
  const [cashOut, openCashOut] = useState(false);
  const lastDream = localStorage.getItem("dreamId");
  const [activeTab, setActiveTab] = useState("Dreams");

  const loadMore = () => {
    if (!!allDreams.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        getAllDreams(order, page2d, take).then((response) => {
          dispatch(setAddDreams(response?.data?.results));
          setHasNextPage(response?.data?.meta?.hasNextPage);
          loader = false;
          dispatch(setCurrentPage(currentPage + 1));
        });
      }
    }
  };

  useEffect(() => {
    loader = true;
    dispatch(setAllDreams([]));
    getAllDreams(order, 1, take).then((response) => {
      loader = false;
      page2d = 1;
      dispatch(setAllDreams(response?.data?.results));
      setHasNextPage(response?.data?.meta?.hasNextPage);
      dispatch(setCurrentPage(1));
    });
  }, [order]);

  useEffect(() => {
    if (!!dream?.id) {
      getPayOutsByDreamId(dream?.id).then((res) => {
        dispatch(setAllDreamPayments(res?.data));
      });
      getDreamDonations(dream?.id, 1).then((response) => {
        dispatch(setProfileReceived(response?.data));
      });
    }
  }, [dream?.id]);

  useEffect(() => {
    if (lastDream) {
      navigate(`/dream-page/${lastDream}`);
      localStorage.removeItem("dreamId");
    }
    if (
      !!user.id &&
      !user?.firstName &&
      window.location.pathname !== "/user-profile/edit-form"
    ) {
      navigate("/user-profile/edit-form");
    }
    dispatch(setBgLoader(true));
    getAllCharities().then((response) => {
      setWingDonations(response?.data?.results);
    });
    lastTenDonations().then((response) => {
      setLastDonations(response?.data);
    });
    getProfileDream().then((res) => {
      dispatch(setDream(res.data));
    });
    getAllProfileDonations().then((res) => {
      dispatch(setAllProfileFulfilled(res?.data));
    });
    getAllProfileWingDonations().then((res) => {
      dispatch(setAllProfileWing(res.data));
    });
  }, []);

  const isFulfilled = (id) => {
    if (!user.balance) {
      setDreamId(id);
      setCustomModal("priceModal");
    } else {
      fulfill(id).then((res) => {
        if (!res) return;
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getDreamById(id).then((res) => {
          dispatch(setSpliceDreams(res.data));
        });
        getUser().then(({ data }) => {
          dispatch(setUser(data));
        });
        getAllProfileDonations().then((res) => {
          dispatch(setAllProfileFulfilled(res?.data));
        });
      });
    }
  };

  const getModal = () => {
    if (customModals === "randomModal") {
      const tooltipKey = generateTooltipKey(randomDonationId?.user?.id);
      return (
        <>
          {randomDonationId ? (
            <Modal
              key={randomDonationId?.id}
              backdrop={true}
              centered
              show={!!customModals}
              onHide={() => setCustomModal("")}
              animation={true}
            >
              <div className="d-flex flex-row flex-wrap random-container">
                <div
                  style={{ width: "100%" }}
                  className="mb-2 d-flex justify-content-between"
                >
                  <div>
                    <span className="random-header-span">
                      Your donation was successful!
                    </span>
                  </div>
                  <div onClick={() => setCustomModal("")}>
                    <span className="fa-close-icon" />
                  </div>
                </div>
                <div
                  className="mb-4"
                  style={{ height: "auto", width: "100%", borderRadius: "8px" }}
                >
                  <Carousel
                    slide={true}
                    fade={true}
                    interval={3000}
                    controls={randomDonationId?.images.length > 1}
                  >
                    {randomDonationId.images?.map((el) => (
                      <CarouselItem
                        style={{ maxHeight: "300px", borderRadius: "8px" }}
                        key={el.id}
                      >
                        <img
                          className="img-fluid random-image"
                          src={el.avatarUrl}
                          alt="el.name"
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            maxHeight: "290px",
                            borderRadius: "8px",
                          }}
                        />
                      </CarouselItem>
                    ))}
                  </Carousel>
                </div>

                <div className="random-donation-details">
                  <div>
                    <span className="random-details-header">
                      Donation Details
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <Link
                      to={`/dashboard/public-page/${randomDonationId.user?.id}`}
                    >
                      <img
                        className="fluid rounded-circle avatar-50 "
                        style={{ objectFit: "cover", marginRight: "10px" }}
                        src={
                          randomDonationId.user?.mainImageUrl || defaultImage
                        }
                        alt="randomDonationId.user.imageURL"
                      />
                    </Link>
                    <div>
                      <span
                        className="random-user-name"
                        data-tooltip-id={tooltipKey}
                      >
                        {randomDonationId.user?.firstName}{" "}
                        {randomDonationId.user?.lastName}
                      </span>
                      <TooltipDetails
                        userId={randomDonationId?.user?.id}
                        isFulfilled={isFulfilled}
                        allFulfilled={allFulfilled}
                        tooltipKey={tooltipKey}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex mb-1">
                      <span className="random-title">Dream Title:</span>
                    </div>
                    <div className="d-flex">
                      <span>{randomDonationId.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          ) : (
            ""
          )}
        </>
      );
    }
    if (customModals === "priceModal") {
      return (
        <PricingModal
          customModals={customModals}
          setCustomModal={setCustomModal}
          dreamId={dreamId}
        />
      );
    }
  };
  const handleRandomDonation = () => {
    setRandomDonationId(null);
    if (user.balance <= 0) {
      setCustomModal("priceModal");
    } else if (user.balance >= 1) {
      dispatch(setBgLoader(true));
      randomDonation().then((res) => {
        if (!res) return;
        getUser().then((response) => {
          dispatch(setUser(response?.data));
        });
        getDreamById(res?.data?.dreamId).then((res) => {
          setRandomDonationId(res?.data);
          setCustomModal("randomModal");
        });
        getAllProfileDonations().then((res) => {
          dispatch(setAllProfileFulfilled(res?.data));
        });
      });
    }
  };
  const handleWingDonation = (id) => {
    if (!user.balance) {
      setCustomModal("priceModal");
    } else {
      wingDonation(id).then((res) => {
        if (!res) return;
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getUser().then((response) => {
          dispatch(setUser(response?.data));
        });
        getAllProfileWingDonations().then((res) => {
          dispatch(setAllProfileWing(res.data));
        });
      });
    }
  };
  const progress = calculateDreamProgress(dream as DreamDto);
  const leftSideCardList = wingDonations?.map((elem, index) => {
    return (
      <Card key={index} className="m-2 p-2 rounded-3 shadow-card">
        <Card.Body className="p-0">
          <div className="media-height d-flex flex-column align-items-center justify-content-start gap-2">
            <div className="d-flex justify-content-start p-0 w-100">
              <Link to={`/wing/${elem?.id}`} className="m-0 p-0">
                <div className="mb-1 d-flex justify-content-center align-items-center">
                  <Image
                    style={{
                      objectFit: "cover",
                      width: "40px",
                      height: "40px",
                      borderRadius: "8px",
                    }}
                    src={
                      elem?.images?.find((image) => image?.isMain === true)
                        ?.url || coverImg
                    }
                    alt="user image"
                  />
                </div>
              </Link>
              <div
                className="d-flex flex-column justify-content-start ms-2"
                style={{ cursor: "pointer" }}
              >
                <h5
                  className="wing-title mb-1 my-title"
                  onClick={() => navigate(`/wing/${elem?.id}`)}
                >
                  {elem.title}
                </h5>
                <span className="my-span lh-1 text-dark">
                  {elem?.description}
                </span>
              </div>
            </div>
            <div
              className="dashboard-card-buttons custom-gradient w-100"
              onClick={() =>
                wingDonationsProfile?.find((d) => d === elem?.id)
                  ? navigate(`/wing/${elem?.id}`)
                  : handleWingDonation(elem?.id)
              }
            >
              <div className="dashboard-card-buttons-text">
                <span>
                  {wingDonationsProfile?.find((d) => d === elem?.id)
                    ? "View"
                    : "Donate"}
                </span>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  });
  const rightSideCardList = lastDonations
    ?.filter((el) => el?.dream?.id !== dream?.id)
    .map((elem, index) => {
      const tooltipKey = generateTooltipKey(elem?.dream?.user?.id);
      return (
        <Card
          key={index}
          className={`${
            index === 0 ? "margin-top-right" : "mt-2"
          } mb-2 rounded-3 me-2 shadow-card ms-2`}
        >
          <div className="d-flex flex-row align-items-center justify-content-between p-1">
            <div className="d-flex justify-content-start align-items-center">
              <div className="me-1">
                <Image
                  className="rounded-circle avatar-45 "
                  style={{ objectFit: "cover", cursor: "pointer" }}
                  src={elem?.dream?.images[0]?.avatarUrl || defaultImage}
                  alt="user image"
                />
              </div>
              <div
                className="me-1 mb-1 my-title text-dark"
                style={{ width: "75px", cursor: "pointer" }}
              >
                <h6
                  className="mb-0 fw-bolder my-title lh-1 mb-1 text-underline"
                  onClick={() => {
                    navigate(`/dashboard/public-page/${elem?.dream?.user?.id}`);
                  }}
                  data-tooltip-id={tooltipKey}
                >
                  {elem?.dream?.user?.firstName || "No name"}{" "}
                  {elem?.dream?.user?.lastName}
                </h6>
                <TooltipDetails
                  userId={elem?.dream?.user?.id}
                  isFulfilled={isFulfilled}
                  allFulfilled={allFulfilled}
                  tooltipKey={tooltipKey}
                />
                <p className="mb-0 my-title lh-sm font-cards-text">
                  {elem?.dream?.title}
                </p>
              </div>
            </div>
            <div>
              <div
                className="custom-gradient right-side-dashboard-buttons"
                onClick={() =>
                  allFulfilled?.find((d) => d === elem?.dream?.id)
                    ? navigate(
                        `/dashboard/public-page/${elem?.dream?.user?.id}`
                      )
                    : isFulfilled(elem?.dream?.id)
                }
              >
                <div className="dashboard-card-buttons-text">
                  <span>
                    {allFulfilled?.find((d) => d === elem?.dream?.id)
                      ? "View"
                      : "Fulfill"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      );
    });

  const CheckCashOut = () => {
    if (dream?.amount / 2 <= allFulfilled?.length) {
      openCashOut(true);
    } else {
      toast.dismiss();
      toast.clearWaitingQueue();
      notifyWarning("You have to fulfill half from your dream amount!");
    }
  };
  return (
    <>
      <Row className="m-0">
        <Col className="pe-0 mt-2 p-0" sm={12} md={12} lg={3} xl={2} xxl={2}>
          <div className="left-side-overflow">
            {cashOut && (
              <CashOut
                cashOut={cashOut}
                openCashOut={openCashOut}
                dreamId={dream?.id}
              />
            )}
            <Card className="rounded-3 ms-2 shadow-card me-2">
              <div
                style={{
                  backgroundImage: `url(${
                    user?.coverImage?.avatarUrl || coverImg
                  })`,
                  backgroundSize: "cover",
                }}
                className=" profile-img d-flex justify-content-center users-card-bg-image align-items-center"
              >
                <div>
                  <Link
                    to={"/user-profile"}
                    className="d-flex justify-content-center align-items-center bg-white avatar-95 mt-2"
                    style={{ borderRadius: "50%", border: "2px solid white" }}
                  >
                    <img
                      src={user?.mainImageUrl || defaultImage}
                      alt="userimg"
                      className="avatar-90 img-fluid"
                    />
                  </Link>
                </div>
              </div>
              <div className="p-2 text-center">
                <h6
                  className="mb-0 fw-bold mb-1 text-dark cursor-pointer"
                  onClick={() => navigate("/user-profile")}
                >
                  {user?.firstName || "No name"} {user?.lastName}
                </h6>
                <div className="d-flex justify-content-center">
                  <p
                    style={{ fontSize: "14px", color: "#4F4F4F" }}
                    className="mb-0 fw-bold w-75 lh-1 my-span"
                  >
                    {dream?.title}
                  </p>
                </div>
              </div>
              {dream?.id ? (
                <div>
                  <div className="d-flex justify-content-between m-2 mt-0">
                    <span style={{ fontSize: "13px" }} className="text-dark">
                      Scope
                    </span>
                    <span style={{ fontSize: "12px", color: "black" }}>
                      <b>{progress || "0"}%</b>
                    </span>
                  </div>
                  <ProgressBar
                    style={{
                      height: "16px",
                      cursor: "pointer",
                      borderRadius: "8px",
                    }}
                    onClick={() => setShow((prev) => !prev)}
                    now={progress}
                    className="ms-2 me-2 black-hover"
                    variant={"gradient"}
                  />
                  <DreamMakersModal
                    donations={profileReceived}
                    show={show}
                    setShow={setShow}
                    dreamId={dream.id}
                  />
                  <div
                    className="d-flex justify-content-between ms-2 me-2 mt-1 mb-2 text-dark"
                    style={{ fontSize: "12px" }}
                  >
                    <span>
                      Received: <b>{profileReceived?.meta?.itemCount || "0"}</b>
                    </span>
                    <span>
                      Fulfilled: <b>{allFulfilled?.length || "0"}</b>
                    </span>
                  </div>
                  {dream?.status === "COMPLETED" && !allPayments?.length ? (
                    <div className="d-flex justify-content-center mb-3 ms-2 me-2">
                      <GradientButton
                        text="Cash Out"
                        minWidth="100%"
                        onClick={() => CheckCashOut()}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {dream?.status === "COMPLETED" &&
                  allPayments?.length &&
                  allPayments?.[allPayments?.length - 1]?.status !==
                    "FAILED" ? (
                    <div className="d-flex justify-content-center mb-3 ms-2 me-2">
                      <GradientButton
                        text="Create a new dream!"
                        minWidth="100%"
                        onClick={() => navigate("/user-profile/create-dream")}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-center ms-2 me-2">
                  <GradientButton
                    text="Create your dream!"
                    minWidth="100%"
                    onClick={() => navigate("/user-profile/create-dream")}
                  />
                </div>
              )}
              {!dream?.id ? (
                <div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    <h6 style={{ cursor: "default" }}>
                      Fulfilled {allFulfilled?.length || "0"}
                    </h6>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Card>
            <div className={"test-buttons mt-4 mb-2"}>
              <div
                className={
                  activeTab === "Wing" ? "active-button" : "mobile-buttons"
                }
                onClick={() => setActiveTab("Wing")}
              >
                <span>Wing donations</span>
              </div>
              <div
                className={
                  activeTab === "Dreams" ? "active-button" : "mobile-buttons"
                }
                onClick={() => setActiveTab("Dreams")}
              >
                <span>Dreams</span>
              </div>
              <div
                className={
                  activeTab === "Last" ? "active-button" : "mobile-buttons"
                }
                onClick={() => setActiveTab("Last")}
              >
                <span> Last fulfilled</span>
              </div>
            </div>
            <div className={activeTab !== "Wing" ? "display-mobile" : ""}>
              <div
                className={
                  activeTab !== "Wing"
                    ? " d-flex justify-content-between w-100 ps-2 pt-0 pe-1 text-dark"
                    : "display-mobile"
                }
              >
                <span style={{ color: "#4F4F4F" }} className="fw-bold">
                  Wing donations
                </span>
                <p className="me-1 font-hover" style={{ cursor: "pointer" }}>
                  <u>See All</u>
                </p>
              </div>
              <div
                className={`left-side-scroll overflow-scroll ${
                  wingDonations.length > 3 ? `add-scroll` : "remove-scroll"
                }`}
              >
                {leftSideCardList}
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg={6}
          xl={8}
          md={8}
          sm={12}
          xxl={8}
          className={
            activeTab !== "Dreams"
              ? "display-mobile m-0 p-0 fluid"
              : "m-0 p-0 fluid"
          }
          style={{ position: "relative" }}
        >
          <div className="me-0 p-0">
            <div className="m-0">
              <Row className="d-flex b-0 w-100 sticky">
                <div className="w-100 d-flex text-center p-0">
                  <div className="w-100 d-flex text-center p-0">
                    <Col xs={0} md={3} />
                    <Col xs={7} md={6}>
                      <div
                        className="d-flex justify-content-center align-items-center center-header lh-1"
                        style={{ height: "36px" }}
                      >
                        <span
                          onClick={() => dispatch(setOrder("DESC"))}
                          className={"span-hover-underline"}
                          style={{
                            cursor: "pointer",
                            fontWeight: order === "DESC" ? "bold" : "",
                          }}
                        >
                          Most Popular
                        </span>
                        <span
                          onClick={() => dispatch(setOrder("ASC"))}
                          className={"span-hover-underline"}
                          style={{
                            paddingLeft: "20px",
                            cursor: "pointer",
                            fontWeight: order === "ASC" ? "bold" : "",
                          }}
                        >
                          Less popular
                        </span>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end" xs={5} md={3}>
                      <div
                        className="dashboard-card-buttons custom-gradient"
                        style={{ width: "180px", marginRight: "7px" }}
                        onClick={handleRandomDonation}
                      >
                        <div className="dashboard-card-buttons-text">
                          <span>Random fulfill</span>
                        </div>
                      </div>
                    </Col>
                  </div>
                  <div>{getModal()}</div>
                </div>
              </Row>
              <div
                style={{
                  overflow: "auto",
                }}
              >
                <InfiniteScroll
                  loadMore={() => loadMore()}
                  hasMore={hasNextPage}
                  useWindow={true}
                  loader={
                    <div
                      className="d-flex justify-content-center w-100"
                      key="loader"
                    >
                      <img
                        src={loaderGif}
                        alt="loader"
                        style={{ height: "70px" }}
                      />
                    </div>
                  }
                >
                  <Row className="m-0">
                    {allDreams?.map((dr) =>
                      dr?.amount !== dr?.amountReceived &&
                      dream?.id !== dr?.id ? (
                        <Col
                          sm={6}
                          xs={6}
                          md={6}
                          lg={6}
                          xl={4}
                          xxl={3}
                          style={{
                            padding: "0px 7px 0px 7px",
                            marginBottom: "14px",
                          }}
                          key={dr.id}
                        >
                          <DashboardCard
                            mainImage={mainImage}
                            isFulfilled={isFulfilled}
                            dream={dr}
                          />
                        </Col>
                      ) : null
                    )}
                  </Row>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg={3}
          xl={2}
          xxl={2}
          md={4}
          sm={12}
          className={
            activeTab !== "Last"
              ? "display-mobile p-0 right-side-overflow"
              : "p-0 right-side-overflow"
          }
        >
          <div
            className={
              activeTab !== "Last"
                ? "text-start m-2 mb-1 mt-3"
                : " display-mobile text-start m-2 mb-1 mt-3"
            }
          >
            <span className="w-100 fw-bold">Last fulfilled</span>
          </div>
          <div
            className="add-scroll"
            style={{
              height: "89.5vh",
              marginRight: "2px",
              paddingBottom: "33px",
              overflow: `${lastDonations?.length > 15 ? `scroll` : "hidden"}`,
            }}
          >
            {rightSideCardList}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
