import React from "react";
import "../../../styles/Dashboard.css";
import {Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import NavBarComponent from "../../../components/NavBar/NavBar";
import { UsersModule } from "src/types/redux";

const AboutUs = () => {
  const navigate = useNavigate();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  return (
    <div>
      {user?.id ? (
        <NavBarComponent />
      ) : (
        <header className="login-header">
          <Row className="p-0 m-0 d-flex w-100 align-items-center">
            <div className="d-flex justify-content-between">
              <div className="d-flex ms-5">
                <div
                  className="fa-logo-icon cursor-pointer"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </Row>
        </header>
      )}
      <div
        className="d-flex flex-column"
        style={{ width: "100vw", height: "100vh" }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: "100%",
            height: "100px",
            marginBottom: "35px",
            marginTop: "20px",
          }}
        >
          <span className="faq-text">About Us</span>
        </div>
        <div
          className="d-flex flex-column about-us-padding"
          style={{ height: "100vh" }}
        >
          <div
            className="about-us-title"
            style={{ paddingTop: "10px", paddingBottom: "20px" }}
          >
            We are Dreamerz
          </div>
          <div>
            <div className="abous-us-description">
              The most important thing in our life is to dream and then realize
              all the dreams we have. When there is a dream, there is hope,
              there is life. Together we can create a world that has never
              existed before. A world that you have been longing for, and one
              that will pave the way for a brighter future. Imagine a world full
              of happiness where dreams come true, where you share and receive
              back twice that you shared. Together, we will have a much better
              chance of improving your performance and fulfilling each other’s
              dreams by sharing only one $tar (dollar) for a dream. The good
              news is that it is never too late to start on a journey to turn
              your dream into a reality.
            </div>
            <div className="abous-us-description">
              This idea also came from a dream, so now we try to help others
              live better, fulfil their dreams, trying to change the whole
              world, so lets make it happen. Dream ON!
            </div>
            <div className="abous-us-description">
              'Let The Dreams be your Wings'.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
