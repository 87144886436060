import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoader, setErrorMessage, setUser } from "../../store/users";
import {
  deleteDreamImage,
  getUser,
  updateDream,
  uploadDreamImage,
  uploadDreamImages,
} from "../../services/api";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { dash, image, plus } from "../../consts/SvgIcon";
import { UsersModule } from "../../types/redux";

const EditDream = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dream = useSelector(
    (state: UsersModule) => state.usersModule.dreams[0]
  );
  const [dreamData, setDreamData] = useState(dream);
  const [imageId, setImageId] = useState("");

  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const errorMessage = useSelector(
    (state: UsersModule) => state.usersModule
  ).errorMessage;
  const [modalProps, setModalProps] = useState(false);

  const sortImages = (images) => {
    const imgs = [];
    images?.forEach((img) => {
      if (img.isMain !== true) imgs.push(img);
    });
    const amount = 3 - imgs.length;
    for (let i = 0; i <= amount; i++) {
      imgs.push(null);
    }
    return imgs;
  };
  useEffect(() => {
    dispatch(setErrorMessage(""));
    setDreamData(dream);
  }, [dream]);

  const ModalRender = () => {
    return (
      <Modal
        backdropClassName={"bg-transparent"}
        centered
        backdrop={true}
        show={modalProps}
        onHide={() => setModalProps(false)}
        animation={true}
      >
        <div>
          <div className="modal-header border-0">
            <h5 className="modal-title fw-bold" id="exampleModalLongTitle">
              Confirmation
            </h5>
          </div>
          <div className="modal-body">
            Are you sure that you want to delete the photo?
          </div>
          <div className="modal-footer border-0">
            <div
              className="dashboard-card-buttons custom-gradient me-2"
              onClick={() => confirmDelete()}
              style={{ width: "100px", height: "30px" }}
            >
              <div className="dashboard-card-buttons-text">
                <span>Yes</span>
              </div>
            </div>
            <button
              className="custom-btn text-black mb-1"
              onClick={() => setModalProps(false)}
              style={{ height: "30px", width: "100px" }}
            >
              <div className="dashboard-card-buttons-text fw-bold">No</div>
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const handleEdit = (event) => {
    const target = event.target;
    const dreamDataClone = { ...dreamData };
    dreamDataClone[target.id] = target.value;
    setDreamData(dreamDataClone);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setShowLoader(true));
    const { title, amount } = event.target.elements;
    updateDream(dream.id, title.value, parseInt(amount.value, 10)).then(() => {
      getUser().then((response) => {
        dispatch(setUser(response.data));
        navigate("/user-profile");
      });
    });
  };

  const handleImageChange = (e) => {
    dispatch(setErrorMessage(""));
    const images = [...e.target.files];
    if (images.length === 1) {
      uploadDreamImage(dream.id, images[0]).then(() => {
        getUser().then((response) => {
          dispatch(setUser(response.data));
        });
      });
    } else {
      if (images.length > 1 && images.length < 6) {
        uploadDreamImages(dream.id, images).then(() => {
          getUser().then((response) => {
            dispatch(setUser(response.data));
          });
        });
      }
    }
  };

  const confirmDelete = () => {
    deleteDreamImage(dream.id, imageId).then(() => {
      getUser().then((response) => {
        dispatch(setUser(response.data));
        setModalProps(false);
      });
    });
  };

  return (
    <>
      <Container>
        <Col lg={12}>
          <Card className="p-4 pt-3 pb-3 mt-3 shadow-card">
            <Card.Header className="d-flex justify-content-between border-0">
              <div className="header-title">
                <h3 className="card-title fw-bold">Edit Dream</h3>
              </div>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Col sm={12} className="d-flex justify-content-start flex-wrap">
                  <div
                    className="profile-img-edit"
                    style={{ margin: "0 20px 10px 0" }}
                  >
                    {dreamData.images?.find((image) => image.isMain === true)
                      ?.url ? (
                      <div>
                        <div
                          className="profile-pic"
                          style={{
                            backgroundImage: `url(${
                              dreamData.images.find(
                                (image) => image.isMain === true
                              )?.url
                            })`,
                          }}
                        />
                        <div
                          className="d-flex justify-content-center align-items-center p-image-delete"
                          onClick={() => {
                            setModalProps(true);
                            setImageId(
                              dreamData.images.find(
                                (image) => image.isMain === true
                              )?.id
                            );
                          }}
                        >
                          {dash()}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="profile-default-pic">{image()}</div>
                        <div className="d-flex justify-content-center align-items-center p-image-add">
                          {plus()}
                          <input
                            className="file-upload"
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              opacity: 0,
                              display: "block",
                            }}
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            multiple
                            name="image"
                            id="image"
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-start flex-wrap">
                    {sortImages(dream?.images).map((img, index) => {
                      if (img !== null)
                        return (
                          <div
                            key={img.id}
                            style={{
                              margin: "0 20px 10px 0",
                              position: "relative",
                            }}
                          >
                            <div
                              className="profile-pic"
                              style={{
                                backgroundImage: `url(${img.url})`,
                              }}
                            />
                            <div
                              className="d-flex justify-content-center align-items-center p-image-delete"
                              onClick={() => {
                                setModalProps(true);
                                setImageId(img.id);
                              }}
                            >
                              {dash()}
                            </div>
                          </div>
                        );
                      else
                        return (
                          <div
                            style={{
                              margin: "0 20px 10px 0",
                              position: "relative",
                            }}
                            key={index}
                          >
                            <div className="profile-default-pic">{image()}</div>
                            <div className="d-flex justify-content-center align-items-center p-image-add">
                              {plus()}
                              <input
                                className="file-upload"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "absolute",
                                  opacity: 0,
                                  display: "block",
                                }}
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                                multiple
                                name="image"
                                id="image"
                                onChange={handleImageChange}
                              />
                            </div>
                          </div>
                        );
                    })}
                  </div>
                  {ModalRender()}
                </Col>
                <p className="text-danger">{errorMessage}</p>
                <Row className="align-items-center">
                  <Form.Group className="form-group col-sm-12">
                    <Form.Label className="form-label fw-bold">
                      Title:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      className="form-control border border-light"
                      rows={5}
                      style={{
                        lineHeight: "22px",
                        resize: "none",
                        backgroundColor: "#f8f9fa",
                      }}
                      id="title"
                      placeholder="Title"
                      required
                      value={dreamData.title || ""}
                      onChange={handleEdit}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label
                      htmlFor="firstName"
                      className="form-label fw-bold"
                    >
                      Amount:
                    </Form.Label>
                    <Form.Control
                      type="number"
                      disabled={true}
                      id="amount"
                      placeholder="Amount"
                      required
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      value={dreamData.amount + "$" || ""}
                    />
                  </Form.Group>
                </Row>
                <div className="d-flex justify-content-end">
                  {showLoader && (
                    <img
                      src={loader}
                      alt="loader"
                      style={{ height: "39.2px" }}
                    />
                  )}
                  <div
                    className="dashboard-card-buttons custom-gradient me-2"
                    onClick={() => navigate("/user-profile")}
                    style={{ width: "100px", height: "32px" }}
                  >
                    <div className="dashboard-card-buttons-text">
                      <span>Cancel</span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="custom-btn text-black"
                    style={{ height: "30px", width: "100px" }}
                  >
                    <div className="dashboard-card-buttons-text fw-bold">
                      Submit
                    </div>
                  </button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default EditDream;
