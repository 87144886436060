import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import useIsMobile from "src/hooks/useIsMobile";
import { getNewDreams } from "src/services/api";
import ViewAll from "./ViewAll";
import { SLIDERS_ENUM } from "src/consts/slidersEnum";
import Carousel from "react-multi-carousel";
import { birthdayResponsive } from "src/consts/breakpoints";
import NewDreamCard from "./NewDreamCard";
import { useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";
import { DreamDto } from "src/services/api/data-contracts";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right-icon.svg";
import { scrollCarousel } from "src/utils/scrollCarousel";

const NewDreams = ({ isFulfilled, sliderWidth }) => {
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;

  const isMobile = useIsMobile(768);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { data: newDreams, isLoading } = useQuery<DreamDto[]>({
    queryKey: ["newDreams"],
    queryFn: () => getNewDreams().then((res) => res.data.results),
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {newDreams.length >= 5 && (
        <div className="birthday-app">
          <div className="dream-header">
            <h4 className="dream-slider-title">New Dreams:</h4>
            <div className="d-flex align-items-center">
              {newDreams.length >= 12 ? (
                <div onClick={handleShowModal} className="view-all-button">
                  <p className="view-all">view all</p>
                </div>
              ) : (
                <></>
              )}
              {!isMobile ? (
                <div className="carousel-arrow-container">
                  <div
                    className="icon-container-hover-right"
                    onClick={() => scrollCarousel("left", "new-dreams-scroll")}
                  >
                    <ArrowLeft className="img-arrow-hover" />
                  </div>
                  <div
                    className="icon-container-hover-left"
                    onClick={() => scrollCarousel("right", "new-dreams-scroll")}
                  >
                    <ArrowRight className="img-arrow-hover" />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <ViewAll
              show={showModal}
              handleClose={handleCloseModal}
              items={newDreams}
              isFulfilled={isFulfilled}
              type={SLIDERS_ENUM.NEW_DREAMS}
            />
          </div>
          {isMobile ? (
            <div className="carousel-wrapper slider-margin-mobile">
              <Carousel
                responsive={birthdayResponsive}
                className="carousel-width slider-carousel"
                sliderClass="d-flex align-items-center justify-content-between"
                itemClass="dream-card-mobile"
                arrows={false}
                swipeable
                infinite={false}
                draggable
              >
                {newDreams.slice(0, 12).map((donation) => (
                  <NewDreamCard
                    key={donation.id}
                    dream={donation}
                    isFulfilled={isFulfilled}
                    allFulfilled={allFulfilled}
                  />
                ))}
              </Carousel>
            </div>
          ) : (
            <div
              className="dream-dreams-container new-dreams-scroll"
              style={{ width: sliderWidth }}
            >
              {newDreams.slice(0, 12).map((donation) => (
                <NewDreamCard
                  key={donation.id}
                  dream={donation}
                  isFulfilled={isFulfilled}
                  allFulfilled={allFulfilled}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default NewDreams;
