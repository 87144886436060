import { Image, Offcanvas } from "react-bootstrap";
import defaultImage from "../../assets/images/defaultUser.png";
import { useNavigate } from "react-router-dom";
import {
  aboutUs,
  balanceStar,
  dashboard,
  explore,
  faq,
  howItWorks,
  leaveFeedback,
  logout,
  personCircle,
  price,
  privacy,
  terms,
} from "../../consts/SvgIcon";
import React, { useState } from "react";
import {
  setAllProfileFulfilled,
  setBgLoader,
  setDream,
  setErrorMessage,
  setIsAuth,
  setUser,
} from "../../store/users";
import { useDispatch } from "react-redux";
import GradientButton from "../Buttons/GradientButton";
import {
  getAllProfileDonations,
  getDreamById,
  getUser,
  randomDonation,
} from "../../services/api";
import { NavigationOffCanvasProps } from "src/types/components-props";

const NavigationOffCanvas = ({
  user,
  setCustomModal,
  setRandomDonationId,
  setFeedback,
}: NavigationOffCanvasProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const menu = {
    main: [
      { name: "Explore", click: () => navigate("/newsfeed"), icon: explore() },
      {
        name: "Dashboard",
        click: () => navigate("/dashboard"),
        icon: dashboard(),
      },
      {
        name: "My profile",
        click: () => navigate("/user-profile"),
        icon: personCircle(),
      },
      {
        name: "Pricing page",
        click: () => navigate("/pricing-page"),
        icon: price(),
      },
    ],
    aboutCompany: [
      { name: "About us", click: () => navigate("/about-us"), icon: aboutUs() },
      {
        name: "How it works",
        click: () => navigate("/how-it-works"),
        icon: howItWorks(),
      },
      { name: "FAQ", click: () => navigate("/faq"), icon: faq() },
      {
        name: "Leave Feedback",
        click: () => setFeedback(true),
        icon: leaveFeedback(),
      },
      {
        name: "Terms and Conditions",
        click: () => navigate("/terms-conditions"),
        icon: terms(),
      },
      {
        name: "Privacy Policy",
        click: () => navigate("/privacy-policy"),
        icon: privacy(),
      },
    ],
  };
  const handleRandomDonation = () => {
    setRandomDonationId(null);
    setShow(false);
    if (user.balance <= 0) {
      setCustomModal("priceModal");
    } else if (user.balance > 0) {
      dispatch(setBgLoader(true));
      randomDonation().then((res) => {
        if (!res) return;
        getUser().then((response) => {
          dispatch(setUser(response?.data));
        });
        getDreamById(res?.data?.dreamId).then((res) => {
          setRandomDonationId(res?.data);
          setCustomModal("randomModal");
        });
        getAllProfileDonations().then((res) => {
          dispatch(setAllProfileFulfilled(res?.data));
        });
      });
    }
  };

  const SignOut = () => {
    dispatch(setIsAuth(false));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profileId");
    dispatch(setUser({}));
    dispatch(setDream({}));
    dispatch(setErrorMessage(""));
    navigate("/");
  };
  return (
    <div className="nav-item">
      <div>
        <Image
          src={user?.mainImageUrl || defaultImage}
          className="rounded-circle me-2 cursor-pointer nav-avatar"
          alt="user"
          onClick={() => setShow(true)}
        />
      </div>
      <Offcanvas
        show={show}
        className="canvas-container"
        placement="end"
        onHide={() => setShow(false)}
      >
        <div className="m-0 p-3">
          <Offcanvas.Header className="p-0">
            <div className="d-flex flex-column gap-3 w-100">
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex gap-2 lh-1 align-items-center cursor-pointer"
                  onClick={() => {
                    navigate("/user-profile");
                    setShow(false);
                  }}
                >
                  <img
                    src={user.mainImageUrl || defaultImage}
                    alt="profile"
                    className="avatar-menu "
                  />
                  <span className="fw-bold text-dark fs-5 underline-name">
                    {user?.firstName} {user?.lastName}
                  </span>
                </div>
                <div className="user-balance">
                  <h5>Balance</h5>
                  <div className="user-balance-footer">
                    <span>{user?.balance ? user?.balance : 0}</span>{" "}
                    <span>{balanceStar()}</span>
                  </div>
                </div>
              </div>
              <GradientButton
                text="Random Fulfill"
                onClick={handleRandomDonation}
              />
            </div>
          </Offcanvas.Header>
          <hr className="text-gray" />
          <Offcanvas.Body className="p-0 d-flex flex-column ">
            {menu.main.map((el) => (
              <div
                className="navigation-item"
                onClick={() => {
                  el.click();
                  setShow(false);
                }}
                key={el.name}
              >
                {el.icon}
                <span>{el.name}</span>
              </div>
            ))}
            <hr className="text-gray" />
            {menu.aboutCompany.map((el) => (
              <div
                className="navigation-item"
                onClick={() => {
                  el.click();
                  setShow(false);
                }}
                key={el.name}
              >
                {el.icon}
                <span>{el.name}</span>
              </div>
            ))}
            <hr className="text-gray" />
            <div
              onClick={SignOut}
              className="navigation-item cursor-pointer"
              style={{ color: "#E44747" }}
            >
              {logout()}
              <span>Logout</span>
            </div>
          </Offcanvas.Body>
        </div>
      </Offcanvas>
    </div>
  );
};
export default NavigationOffCanvas;
