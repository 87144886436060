import moment from "moment";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShareButton from "src/components/Buttons/ShareButton";
import NewsfeedSocialInteraction from "src/components/Partials/NewsfeedSocialInteraction";
import { UsersModule } from "src/types/redux";
import { shareTo } from "src/utils/share";
import defaultImage from "../../assets/images/defaultUser.png";
import { getAngelNewsfeedPostById } from "src/services/api";
import { useQuery } from "@tanstack/react-query";
import { SOCIALS_ENUM } from "src/consts/socialsEnum";
import Comments from "src/components/Comments/Comments";
import FulfilledDreamsCarousel from "src/components/PublicandInternShared/FulfilledDreamsCarousel";
import { commentType } from "src/consts/commentsTypeEnum";
import ShareModal from "src/components/Partials/ShareModal";
import PricingModal from "../Pricing/PricingModal";
import { handleFulfillment } from "src/utils/isFulfilled";
import { updateAngelLikes } from "src/store/action";
import { shareType } from "src/consts/notifications";

export default function DreamAngelPage() {
  const { id } = useParams();

  const { data: angelData, isLoading } = useQuery({
    queryKey: ["dreamAngelPage", id],
    queryFn: () => getAngelNewsfeedPostById(id).then((res) => res?.data),
    enabled: !!id,
    onSuccess: (data) => {
      dreamAngelData(data);
    },
  });

  const dreamAngelData = (data) => {
    const likesCount = data.likedNewsFeedByUsers.length;
    const likedBy = data.likedNewsFeedByUsers.map((item) => item.user.id);
    const userLiked = likedBy.includes(localStorage.getItem("profileId"));
    dispatch(updateAngelLikes(data?.id, likesCount, userLiked, likedBy));
  };

  const dispatch = useDispatch();
  const [shareModal, setShareModal] = useState(false);
  const [customModals, setCustomModal] = useState("");
  const [dreamId, setDreamId] = useState("");
  const [link, setLink] = useState(process.env.REACT_APP_BASE_URL);
  const mainUser = useSelector((state: UsersModule) => state.usersModule).user;
  const navigate = useNavigate();

  const isFulfilled = (id) => {
    handleFulfillment(id, mainUser, setDreamId, setCustomModal, dispatch);
  };

  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;

  if (isLoading) {
    return <></>;
  }
  const share = () => {
    setLink(`${link}/dream-angel/${angelData?.id}`);
    shareTo({
      link: `${link}/dream-angel/${angelData?.id}`,
      type: shareType.SHARE_DREAM_ANGEL,
      replyUserId: mainUser?.id,
      dreamAngelId: angelData?.dreamAngelId,
      setShareModal,
    });
  };

  return (
    <div className="d-flex justify-content-center flex-column mt-2">
      <ShareModal
        customModals={shareModal}
        setCustomModal={setShareModal}
        sharedLink={link}
        post={angelData}
        mainUser={mainUser}
      />
      <div className="wing-donation-page-card mb-5" style={{ width: "680px" }}>
        <Card className="rounded-3 gap-card shadow m-0 pb-0">
          <Card.Body className="p-0 gap-post">
            <div className="d-flex align-items-center flex-row justify-content-between gap-2">
              <span className="time-ago">
                {moment(angelData?.createdAt)?.fromNow(true)} ago
              </span>
              <div className="buttons-container" style={{ width: "72px" }}>
                <ShareButton width="72px" onClick={() => share()} />
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center gap-2 w-100 gap-card">
              <div className="gradient-border-container">
                <img
                  src={angelData?.user?.mainImageUrl || defaultImage}
                  alt="profile"
                  className="angel-dream-post-avatar"
                />
              </div>
              <div className="gradient-bg">
                <span className="text-dark">Dream Angel</span>
              </div>
              <span
                className="user-name cursor-pointer"
                onClick={() =>
                  navigate(`/dashboard/public-page/${angelData?.user?.id}`)
                }
              >
                {`${angelData?.user?.firstName || "No name"} 
                  ${angelData?.user?.lastName || ""}`}
              </span>
              <span className="time-ago">{angelData?.title}</span>
            </div>
            <NewsfeedSocialInteraction
              post={angelData}
              type={SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL}
            ></NewsfeedSocialInteraction>
            <Comments
              user={mainUser}
              mainImg={mainImg}
              isFulfilled={isFulfilled}
              postId={id}
              dreamAngelId={angelData?.dreamAngelId}
              type={commentType.angel}
            />
          </Card.Body>
        </Card>
      </div>
      <FulfilledDreamsCarousel
        userId={angelData?.user?.id}
        isFulfilled={isFulfilled}
      />
      <PricingModal
        customModals={customModals}
        setCustomModal={setCustomModal}
        dreamId={dreamId}
      />
    </div>
  );
}
