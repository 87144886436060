import { shareNotification } from "src/services/api";
import { ShareToProps } from "src/types/models";

export const shareTo = async ({
  link,
  type,
  dreamId,
  replyUserId,
  media,
  dreamAngelId,
  setShareModal,
}: ShareToProps) => {
  if (
    (!!navigator.canShare &&
      navigator.share &&
      /android/i.test(navigator.userAgent)) ||
    /iPad|iPhone|iPod/.test(navigator.userAgent)
  ) {
    await navigator.share({
      url: link,
    });
    type &&
      shareNotification({ type, dreamId, replyUserId, media, dreamAngelId });
  } else {
    setShareModal(true);
  }
};
