import { Carousel, Col, Modal, ProgressBar, Row } from "react-bootstrap";
import coverImg from "../../assets/images/coverImg.png";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/defaultUser.png";
import InfiniteScroll from "react-infinite-scroller";
import React, { useEffect, useRef, useState } from "react";
import {
  createCharitiesComments,
  createComments,
  getCharityComments,
  getComments,
  getPublicFulfilled,
} from "../../services/api";
import Comment from "./Comment";
import { closeIcon, leftArrowV2, rightArrowV2 } from "../../consts/SvgIcon";
import moment from "moment";
import ShareButton from "../Buttons/ShareButton";
import GradientButton from "../Buttons/GradientButton";
import ShareModal from "../Partials/ShareModal";
import { setPublicFulfilled } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import InputComment from "./InputComment";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import { UsersModule } from "../../types/redux";
import { CommentsModalProps } from "../../types/comments-props";
import NewsfeedSocialInteraction from "../Partials/NewsfeedSocialInteraction";
import { SOCIALS_ENUM } from "src/consts/socialsEnum";
import { CharityDto, DreamDto } from "src/services/api/data-contracts";
import {
  updateCharityCommentsCount,
  updateDreamCommentsCount,
} from "src/store/action";
import { fulfillCheck } from "src/utils/fulfillCheck";

const modalCarousel = (img) => {
  return (
    <Carousel.Item key={img}>
      <img src={img} className="modal-comment-image" alt="Responsive" />
    </Carousel.Item>
  );
};
let page2d = 1;
let loader = false;

const CommentsModal = ({
  dream,
  setModalProps,
  modalProps,
  setComments,
  comments,
  hasMore,
  setHasMore,
  mainImage,
  isFulfilled,
  currentUserComment,
  setComment,
  currentUser,
  allFulfilled,
  progress,
  type,
  user,
  post,
}: CommentsModalProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emojiPicker, showEmojiPicker] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const scrollRef = useRef(null);
  const commentsInputRef = useRef(null);
  const [link, setLink] = useState(process.env.REACT_APP_BASE_URL);
  const [customModals, setCustomModal] = useState(false);
  const publicFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicFulfilled;
  const wingDonationsProfile = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileWing;

  useDisableBodyScroll(modalProps);

  useEffect(() => {
    modalProps &&
      type !== "charity" &&
      getPublicFulfilled(user?.id, 1).then((res) => {
        dispatch(setPublicFulfilled(res?.data));
      });
  }, [modalProps]);

  const checkIsFulfilled = fulfillCheck(allFulfilled, dream, user?.id);

  const checkIsDonated = wingDonationsProfile?.find(
    (d) => d === dream?.id || dream?.amount === dream?.amountReceived
  );

  const share = () => {
    setLink(`https://dreamerz.net/dream/${dream?.id}`);
    setCustomModal(true);
  };
  const wingShare = () => {
    setLink(`https://dreamerz.net/wing/${dream?.id}`);
    setCustomModal(true);
  };
  const loadMore = () => {
    if (comments?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        if (dream?.id) {
          type === "charity"
            ? getCharityComments(dream?.id, page2d, 10).then((response) => {
                setComments((prevState) => [
                  ...prevState?.map((el) => ({ ...el })),
                  ...response.data.results?.map((el) => ({ ...el })),
                ]);
                setHasMore(response?.data?.meta?.hasNextPage);
                setCurrentPage(currentPage + 1);
              })
            : getComments(dream?.id, page2d, 10).then((response) => {
                setComments((prevState) => [
                  ...prevState?.map((el) => ({ ...el })),
                  ...response.data.results?.map((el) => ({ ...el })),
                ]);
                setHasMore(response?.data?.meta?.hasNextPage);
                setCurrentPage(currentPage + 1);
              });
        }
        loader = false;
      }
    }
  };

  const addComment = (dreamId) => {
    if (currentUserComment.length) {
      type === "charity"
        ? createCharitiesComments({
            message: currentUserComment,
            charityId: dreamId,
          }).then(() => {
            getCharityComments(dreamId, 1, 10).then((res) => {
              dispatch(
                updateCharityCommentsCount(dreamId, res?.data?.meta?.itemCount)
              );
              setComments(res?.data?.results);
              setHasMore(res?.data?.meta?.hasNextPage);
              page2d = 1;
            });
          })
        : createComments({
            message: currentUserComment,
            dreamId: dreamId,
          }).then(() => {
            getComments(dreamId, 1, 10).then((res) => {
              dispatch(
                updateDreamCommentsCount(dreamId, res?.data?.meta?.itemCount)
              );
              setComments(res?.data?.results);
              setHasMore(res?.data?.meta?.hasNextPage);
              page2d = 1;
            });
          });
      setComment("");
      let scrollDiv = document.getElementById("commentScroll");
      scrollDiv!.scrollTop = 0;
    }
  };

  const handleComment = (event) => {
    setComment(event.target.value);
    if (!currentUserComment.length && event.key === "Enter") {
      event.preventDefault();
    } else if (event.key === "Enter" && currentUserComment.length) {
      event.preventDefault();
      addComment(dream?.id);
    }
  };
  const commentsList = comments
    ?.filter((el) => el?.parentId === null)
    .map((elem, index) => {
      return (
        <div key={`popup-comment-${index}-${elem?.id}}`}>
          <Comment
            user={currentUser}
            dreamId={type === "charity" ? elem.charityId : elem?.dreamId}
            elem={elem}
            type={type}
            addLines
            isFulfilled={isFulfilled}
          />
        </div>
      );
    });

  return (
    <Modal
      size="xl"
      fullscreen="md-down"
      centered
      backdrop={true}
      show={modalProps}
      onHide={() => setModalProps(false)}
      animation={true}
      style={{ maxWidth: "unset" }}
      contentClassName="border-0"
      dialogClassName="width-set"
    >
      <ShareModal
        customModals={customModals}
        setCustomModal={setCustomModal}
        sharedLink={link}
        post={dream}
        mainUser={currentUser}
        anotherModalOpen={true}
      />
      <div className="modal-comments-container">
        <Row className="popup-comment flex-nowrap justify-content-start w-100 p-0 m-0">
          <Col
            md={7}
            sm={12}
            className="position-relative p-0 m-0"
            style={{ height: "250px" }}
          >
            <div
              className="arrow-container position-absolute close-container"
              onClick={() => setModalProps(false)}
            >
              {closeIcon()}
            </div>
            <Carousel
              indicators={false}
              interval={null}
              controls={dream?.images?.length > 1}
              nextIcon={
                dream?.images?.length > 1 && (
                  <div className="arrow-container right-arrow-position">
                    {rightArrowV2()}
                  </div>
                )
              }
              prevIcon={
                dream?.images?.length > 1 && (
                  <div className="arrow-container left-arrow-position">
                    {leftArrowV2()}
                  </div>
                )
              }
            >
              {dream?.images?.length
                ? dream.images?.map((img) => modalCarousel(img?.url))
                : modalCarousel(coverImg)}
            </Carousel>
          </Col>
          <Col
            md={5}
            sm={12}
            className="p-0"
            style={{ zIndex: "1", height: "400px" }}
          >
            <div
              className="d-flex flex-column justify-content-between responsive-container"
              ref={commentsInputRef}
            >
              <div className="d-flex flex-column" style={{ height: "100%" }}>
                <div
                  style={{
                    borderBottom: "1px solid #F1F1F1",
                  }}
                  className="d-flex flex-column gap-3 mb-3 pb-3"
                >
                  <div className="d-flex justify-content-between align-items-center w-100 gap-2 mobile-buttons-modal">
                    <div className="d-flex  align-items-center w-100 gap-2">
                      <img
                        src={user?.mainImageUrl || defaultImage}
                        alt="profile"
                        className="avatar-menu "
                      />
                      <div
                        className="d-flex align-items-center w-50 gap-2"
                        onClick={() =>
                          navigate(
                            `/dashboard/public-page/${user?.id || "/dashboard"}`
                          )
                        }
                      >
                        <div className="d-flex flex-column">
                          {type === "charity" ? (
                            <span className="user-name cursor-pointer">
                              {dream?.title}
                            </span>
                          ) : (
                            <span className="user-name cursor-pointer">
                              {user?.firstName || "No Name"}{" "}
                              {user?.lastName || ""}
                            </span>
                          )}
                          <span className="time-ago">
                            {moment(dream?.createdAt)?.fromNow(true)} ago
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="buttons-container-modal">
                      <ShareButton
                        width={window.innerWidth > 1000 ? "72px" : "40%"}
                        onClick={() =>
                          type === "charity" ? wingShare() : share()
                        }
                      />
                      {type === "charity" ? (
                        <GradientButton
                          minWidth="137px"
                          text={checkIsDonated ? "View" : "Fulfill"}
                          onClick={() =>
                            checkIsDonated
                              ? navigate(`/wing/${dream?.id}`)
                              : isFulfilled(dream?.id)
                          }
                        />
                      ) : (
                        <GradientButton
                          minWidth="137px"
                          text={checkIsFulfilled ? "View Profile" : "Fulfill"}
                          onClick={() =>
                            checkIsFulfilled
                              ? navigate(`/dashboard/public-page/${user?.id}`)
                              : isFulfilled(dream?.id)
                          }
                        />
                      )}
                    </div>
                  </div>
                  <span className="my-span comment-header-title">
                    {type === "charity"
                      ? (dream as CharityDto)?.description
                      : dream?.title}
                  </span>
                  <div className="d-flex flex-column gap-1">
                    <div className="d-flex align-items-center justify-content-between gap-2">
                      <ProgressBar
                        now={progress}
                        variant={"gradient"}
                        className="post-progress-bar "
                      />
                      <span
                        className="fw-bold text-dark"
                        style={{ fontSize: "12px" }}
                      >
                        {isNaN(progress) ? 0 : progress}%
                      </span>
                    </div>
                    <div
                      className="d-flex justify-content-between text-dark"
                      style={{ fontSize: "13px" }}
                    >
                      {type !== "charity" && (
                        <span>
                          Fulfilled{" "}
                          <b>{publicFulfilled?.meta?.itemCount || 0}</b>
                        </span>
                      )}
                      <span>
                        Received <b>{dream?.amountReceived || 0}</b>
                      </span>
                    </div>
                    <NewsfeedSocialInteraction
                      dream={type !== "charity" ? (dream as DreamDto) : null}
                      charity={
                        type === "charity" ? (dream as CharityDto) : null
                      }
                      type={
                        type === "charity"
                          ? SOCIALS_ENUM.INTERN_CHARITY
                          : SOCIALS_ENUM.NEWSFEED_DREAM
                      }
                      isCommentButton={true}
                      focusToInput
                      inputToFocus={commentsInputRef}
                    ></NewsfeedSocialInteraction>
                  </div>
                </div>
                <div
                  className="overflow-auto popup-comment-list "
                  id="commentScroll"
                  ref={scrollRef}
                >
                  <InfiniteScroll
                    loadMore={() => loadMore()}
                    hasMore={hasMore}
                    getScrollParent={() => scrollRef.current}
                    useWindow={false}
                    threshold={10}
                  >
                    {commentsList}
                  </InfiniteScroll>
                </div>
              </div>
              <InputComment
                currentUserComment={currentUserComment}
                setComment={setComment}
                emojiPicker={emojiPicker}
                showEmojiPicker={showEmojiPicker}
                mainImage={mainImage}
                handleComment={(e) => handleComment(e)}
                addComment={() => addComment(dream?.id)}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
export default CommentsModal;
