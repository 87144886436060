import { facebookShare, telegram, twitterShare, whatsApp } from "./SvgIcon";
import messengerImg from "../assets/images/messengerApp.svg";
import React from "react";
import { MISSING_DATA_ENUM } from "./missingDataEnum";

export const socialMedia = [
  {
    url: "https://twitter.com/intent/tweet?text=",
    media: "Twitter",
    id: "twitter",
    icon: twitterShare(),
  },
  {
    url: "https://www.facebook.com/sharer/sharer.php?u=",
    media: "Facebook",
    id: "facebook",
    icon: facebookShare(),
  },
  {
    url: "https://wa.me/?text=",
    media: "WhatsAppUrl",
    id: "whatsAppUrl",
    icon: whatsApp(),
  },
  {
    url: "https://telegram.me/share/url?url=",
    media: "Telegram",
    id: "telegram",
    icon: telegram(),
  },
  {
    url: "https://www.facebook.com/sharer/sharer.php?u=",
    media: "Messenger",
    id: "messenger",
    icon: <img src={messengerImg} alt="messenger" />,
  },
];

export const profileNavItems = [
  {
    hasDream: true,
    to: "/pills-currentDream-tab",
    eventKey: "first",
    label: "Dream",
  },
  { to: "/pills-activity", eventKey: "second", label: "Activity" },
  { to: "/pills-about-tab", eventKey: "third", label: "About" },
  { to: "/pills-donations-tab", eventKey: "fourth", label: "Fulfilled" },
  {
    hasDream: true,
    to: "/pills-fulfilledDreams-tab",
    eventKey: "fifth",
    label: "Received",
  },
];

export const missingData = [
  {
    type: MISSING_DATA_ENUM.USER_DREAM,
    title: "No dreams shared, yet!",
    content:
      "This Dreamer is still brewing their first vision. Encourage them to share their dream with the community!",
  },
  {
    type: MISSING_DATA_ENUM.USER_ACTIVITY,
    title: "No activity yet",
    content:
      "This Dreamer is just getting started! Check back soon to see their activity on the platform.",
  },
  {
    type: MISSING_DATA_ENUM.USER_FULFILLED,
    title: "This Dreamer hasn't backed any dreams yet",
    content: "Their first contribution could be just around the corner!",
  },
  {
    type: MISSING_DATA_ENUM.WING_DONATIONS,
    title: "No contributions to Wing Donations",
    content: "Their first contribution could be just around the corner!",
  },
  {
    type: MISSING_DATA_ENUM.RECEIVED_DONATIONS,
    title: "Be the first to support this vision!",
    content: "No fellow Dreamers have contributed yet.",
    button: "Fulfill their dream",
  },
  {
    type: MISSING_DATA_ENUM.RECEIVED_ANGEL_DONATIONS,
    title: "Be the first to support this vision!",
    content: "No Dream Angels have invested yet.",
    button: "Fulfill their dream",
  },
  {
    type: MISSING_DATA_ENUM.ANGEL_ACIVITY,
    title: "No activity yet",
    content:
      "This Dream Angel is just getting started! Check back soon to see their activity on the platform.",
  },
  {
    type: MISSING_DATA_ENUM.ANGEL_FULFILLED,
    title: "This Dream Angel hasn't backed any dreams yet",
    content: "Their first contribution could be just around the corner!",
  },
  {
    type: MISSING_DATA_ENUM.ANGEL_WING_FULFILLED,
    title: "No contributions to Wing Donations",
    content: "Their first contribution could be just around the corner!",
  },
];
