export const fulfillCheck = (allFulfilled, dream, userId) => {
  const mainUserId = localStorage.getItem("profileId");

  if (!mainUserId) {
    return true;
  }

  if (!dream || (dream && Object.keys(dream).length === 0)) {
    return false;
  }

  return allFulfilled?.find(
    (d) =>
      d === dream?.id ||
      dream?.amount === dream?.amountReceived ||
      mainUserId === userId
  );
};
