import { Card, ProgressBar } from "react-bootstrap";
import React, { useEffect } from "react";
import { done, toDo } from "../consts/SvgIcon";
import { useDispatch, useSelector } from "react-redux";
import { setCompletedProfile } from "../store/users";
import { useNavigate } from "react-router-dom";
import { UsersModule } from "src/types/redux";

const CompleteProfile = ({ user, dream, allFulfilled }) => {
  const completedData = [
    {
      toDo: "Add profile picture",
      completed: !!user?.mainImageUrl,
      path: "/user-profile",
    },
    {
      toDo: "Add profile background photo",
      completed: !!user?.coverImage?.url,
      path: "/user-profile",
    },
    {
      toDo: "Create a dream",
      completed: user?.role === "DREAMER",
      path: "/user-profile",
    },
    {
      toDo: "Add dream pictures",
      completed: !!dream?.images?.length,
      path: "/user-profile",
    },
    {
      toDo: "Start fulfilling others' dreams to realize yours",
      completed: !!allFulfilled?.length,
      path: "/pricing-page",
    },
  ];

  const profileCompleted = useSelector(
    (state: UsersModule) => state.usersModule
  ).profileCompleted;
  const dispatch = useDispatch();
  const progress = Math.round(
    (completedData.filter((el) => !!el?.completed).length * 100) /
      completedData.length
  );
  const navigate = useNavigate();
  const sortedCompletedData = [...completedData].sort((a, b) => {
    return Number(b.completed) - Number(a.completed);
  });
  useEffect(() => {
    if (!profileCompleted) {
      progress === 100 && dispatch(setCompletedProfile(true));
    }
  }, [progress]);

  return (
    <Card
      className="complete-container"
      style={{ display: `${profileCompleted ? "none" : ""}` }}
    >
      <span className="text-dark fw-bold fs-5 lh-1">Complete your profile</span>
      <div className="d-flex gap-2 align-items-center">
        <ProgressBar
          now={progress}
          className="black-hover post-progress-bar"
          variant={"gradient"}
        />
        <span className="fw-bold text-dark" style={{ fontSize: "12px" }}>
          {isNaN(progress) ? 0 : progress}%
        </span>
      </div>
      <div className="d-flex flex-column gap-20-complete lh-1">
        {sortedCompletedData.map((el) =>
          el?.completed ? (
            <div
              className="d-flex gap-1 align-items-center"
              style={{ color: "#A8A6A6" }}
              key={el.toDo}
            >
              <div>{done()}</div> <span>{el?.toDo}</span>
            </div>
          ) : (
            <div
              className="d-flex gap-1 align-items-center text-dark underline-name "
              key={el.toDo}
              onClick={() => navigate(el.path)}
            >
              <div>{toDo()}</div> <span>{el?.toDo}</span>
            </div>
          )
        )}
      </div>
      <div
        onClick={() => dispatch(setCompletedProfile(true))}
        className="dashboard-card-buttons custom-gradient"
        style={{ height: "40px", width: "100%" }}
      >
        <div className="dashboard-card-buttons-text">
          <span>Complete Later</span>
        </div>
      </div>
    </Card>
  );
};
export default CompleteProfile;
