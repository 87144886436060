import { InputCommentProps } from "src/types/comments-props";
import EmojiMenu from "../Partials/EmojiMenu";
import React from "react";
import { setActiveReply } from "src/store/action";
import { useDispatch } from "react-redux";

const InputComment = ({
  mainImage,
  setComment,
  currentUserComment,
  showEmojiPicker,
  emojiPicker,
  handleComment,
  addComment,
  postId,
  ...props
}: InputCommentProps) => {
  const imageUrl = typeof mainImage === "string" ? mainImage : mainImage?.url;
  const dispatch = useDispatch();

  return (
    <div
      className="d-flex flex-row align-items-center comment-horizontal gap-2 responsive-modal-input"
      {...props}
    >
      <div>
        <img
          alt={"userimg"}
          className="avatar-35 rounded-circle"
          src={imageUrl}
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="w-100">
        <form className="position-relative">
          <input
            id={postId || "focus"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(setActiveReply(""));
              }
              handleComment(e);
              showEmojiPicker(false);
            }}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            onFocus={() => {
              postId &&
                window.innerWidth > 500 &&
                document.getElementById(postId).scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
            }}
            value={currentUserComment}
            type="text"
            className="border-0 comments-input form-control "
            placeholder="Leave a comment.."
          />
          <EmojiMenu
            showEmojiPicker={showEmojiPicker}
            emojiPicker={emojiPicker}
            setText={setComment}
            sendMessage={addComment}
            currentUserComment={currentUserComment}
          />
        </form>
      </div>
    </div>
  );
};

export default InputComment;
