import React from "react";
import { Nav } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { profileNavItems } from "../../consts/items";
import { navbarResponsive } from "../../consts/breakpoints";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const ProfileNavBar = ({ setTabCallback, userData, profileType }) => {
  const getLabel = (label) => {
    if (label === "Dream") {
      return profileType === "owner"
        ? "My Dream"
        : `${userData.gender === "FEMALE" ? "Her" : "His"} Dream`;
    }
    if (label === "About") {
      return profileType === "owner"
        ? "About Me"
        : `${userData.gender === "FEMALE" ? "About Her" : "About Him"}`;
    }
    return label;
  };

  const hasDream = useRoleCheck(userData);

  const renderMobileNavItems = () =>
    profileNavItems.map(
      (item, index) =>
        (!item?.hasDream || item?.hasDream === hasDream) && (
          <Nav
            as="ul"
            variant="pills"
            className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 navbar-user-profile"
          >
            <Nav.Item as="li" className="col p-0" key={index}>
              <Nav.Link
                eventKey={item.eventKey}
                role="button"
                className="p-0 d-flex align-items-center justify-content-center navbar-item-box"
                onClick={() => setTabCallback(item.eventKey)}
              >
                <span>{getLabel(item.label)}</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )
    );
  const renderNavItems = () =>
    profileNavItems.map(
      (item, index) =>
        (!item.hasDream || item.hasDream === hasDream) && (
          <Nav.Item as="li" className="col p-0" key={index}>
            <Nav.Link
              eventKey={item.eventKey}
              role="button"
              className="p-0 d-flex align-items-center justify-content-center navbar-item-box"
              onClick={() => setTabCallback(item.eventKey)}
            >
              <span>{getLabel(item.label)}</span>
            </Nav.Link>
          </Nav.Item>
        )
    );

  return (
    <div className="user-tabing">
      {window.innerWidth < 500 && hasDream ? (
        <Carousel
          responsive={navbarResponsive}
          className="carousel-width slider-carousel"
          sliderClass="d-flex align-items-center"
          centerMode={true}
          infinite={false}
          arrows={false}
          additionalTransfrom={0}
          swipeable
        >
          {renderMobileNavItems()}
        </Carousel>
      ) : (
        <Nav
          as="ul"
          variant="pills"
          className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 navbar-user-profile"
        >
          {renderNavItems()}
        </Nav>
      )}
    </div>
  );
};

export default ProfileNavBar;
