import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../styles/Dashboard.css";
import "../../styles/CustomNavBarStyles.css";
import NotificationDropdown from "./NotificationDropdown";
import NavigationOffcanvas from "./NavigationOffCanvas";
import { balanceStar } from "../../consts/SvgIcon";
import SearchBar from "./SearchBar";
import MobileSearchBar from "./MobileSearchBar";
import RandomModal from "./RandomModal";
import PricingModal from "../../pages/Pricing/PricingModal";
import LeaveFeedback from "./LeaveFeedback";
import { UsersModule } from "../../types/redux";

const NavBarComponent = () => {
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const [allUsers, setAllUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [customModals, setCustomModal] = useState("");
  const [randomDonationId, setRandomDonationId] = useState(null);
  const [feedback, setFeedback] = useState(false);
  const getModal = () => {
    if (customModals === "randomModal") {
      return (
        <>
          {randomDonationId && (
            <RandomModal
              customModals={customModals}
              setCustomModal={setCustomModal}
              randomDonationId={randomDonationId}
            />
          )}
        </>
      );
    }
    if (customModals === "priceModal") {
      return (
        <PricingModal
          customModals={customModals}
          setCustomModal={setCustomModal}
        />
      );
    }
  };
  return (
    <div className="iq-top-navbar ps-1 pe-1">
      <div>{getModal()}</div>
      <LeaveFeedback setFeedback={setFeedback} feedback={feedback} />
      <div className="iq-navbar-custom m-0">
        <Navbar
          expand="md"
          variant="light"
          className="p-0 d-flex justify-content-between align-items-center flex-nowrap"
          style={{ height: "59px" }}
        >
          <div className="iq-navbar-logo ms-2" style={{ width: "300px" }}>
            <Link to="/newsfeed" className="d-flex justify-content-start">
              <div className="fa-logo-icon" />
            </Link>
          </div>
          <SearchBar
            user={user}
            allUsers={allUsers}
            setAllUsers={setAllUsers}
            inputValue={inputValue}
            setInputValue={setInputValue}
            className="desktop-search"
            style={{ width: "300px" }}
          />
          <Nav
            as="ul"
            className="navbar-list d-flex justify-content-end align-items-center gap-2"
            style={{ width: "300px" }}
          >
            <MobileSearchBar
              user={user}
              allUsers={allUsers}
              setAllUsers={setAllUsers}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            <NotificationDropdown />
            <div className="user-balance-nav">
              <h5>Balance</h5>
              <div className="user-balance-footer">
                <span>{user?.balance ? user?.balance : 0}</span>{" "}
                <span>{balanceStar()}</span>
              </div>
            </div>
            <NavigationOffcanvas
              user={user}
              setCustomModal={setCustomModal}
              setRandomDonationId={setRandomDonationId}
              setFeedback={setFeedback}
            />
          </Nav>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBarComponent;
