import React, { useEffect, useState } from "react";
import Card from "../../../components/Partials/Card";
import { useNavigate } from "react-router-dom";
import "../../../styles/Dashboard.css";
import defaultImage from "../../../assets/images/defaultUser.png";
import { useDispatch, useSelector } from "react-redux";
import ShareButton from "../../../components/Buttons/ShareButton";
import ShareModal from "../../../components/Partials/ShareModal";
import moment from "moment";
import { SOCIALS_ENUM } from "../../../consts/socialsEnum";
import NewsfeedSocialInteraction from "../../../components/Partials/NewsfeedSocialInteraction";
import { User, UsersModule } from "src/types/redux";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "src/components/Tooltip/TooltipDetails";
import { ActivityType } from "src/components/Profile/Activity";
import { shareTo } from "src/utils/share";
import AngelCommentsFeed from "src/components/Comments/AngelCommentsFeed";
import { updateAngelLikes } from "src/store/action";
import { shareType } from "src/consts/notifications";

type DreamAngelTypes = {
  post;
  onClick?: (id: string) => void;
  user?: User;
  type?: string;
  timestamp?: string;
  isFulfilled: (id: string) => void;
  reply?;
};

const DreamAngel = ({
  post,
  onClick,
  user,
  type,
  timestamp,
  isFulfilled,
  reply,
}: DreamAngelTypes) => {
  const [shareModal, setShareModal] = useState(false);
  const dispatch = useDispatch();
  const [link, setLink] = useState(process.env.REACT_APP_BASE_URL);
  const mainUser = useSelector((state: UsersModule) => state.usersModule).user;
  const navigate = useNavigate();
  const tooltipKey = generateTooltipKey(post?.user?.id);

  useEffect(() => {
    const likesCount = post.likedNewsFeedByUsers.length;
    const likedBy = post.likedNewsFeedByUsers.map((item) => item.user.id);
    const userLiked = likedBy.includes(localStorage.getItem("profileId"));
    dispatch(updateAngelLikes(post?.id, likesCount, userLiked, likedBy));
  }, [post]);

  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;

  const share = () => {
    setLink(`${link}/dream-angel/${post?.id}`);
    shareTo({
      link: `${link}/dream-angel/${post?.id}`,
      type: shareType.SHARE_DREAM_ANGEL,
      replyUserId: mainUser?.id,
      dreamAngelId: post?.dreamAngelId,
      setShareModal,
    });
  };

  return (
    <Card className="rounded-3 gap-card shadow m-0 pb-0">
      <Card.Body className="p-0 gap-post">
        <ShareModal
          customModals={shareModal}
          setCustomModal={setShareModal}
          sharedLink={link}
          post={post}
          mainUser={mainUser}
        />
        {type ? (
          <ActivityType
            contributor={user}
            type={type}
            timestamp={timestamp}
            reply={reply}
          />
        ) : (
          <></>
        )}
        <div className="d-flex align-items-center flex-row justify-content-between gap-2">
          <span className="time-ago">
            {moment(post?.createdAt)?.fromNow(true)} ago
          </span>
          <div className="buttons-container" style={{ width: "72px" }}>
            <ShareButton width="72px" onClick={() => share()} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center gap-2 w-100 gap-card">
          <div
            className="gradient-border-container"
            onClick={() => {
              onClick(type ? null : post?.id);
              navigate(`/dashboard/public-page/${post?.user?.id}`);
            }}
          >
            <img
              src={post?.user?.mainImageUrl || defaultImage}
              alt="profile"
              className="angel-dream-post-avatar"
            />
          </div>
          <div className="gradient-bg">
            <span className="text-dark">Dream Angel</span>
          </div>
          <span
            className="user-name cursor-pointer"
            data-tooltip-id={tooltipKey}
            onClick={() => navigate(`/angel/${post?.id}`)}
          >
            {`${post?.user?.firstName || "No name"} 
                  ${post?.user?.lastName || ""}`}
          </span>
          <TooltipDetails
            userId={post?.user?.id}
            tooltipKey={tooltipKey}
            isFulfilled={isFulfilled}
            allFulfilled={allFulfilled}
          />
          <span className="time-ago">{post?.title}</span>
        </div>
        <NewsfeedSocialInteraction
          post={post}
          type={SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL}
        ></NewsfeedSocialInteraction>
        <AngelCommentsFeed
          user={mainUser}
          post={post}
          isFulfilled={isFulfilled}
        />
      </Card.Body>
    </Card>
  );
};

export default DreamAngel;
