export const dream = [
  {
    firstName: "Olia",
    lastName: "Tri",
    title: "I would love to have a vacation in Greece",
    progress: 38,
    coverImage: "/images/landingUsers/oliaTriCover.jpg",
    backgroundImage: "/images/landingUsers/oliaTriBackground.jpg",
    id: 1,
    alt: "Olia dreams of going on vacation in Greece on a stony beach",
  },
  {
    firstName: "Denis",
    lastName: "Ceban",
    title: "I dream of an Ipad Pro 11 inch!",
    progress: 33,
    coverImage: "/images/landingUsers/denisCebanCover.jpg",
    backgroundImage: "/images/landingUsers/denisCebanBackground.jpg",
    id: 2,
    alt:"Denis - an engineer with a rich imagination dreams of a tablet for sketches"
  },
  {
    firstName: "Robert",
    lastName: "Starling",
    title: "I would love to have a new pair of Nike Air",
    coverImage: "/images/landingUsers/robertStarlingCover.jpg",
    backgroundImage: "/images/landingUsers/robertStarlingBackground.jpg",
    progress: 56,
    id: 3,
    alt: "Robert Starling’s profile card with a picture of his dream of Nike Air boots and live progress bar"
  },
  {
    firstName: "Demi",
    lastName: "C",
    title: "I dream of traveling to Bali",
    coverImage: "/images/landingUsers/demiCCover.jpg",
    backgroundImage: "/images/landingUsers/demiCBackground.jpg",
    progress: 41,
    id: 4,
    alt:"Demi dreams of meeting the sunrise in Bali"
  },
  {
    firstName: "Iulia",
    lastName: "R",
    title:
      "I want a MacBook Air which will assist for my studies at Harvard. As I will start this incredible journey, your support in helping me acquire this essential tool would make a hell of a difference 🙏🏻🙏🏻🙏🏻",
    coverImage: "/images/landingUsers/iuliaRCover.png",
    backgroundImage: "/images/landingUsers/iuliaRBackground.png",
    progress: 22,
    id: 5,
    alt: "Iulia want a MacBook Air which will assist for my studies at Harvard"
  },
  {
    firstName: "Elize",
    lastName: "Star",
    title:
      "I would love to travel the world, exploring diverse places and cultures.",
    coverImage: "/images/landingUsers/elizeStarCover.jpg",
    backgroundImage: "/images/landingUsers/elizeStarBackground.jpg",
    progress: 67,
    id: 6,
    alt:"Elize love to travel the world, exploring diverse places and cultures"
  },
];
