import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../../../assets/images/defaultUser.png";
import "../../../styles/BirthdaySlider.css";
import coverImg from "../../../assets/images/coverImg.png";
import React from "react";
import { getUserById } from "src/services/api";
import { useQuery } from "@tanstack/react-query";
import { UserDto } from "src/services/api/data-contracts";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "src/components/Tooltip/TooltipDetails";

const AngelReceivedCard = ({ received }) => {
  const navigate = useNavigate();
  const tooltipKey = generateTooltipKey(received?.user?.id);

  const { data: angelDetails, isLoading } = useQuery<UserDto>({
    queryKey: ["angelDetails", received?.user?.id],
    queryFn: () => getUserById(received?.user?.id).then((res) => res.data),
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <div
      className="profile-received-card shadow-card rounded-3"
      style={{ height: "278px" }}
    >
      <div className="angel-received-card-header">
        <img
          src={received?.user?.coverImage?.url || coverImg}
          alt="profile-img"
          className="angel-received-card-header"
        />
      </div>
      <Link
        to={`/dashboard/public-page/${received?.user?.id}`}
        style={{ height: "0px" }}
      >
        <div className="bg-white border-radius-img angel-received-img-mg angel-received-img-border">
          <img
            src={received?.user?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="bg-white angel-received-avatar"
          />
        </div>
      </Link>
      <div className="angel-received-main-box">
        <div
          className="d-flex align-items-center"
          style={{ flexDirection: "column" }}
        >
          <Link to={`/dashboard/public-page/${received?.user?.id}`}>
            <span
              className="profile-received-username-box my-name-span lh-sm name-overflow-cards text-underline"
              data-tooltip-id={tooltipKey}
            >
              {`${received?.user?.firstName || "No Name"} ${
                received.user?.lastName || ""
              }`}
            </span>
          </Link>
          <div className="profile-angel-badge">
            <p>Dream Angel</p>
          </div>
          <TooltipDetails userId={received?.user?.id} tooltipKey={tooltipKey} />
        </div>
        <div className="received-angel-fulfilled-box">
          <p>Fulfilled</p>
          <div className="fw-bold">
            {angelDetails?.donations?.length || "0"} Dreams
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div
            className="dashboard-card-buttons custom-gradient"
            style={{ width: "100%", height: "40px" }}
            onClick={() =>
              navigate(`/dashboard/public-page/${received?.userId}`)
            }
          >
            <div className="profile-received-buttons-text">
              <span>View Profile</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AngelReceivedCard;
