export const MISSING_DATA_ENUM = {
  USER_DREAM: "USER_DREAM",
  USER_ACTIVITY: "USER_ACTIVITY",
  USER_FULFILLED: "USER_FULFILLED",
  WING_DONATIONS: "WING_DONATIONS",
  RECEIVED_DONATIONS: "RECEIVED_DONATIONS",
  RECEIVED_ANGEL_DONATIONS: "RECEIVED_ANGEL_DONATIONS",
  ANGEL_ACIVITY: "ANGEL_ACIVITY",
  ANGEL_FULFILLED: "ANGEL_FULFILLED",
  ANGEL_WING_FULFILLED: "ANGEL_WING_FULFILLED",
};
