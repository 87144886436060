import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../assets/images/defaultUser.png";
import TooltipDetails from "./Tooltip/TooltipDetails";
import { useSelector } from "react-redux";
import { UsersModule } from "../types/redux";
import { generateTooltipKey } from "../utils/gerenateTooltipKey";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const FulfilledBy = ({ post, contributor, text, onClick, isFulfilled }) => {
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const navigate = useNavigate();
  const tooltipKey = generateTooltipKey(contributor?.id);
  const hasDream = useRoleCheck(contributor);

  return (
    <div className="d-flex justify-content-between align-items-center fulfilled-by-container">
      <div
        className="d-flex align-items-center gap-2 cursor-pointer user-image"
        onClick={() => {
          onClick(post.id);
          navigate(`/dashboard/public-page/${contributor.id}`);
        }}
      >
        <div className={`${!hasDream ? "gradient-border" : ""}`}>
          <img
            src={contributor?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="avatar-35 rounded-circle cursor-pointer"
            style={{ objectFit: "cover" }}
          />
        </div>
        <div
          className="d-flex fuflilled-by-name-box"
          data-tooltip-id={tooltipKey}
        >
          <div className="fulfilled-by-name-badge-box">
            <span className="user-name">
              {`${contributor?.firstName || "No name"} 
                        ${contributor?.lastName || ""}`}
            </span>
            {!hasDream && (
              <div className="tooltip-angel-badge" style={{ marginTop: 0 }}>
                Dream Angel
              </div>
            )}
          </div>

          <span className="time-ago">{text}</span>
        </div>
      </div>
      <TooltipDetails
        userId={contributor?.id}
        isFulfilled={isFulfilled}
        allFulfilled={allFulfilled}
        tooltipKey={tooltipKey}
      />
      <span className="time-ago">
        {moment(post?.createdAt)?.fromNow(true)} ago
      </span>
    </div>
  );
};
export default FulfilledBy;
