import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Image, ProgressBar, Row } from "react-bootstrap";
import Card from "../../components/Partials/Card";
import { useDispatch, useSelector } from "react-redux";
import CashOut from "../../components/Partials/CashOut";
import {
  fulfill,
  getAllCharities,
  getAllProfileDonations,
  getAllProfileWingDonations,
  getDreamById,
  getDreamDonations,
  getPayOutsByDreamId,
  getPosts,
  getProfileDream,
  getUser,
  shareNotification,
  wingDonation,
} from "../../services/api";
import {
  setAllDreamPayments,
  setAllProfileFulfilled,
  setAllProfileWing,
  setBgLoader,
  setDream,
  setPosts,
  setProfileReceived,
  setSpliceDreams,
  setUser,
} from "../../store/users";
import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/defaultUser.png";
import coverImg from "../../assets/images/coverImg.png";
import DreamMakersModal from "../../components/DreamMakersModal";
import PricingModal from "../Pricing/PricingModal";
import CompleteProfile from "../../components/CompleteProfile";
import GradientButton from "../../components/Buttons/GradientButton";
import Sponsored from "./Posts/Sponsored";
import DreamAngel from "./Posts/DreamAngel";
import Dream from "./Posts/Dream";
import Charity from "./Posts/Charity";
import loaderGif from "../../assets/images/page-img/page-load-loader.gif";
import InfiniteScroll from "react-infinite-scroller";
import Sliders from "../../components/Sliders/Sliders";
import { UsersModule } from "src/types/redux";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import { DreamDto } from "src/services/api/data-contracts";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

const NewsFeed = () => {
  const notify = (message) => toast.success(message);
  const notifyWarning = (message) => toast.warning(message);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const dream = useSelector((state: UsersModule) => state.usersModule)
    .dream as DreamDto;
  const profileReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).profileReceived;
  const posts = useSelector((state: UsersModule) => state.usersModule).posts;
  const [wingDonations, setWingDonations] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customModals, setCustomModal] = useState("");
  const [show, setShow] = useState(false);
  const [dreamId, setDreamId] = useState("");
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const wingDonationsProfile = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileWing;
  const allPayments = useSelector(
    (state: UsersModule) => state.usersModule
  ).allDreamPayments;
  const [_hasNextPage, setHasNextPage] = useState(true);
  const [cashOut, openCashOut] = useState(false);
  const lastDream = localStorage.getItem("dreamId");
  const charityType = ["CHARITY", "FULFILL_WING_DONATION"];
  const dreamType = ["DREAM", "FULFILL_DONATION", "SAINT_DREAMER"];

  const fetchPosts = useCallback(async ({ pageParam = 1 }) => {
    return getPosts(pageParam);
  }, []);

  const {
    data: newsFeedPosts,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["get-news-feed-posts"],
    getNextPageParam: (last) =>
      last.data.meta.hasNextPage ? last.data.meta.page + 1 : undefined,
    queryFn: fetchPosts,
    staleTime: 30000,
    cacheTime: 30000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useQuery({
    queryKey: ["get-user-profile"],
    queryFn: async () => {
      const userData = await getUser();
      if (userData) {
        localStorage.setItem("profileId", userData.data.id);
      }
      return userData;
    },
  });

  useEffect(() => {
    dispatch(setPosts([]));
    if (!isLoading && newsFeedPosts) {
      const newsFeedData = newsFeedPosts.pages
        .map(({ data }) => data.results)
        .flat();
      dispatch(setPosts(newsFeedData));
      setHasNextPage(
        newsFeedPosts.pages[newsFeedPosts.pages.length - 1]?.data.meta
          ?.hasNextPage
      );

      if (!!dream?.id) {
        getPayOutsByDreamId(dream?.id).then((res) => {
          dispatch(setAllDreamPayments(res?.data));
        });
        getDreamDonations(dream?.id, 1).then((response) => {
          dispatch(setProfileReceived(response?.data));
        });
      }

      if (localStorage.getItem("lastAccessedPost")) {
        setTimeout(() => {
          document
            .getElementById(localStorage.getItem("lastAccessedPost"))
            .scrollIntoView({ behavior: "smooth" });
          localStorage.removeItem("lastAccessedPost");
        }, 2000);
      }
    }
  }, [isLoading, newsFeedPosts, dream?.id]);

  const loadMore = useCallback(() => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetchingNextPage]);

  const getAllData = useCallback(() => {
    getAllCharities().then((response) => {
      setWingDonations(response?.data?.results);
    });
    getProfileDream().then((res) => {
      dispatch(setDream(res.data));
    });
    getAllProfileDonations().then((res) => {
      dispatch(setAllProfileFulfilled(res?.data));
    });
    getAllProfileWingDonations().then((res) => {
      dispatch(setAllProfileWing(res.data));
    });
  }, []);

  useEffect(() => {
    if (lastDream) {
      navigate(`/dream-page/${lastDream}`);
      localStorage.removeItem("dreamId");
    }
    if (
      !!user.id &&
      !user?.firstName &&
      window.location.pathname !== "/user-profile/edit-form"
    ) {
      navigate("/user-profile/edit-form");
    }
    dispatch(setBgLoader(true));
    getAllData();
  }, []);

  const isFulfilled = (id) => {
    if (!user.balance) {
      setDreamId(id);
      setCustomModal("priceModal");
    } else {
      fulfill(id).then((res) => {
        if (!res) return;
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getDreamById(id).then((res) => {
          dispatch(setSpliceDreams(res.data));
        });
        getUser().then(({ data }) => {
          dispatch(setUser(data));
        });
        getAllProfileDonations().then((res) => {
          dispatch(setAllProfileFulfilled(res?.data));
        });
      });
    }
  };

  const handleWingDonation = (id) => {
    if (!user.balance) {
      setCustomModal("priceModal");
    } else {
      wingDonation(id).then((res) => {
        if (!res) return;
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getUser().then((response) => {
          dispatch(setUser(response?.data));
        });
        getAllProfileWingDonations().then((res) => {
          dispatch(setAllProfileWing(res.data));
        });
      });
    }
  };
  const progress = calculateDreamProgress(dream as DreamDto);
  const leftSideCardList = wingDonations?.map((elem, index) => {
    return (
      <Card key={index} className="p-3 rounded-3 shadow m-0">
        <Card.Body className="p-0">
          <div className="media-height d-flex flex-column align-items-center justify-content-start gap-2">
            <div className="d-flex justify-content-start p-0 w-100 user-image">
              <Link to={`/wing/${elem?.id}`} className="m-0 p-0">
                <div className="mb-1 d-flex justify-content-center align-items-center">
                  <Image
                    className="wing-avatar-notice"
                    src={
                      elem?.images?.find((image) => image?.isMain === true)
                        ?.url || coverImg
                    }
                    alt="user image"
                  />
                </div>
              </Link>
              <div className="d-flex flex-column justify-content-start ms-2 cursor-pointer">
                <h5
                  className="user-name wing-title mb-1"
                  onClick={() => navigate(`/wing-donation/${elem?.id}`)}
                >
                  {elem.title}
                </h5>
                <span className="my-span lh-1 text-dark">
                  {elem?.description}
                </span>
              </div>
            </div>
            <div
              className="dashboard-card-buttons custom-gradient w-100"
              onClick={() =>
                wingDonationsProfile?.find((d) => d === elem?.id)
                  ? navigate(`/wing-donation/${elem?.id}`)
                  : handleWingDonation(elem?.id)
              }
            >
              <div className="dashboard-card-buttons-text">
                <span>
                  {wingDonationsProfile?.find((d) => d === elem?.id)
                    ? "View"
                    : "Donate"}
                </span>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  });

  const handleOnClickPost = (id: string) => {
    localStorage.setItem("lastAccessedPost", id);
  };

  const CheckCashOut = () => {
    if (dream?.amount / 2 <= allFulfilled?.length) {
      openCashOut(true);
    } else {
      toast.dismiss();
      toast.clearWaitingQueue();
      notifyWarning("You have to fulfill half from your dream amount!");
    }
  };
  return (
    <Container fluid className="m-0 p-0">
      <Row className="m-0 main-row-newsfeed">
        <Col
          sm={12}
          md={4}
          lg={4}
          xl={3}
          xxl={3}
          className="padding-left-card left-side-overflow-post "
        >
          <div className="d-flex flex-column gap-4">
            {cashOut && (
              <CashOut
                cashOut={cashOut}
                openCashOut={openCashOut}
                dreamId={dream?.id}
              />
            )}
            <Card className="rounded-3 shadow left-card m-0">
              <div
                style={{
                  backgroundImage: `url(${
                    user?.coverImage?.avatarUrl || coverImg
                  })`,
                  backgroundSize: "cover",
                }}
                className=" profile-img d-flex justify-content-center bg-card align-items-center"
              >
                <div>
                  <Link
                    to={"/user-profile"}
                    className="d-flex justify-content-center align-items-center bg-white avatar-95 "
                    style={{ borderRadius: "50%", border: "2px solid white" }}
                  >
                    <img
                      src={user?.mainImageUrl || defaultImage}
                      alt="userimg"
                      className="avatar-90 img-fluid"
                    />
                  </Link>
                </div>
              </div>
              <div className="p-3 text-center">
                <h6
                  className=" fw-bold text-dark cursor-pointer m-0 fs-5"
                  onClick={() => navigate("/user-profile")}
                >
                  {user?.firstName || "No name"} {user?.lastName}
                </h6>
                <div className="d-flex justify-content-center">
                  <p
                    style={{ fontSize: "14px", color: "#A8A6A6" }}
                    className="mb-0  lh-1 my-span"
                  >
                    {dream?.title}
                  </p>
                </div>
              </div>
              {dream?.id ? (
                <div>
                  <div className="d-flex justify-content-between m-3 mb-1 mt-0">
                    <span style={{ fontSize: "13px" }} className="text-dark">
                      Scope
                    </span>
                    <span style={{ fontSize: "12px", color: "black" }}>
                      <b>{progress || "0"}%</b>
                    </span>
                  </div>
                  <ProgressBar
                    onClick={() => setShow((prev) => !prev)}
                    now={progress}
                    className="ms-3 me-3 black-hover card-progress-bar"
                    variant={"gradient"}
                  />
                  <DreamMakersModal
                    donations={profileReceived}
                    show={show}
                    setShow={setShow}
                    dreamId={dream.id}
                  />
                  <div
                    className="d-flex justify-content-between ms-3 me-3 mt-1 mb-3 text-dark"
                    style={{ fontSize: "12px" }}
                  >
                    <span>
                      Received: <b>{profileReceived?.meta?.itemCount || "0"}</b>
                    </span>
                    <span>
                      Fulfilled: <b>{allFulfilled?.length || "0"}</b>
                    </span>
                  </div>
                  {dream?.status === "COMPLETED" && !allPayments?.length ? (
                    <div className="d-flex justify-content-center mb-3 ms-3 me-3">
                      <GradientButton
                        text="Cash Out"
                        minWidth="100%"
                        onClick={() => CheckCashOut()}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {dream?.status === "COMPLETED" &&
                  allPayments?.length &&
                  allPayments?.[allPayments?.length - 1]?.status !==
                    "FAILED" ? (
                    <div className="d-flex justify-content-center mb-3 ms-3 me-3">
                      <GradientButton
                        text="Create a new dream!"
                        minWidth="100%"
                        onClick={() => navigate("/user-profile/create-dream")}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-center ms-3 me-3">
                  <GradientButton
                    text="Create your dream!"
                    minWidth="100%"
                    onClick={() => navigate("/user-profile/create-dream")}
                  />
                </div>
              )}
              {!dream?.id ? (
                <div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    <h6 style={{ cursor: "default" }}>
                      Fulfilled {allFulfilled?.length || "0"}
                    </h6>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Card>
            <CompleteProfile
              user={user}
              dream={dream}
              allFulfilled={allFulfilled}
            />
            <div className="left-card d-flex flex-column gap-2 ">
              <div className="d-flex gap-1 w-100 text-dark">
                <span style={{ color: "#4F4F4F" }} className="fw-bold">
                  Wing donations
                </span>
              </div>
              <div className="left-card d-flex flex-column gap-2">
                {leftSideCardList}
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={6} xl={7} lg={8} md={8} sm={12} className="margin-posts p-0">
          <InfiniteScroll
            loadMore={() => loadMore()}
            hasMore={hasNextPage}
            useWindow={true}
            loader={
              <div className="d-flex justify-content-center w-100" key="loader">
                <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
              </div>
            }
          >
            {!isLoading && (
              <div className="padding-post post-gap">
                {posts?.map((post, index) => (
                  <React.Fragment key={post?.id}>
                    <div id={post.id}>
                      {/* {post?.type === "SPONSORED" && (
                        <Sponsored dream={post} onClick={handleOnClickPost} />
                      )} */}
                      {post?.type === "DREAM_ANGEL" && (
                        <DreamAngel
                          post={post}
                          onClick={handleOnClickPost}
                          isFulfilled={isFulfilled}
                        />
                      )}
                      {charityType?.includes(post?.type) && (
                        <Charity
                          isFulfilled={handleWingDonation}
                          post={post}
                          onClick={handleOnClickPost}
                        />
                      )}
                      {dreamType?.includes(post?.type) && (
                        <Dream
                          isFulfilled={isFulfilled}
                          post={post}
                          onClick={handleOnClickPost}
                        />
                      )}
                      <Sliders
                        isFulfilled={isFulfilled}
                        index={index}
                      ></Sliders>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </InfiniteScroll>
        </Col>
        <PricingModal
          customModals={customModals}
          setCustomModal={setCustomModal}
          dreamId={dreamId}
        />
      </Row>
    </Container>
  );
};

export default NewsFeed;
