import React, { useEffect, useRef, useState } from "react";
import { createComments, getComments } from "../../services/api";
import InfiniteScroll from "react-infinite-scroller";
import Comment from "../Comments/Comment";
import loaderGif from "../../assets/images/page-img/page-load-loader.gif";
import InputComment from "./InputComment";
import { CommentsNewProps } from "src/types/comments-props";
import { commentType } from "src/consts/commentsTypeEnum";

let page2d = 1;
let loader = false;

const CommentsNew = ({
  user,
  mainImg,
  dream,
  dreamId,
  page,
  isFulfilled,
}: CommentsNewProps) => {
  const [seeAllComments, setSeeAllComments] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [hasMore, setHasMore] = useState(Boolean);
  const scrollref = useRef(null);
  const [commentsNumber, setCommentsNumber] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [emojiPicker, showEmojiPicker] = useState(false);

  useEffect(() => {
    if (dreamId) {
      getComments(dreamId, page2d, 10).then((res) => {
        setCommentData(res?.data?.results || []);
        setHasMore(res?.data?.meta?.hasNextPage || false);
        setCommentsNumber(res?.data?.meta?.itemCount || 0);
      });
      page2d = 1;
    }
  }, [dreamId]);

  useEffect(() => {
    return () => {
      page2d = 1;
      setCommentData([]);
    };
  }, [dreamId]);

  const loadMore = () => {
    if (commentData?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        if (dreamId) {
          getComments(dream?.id, page2d, 10).then((response) => {
            setCommentData((prevState) => [
              ...prevState?.map((el) => ({ ...el })),
              ...response.data.results?.map((el) => ({ ...el })),
            ]);
            setHasMore(response?.data?.meta?.hasNextPage);
            setCurrentPage(currentPage + 1);
          });
        }
        loader = false;
      }
    }
  };

  const sendMessage = () => {
    if (commentInput?.length) {
      createComments({ message: commentInput, dreamId: dream?.id }).then(() => {
        setCommentInput("");
        getComments(dreamId, 1, 10).then((res) => {
          setCommentData(res?.data?.results);
          setHasMore(res?.data?.meta?.hasNextPage);
          let scrollDiv = document.getElementById("dream-comment-scroll");
          scrollDiv.scrollTop = 0;
        });
      });
    }
  };
  const handleComment = (event) => {
    setCommentInput(event.target.value);
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };
  const commentsList = commentData
    ?.filter((el) => el.parentId === null)
    .map((elem, index) => {
      return (
        <div key={`comment-${index}-${elem?.id}`}>
          <Comment
            user={user}
            dreamId={dream?.id}
            type={commentType.dream}
            elem={elem}
            isFulfilled={isFulfilled}
          />
        </div>
      );
    });

  return (
    <>
      <div style={{ height: "auto" }} className="p-0 comment-area">
        <ul className="post-comments p-0 m-0 container-fluid">
          {page !== "about" && (
            <div className="dream-photo-title">Comments</div>
          )}
          <ul
            style={{ minHeight: "0", maxHeight: "400px" }}
            className="overflow-auto ps-0"
            id="dream-comment-scroll"
            ref={scrollref}
          >
            <InfiniteScroll
              pageStart={currentPage}
              loadMore={() => loadMore()}
              hasMore={hasMore}
              getScrollParent={() => scrollref.current}
              useWindow={false}
              threshold={10}
            >
              {commentsList}

              {loader && (
                <div className="d-flex justify-content-center w-100">
                  <img
                    src={loaderGif}
                    alt="loader"
                    style={{ height: "70px" }}
                  />
                </div>
              )}
            </InfiniteScroll>
          </ul>
        </ul>

        {commentsList.length ? (
          <div style={{ margin: "10px 0 20px" }}>
            <span
              onClick={() => {
                !!commentsList?.length && setSeeAllComments(!seeAllComments);
              }}
              style={{
                color: "black",
                lineHeight: "17.86px",
                letterSpacing: "-0.04em",
              }}
              className="span-hover-underline cursor-pointer"
            >
              {seeAllComments ? "Hide" : `Read all ${commentsNumber} comments`}
            </span>
          </div>
        ) : (
          <></>
        )}
        <InputComment
          className="d-flex align-items-center gap-2"
          currentUserComment={commentInput}
          setComment={setCommentInput}
          emojiPicker={emojiPicker}
          showEmojiPicker={showEmojiPicker}
          mainImage={mainImg}
          handleComment={(e) => handleComment(e)}
          addComment={() => sendMessage()}
        />
      </div>
    </>
  );
};

export default CommentsNew;
