import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UsersModule } from "src/types/redux";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import { handleFulfillment } from "src/utils/isFulfilled";
import TooltipDetails from "../Tooltip/TooltipDetails";
import defaultImage from "../../assets/images/defaultUser.png";
import coverImage from "../../assets/images/coverImg.png";
import { setPublicReceived } from "src/store/users";
import { getPublicDreamDonations } from "src/services/api";
import DreamMakersModal from "../DreamMakersModal";
import { fulfillCheck } from "src/utils/fulfillCheck";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";

export default function UsersFulfilledCard({ dream }) {
  const profileId = localStorage.getItem("profileId");
  const progress = calculateDreamProgress(dream?.dreams[0]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;

  const [dreamId, setDreamId] = useState("");
  const [customModals, setCustomModal] = useState("");
  const [show, setShow] = useState(false);

  const tooltipKey = generateTooltipKey(dream?.id);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;

  const isFulfilled = (id) => {
    handleFulfillment(id, user, setDreamId, setCustomModal, dispatch);
  };

  const isDreamFulfilled = fulfillCheck(
    allFulfilled,
    dream?.dreams[0],
    dream?.id
  );

  const handleNavigation = () => {
    if (!profileId) {
      navigate("/login");
    } else if (isDreamFulfilled) {
      navigate(`/dashboard/public-page/${dream?.id}`);
    } else {
      isFulfilled(dream?.dreams[0].id);
    }
  };

  const progressClick = () => {
    dispatch(setPublicReceived({}));
    setShow((prev) => !prev);
    getPublicDreamDonations(dream?.dreams[0]?.id, 1).then((res) => {
      dispatch(setPublicReceived(res?.data));
    });
  };

  return (
    <div className="public-dream-card shadow-card rounded-3 d-flex flex-column">
      <div
        className="profile-bg-card d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: `url(${dream?.coverImage?.url || coverImage})`,
        }}
      >
        <div className="public-dream-card-img-box">
          <div className="d-flex justify-content-center align-items-center bg-white rounded-circle">
            <img
              src={dream?.mainImageUrl || defaultImage}
              alt="profile-img"
              className="public-dream-user-img-card rounded-circle"
            />
          </div>
        </div>
      </div>
      <div className="public-dream-card-box text-center d-flex flex-column">
        <div className="public-dream-card-name-desc-box">
          <span
            className="my-title public-dream-card-user-name"
            data-tooltip-id={tooltipKey}
            onClick={() =>
              navigate(
                profileId ? `/dashboard/public-page/${dream?.id}` : `/login`
              )
            }
          >
            {`${dream?.firstName || "No Name"} 
                        ${dream?.lastName || ""}`}
          </span>
          <span className="my-span lh-sm public-dream-card-user-dream ">
            {dream?.dreams[0]?.title || " "}
          </span>
        </div>
        <div className="d-flex flex-column public-dream-card-fulfill-container">
          <div className="w-100 d-flex justify-content-between">
            <span className="public-dream-card-fulfilled">Scope</span>
            <span className="fw-bold public-dream-card-fulfilled">
              {progress || 0}%
            </span>
          </div>
          <ProgressBar
            className="card-progress-bar black-hover"
            now={progress}
            variant={"gradient"}
            onClick={() => (profileId ? progressClick() : navigate("/login"))}
          />
        </div>
        <div
          className="dashboard-card-buttons custom-gradient"
          style={{ width: "100%", height: "40px" }}
          onClick={handleNavigation}
        >
          <div className="dashboard-card-buttons-text">
            <span>
              {isDreamFulfilled
                ? "View Profile"
                : `Fulfill ${dream?.gender === "FEMALE" ? "Her" : "His"} Dream`}
            </span>
          </div>
        </div>
        {publicReceived?.results?.length ? (
          <DreamMakersModal
            donations={publicReceived}
            show={show}
            setShow={setShow}
            dreamId={dream?.dreams[0].id}
            card={true}
          />
        ) : (
          <></>
        )}
      </div>
      {profileId ? (
        <TooltipDetails
          userId={dream?.id}
          isFulfilled={isFulfilled}
          allFulfilled={allFulfilled}
          tooltipKey={tooltipKey}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
