const getStyle = (oElm, strCssRule) => {
  var strValue = "";
  if (oElm && document?.defaultView && document.defaultView.getComputedStyle) {
    strValue = document.defaultView
      .getComputedStyle(oElm, "")
      ?.getPropertyValue(strCssRule);
  } else if (oElm?.currentStyle) {
    strCssRule = strCssRule?.replace(/\-(\w)/g, function (strMatch, p1) {
      return p1?.toUpperCase();
    });
    strValue = oElm?.currentStyle[strCssRule];
  }
  return strValue;
};

export const calculateSlidersWidth = () => {
  const leftSide = document?.querySelector(".left-side-overflow-post");
  const postSide = document?.querySelector(".padding-post");

  if (!leftSide || !postSide) {
    return 0;
  }

  const leftSideWidth = leftSide.getBoundingClientRect().width;
  const postSideWidth = postSide.getBoundingClientRect().width;
  const windowWidth = window.innerWidth;

  const postSidePadding = parseInt(
    getStyle(postSide, "padding-left").slice(0, 2)
  );

  const response =
    (windowWidth - leftSideWidth - postSideWidth) / 2 +
    postSideWidth -
    postSidePadding;

  return response;
};
