import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setBgLoader,
  setDream,
  setErrorMessage,
  setShowLoader,
  setUser,
} from "../../store/users";
import {
  createDream,
  getProfileDream,
  getUser,
  uploadDreamImages,
} from "../../services/api";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { dash, image, plus, reminder } from "../../consts/SvgIcon";
import SubmitModal from "../../components/Partials/SumbitModal";
import { toast } from "react-toastify";
import GradientButton from "../../components/Buttons/GradientButton";
import { UsersModule } from "src/types/redux";
import { DreamDto } from "src/services/api/data-contracts";

const CreateDream = () => {
  const notify = (message) => toast.warning(message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const errorMessage = useSelector(
    (state: UsersModule) => state.usersModule
  ).errorMessage;
  const [images, setImages] = useState([]);
  const [showedImages, setShowedImages] = useState({
    firstImage: "",
    otherImages: [null, null, null, null],
  });
  const [modalProps, setModalProps] = useState(false);
  const [submitModal, setModal] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const dream = useSelector((state: UsersModule) => state.usersModule)
    .dream as DreamDto;

  useEffect(() => {
    getProfileDream().then((res) => {
      dispatch(setDream(res.data));
    });
  }, []);

  const ModalRender = () => {
    return (
      <Modal
        backdropClassName={"bg-transparent"}
        centered
        backdrop={true}
        show={modalProps}
        onHide={() => setModalProps(false)}
        animation={true}
      >
        <div>
          <div className="modal-header border-0">
            <h5 className="modal-title fw-bold" id="exampleModalLongTitle">
              Confirmation
            </h5>
          </div>
          <div className="modal-body">
            Are you sure that you want to delete the photo?
          </div>
          <div className="modal-footer border-0">
            <div
              className="dashboard-card-buttons custom-gradient me-2"
              onClick={() => confirmDelete()}
              style={{ width: "100px", height: "30px" }}
            >
              <div className="dashboard-card-buttons-text">
                <span>Yes</span>
              </div>
            </div>
            <button
              className="custom-btn text-black mb-1"
              onClick={() => setModalProps(false)}
              style={{ height: "30px", width: "100px" }}
            >
              <div className="dashboard-card-buttons-text fw-bold">No</div>
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const submitImages = images.filter((img) => img !== null);
    setImages(submitImages);
    dispatch(setShowLoader(true));
    dispatch(setBgLoader(true));
    const { title, amount } = event.target.elements;
    if (images.length > 2) {
      if (!dream?.id || dream?.status === "COMPLETED") {
        createDream({
          title: title.value,
          amount: parseInt(amount.value, 10),
        }).then((e) => {
          const id = e?.data?.id;
          if (images.length > 1 && images.length < 6) {
            uploadDreamImages(id, images).then(() => {
              getUser().then((response) => {
                dispatch(setUser(response.data));
              });
              getProfileDream().then((res) => {
                dispatch(setDream(res.data));
                setModal(false);
                navigate("/user-profile");
              });
            });
          }
        });
      }
    } else {
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Please add at least 3 images, you can change them later.");
      dispatch(setShowLoader(false));
      dispatch(setBgLoader(false));
    }
  };
  const handleImageChange = (e) => {
    dispatch(setErrorMessage(""));
    const selectedImages = [...e.target.files];
    if (!!images[0]) {
      images.map((img) => {
        return selectedImages.push(img);
      });
    }
    if (selectedImages.length <= 5) {
      setImages(selectedImages);
      const firstImage = URL.createObjectURL(selectedImages[0]);
      const otherImages = [];
      const selectedImagesClone = [...selectedImages];
      selectedImagesClone.splice(0, 1);
      selectedImagesClone?.map((img) => {
        return otherImages.push(URL.createObjectURL(img));
      });
      const amount = 3 - otherImages?.length;
      for (let i = 0; i <= amount; i++) {
        otherImages.push(null);
      }
      setShowedImages({ firstImage, otherImages });
    } else {
      dispatch(setErrorMessage(`You can't upload more than 5 images`));
    }
  };

  const confirmDelete = () => {
    setModalProps(false);
    const selectedImages = [...images];
    selectedImages.splice(imageIndex, 1);
    setImages(selectedImages);
    const firstImage = selectedImages[0]
      ? URL.createObjectURL(selectedImages[0])
      : "";
    const otherImages = [];
    const selectedImagesClone = [...selectedImages];
    selectedImagesClone.splice(0, 1);
    selectedImagesClone?.map((img) => {
      return otherImages.push(URL.createObjectURL(img));
    });
    const amount = 3 - otherImages?.length;
    for (let i = 0; i <= amount; i++) {
      otherImages.push(null);
    }
    setShowedImages({ firstImage, otherImages });
  };

  return (
    <>
      <Container>
        <Col lg={12}>
          <Card className="p-4 pt-3 pb-3 mt-3 shadow-card">
            <Card.Header className="d-flex justify-content-between border-0">
              <div className="header-title">
                <h3 className="card-title fw-bold">Create a dream</h3>
              </div>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit} id="createDream">
                <Col md="12" className="d-flex justify-content-start flex-wrap">
                  <div
                    className="profile-img-edit"
                    style={{ margin: "0 20px 10px 0" }}
                  >
                    {showedImages?.firstImage ? (
                      <div>
                        <div
                          className="profile-pic"
                          style={{
                            backgroundImage: `url(${showedImages.firstImage})`,
                          }}
                        />
                        <div
                          className="d-flex justify-content-center align-items-center p-image-delete"
                          onClick={() => {
                            setModalProps(true);
                            setImageIndex(0);
                          }}
                        >
                          {dash()}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="profile-default-pic">{image()}</div>
                        <div className="d-flex justify-content-center align-items-center p-image-add">
                          {plus()}
                          <input
                            className="file-upload"
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              opacity: 0,
                              display: "block",
                            }}
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            multiple
                            name="image"
                            id="image"
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-start flex-wrap">
                    {showedImages.otherImages?.map((img, index) => {
                      if (img !== null)
                        return (
                          <div
                            key={index}
                            style={{
                              margin: "0 20px 10px 0",
                              position: "relative",
                            }}
                          >
                            <div
                              className="profile-pic"
                              style={{
                                backgroundImage: `url(${img})`,
                              }}
                            />
                            <div
                              className="d-flex justify-content-center align-items-center p-image-delete"
                              onClick={() => {
                                setModalProps(true);
                                setImageIndex(index + 1);
                              }}
                            >
                              {dash()}
                            </div>
                          </div>
                        );
                      else
                        return (
                          <div
                            style={{
                              margin: "0 20px 10px 0",
                              position: "relative",
                            }}
                            key={index}
                          >
                            <div className="profile-default-pic">{image()}</div>
                            <div className="d-flex justify-content-center align-items-center p-image-add">
                              {plus()}
                              <input
                                className="file-upload"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "absolute",
                                  opacity: 0,
                                  display: "block",
                                }}
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                                multiple
                                name="image"
                                id="image"
                                onChange={handleImageChange}
                              />
                            </div>
                          </div>
                        );
                    })}
                  </div>
                  {ModalRender()}
                </Col>
                <p className="text-danger">{errorMessage}</p>
                <div className="pro-tip-container">
                  <div className="text-dark d-flex align-items-center gap-1 fw-bold">
                    {reminder()} Pro Tip
                  </div>
                  <span className="text-dark">
                    Adding 3+ high-quality images that capture your dream and
                    writing an inspiring description will help you get your
                    dream fulfilled!
                  </span>
                </div>
                <Row className="align-items-center">
                  <Form.Group className="form-group col-sm-12">
                    <Form.Label className="form-label fw-bold">
                      Your dream description*
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      className="form-control border border-light"
                      rows={5}
                      style={{
                        lineHeight: "22px",
                        resize: "none",
                        backgroundColor: "#f8f9fa",
                      }}
                      id="title"
                      placeholder="..."
                      required
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label
                      htmlFor="firstName"
                      className="form-label fw-bold"
                    >
                      Amount*{" "}
                      <span className="text-dark fw-light">
                        (you cannot change it later, at least 150$)
                      </span>
                    </Form.Label>
                    <div className="d-flex justify-content-start">
                      <Form.Control
                        type="number"
                        min={150}
                        className="form-control"
                        id="amount"
                        placeholder="..."
                        required
                        style={{
                          backgroundColor: "#f8f9fa",
                          height: "35px",
                        }}
                      />{" "}
                      <p className="fw-bold m-2 fs-4 mt-0">$</p>
                    </div>
                  </Form.Group>
                </Row>
                <div className="d-flex justify-content-end">
                  {showLoader && (
                    <img
                      src={loader}
                      alt="loader"
                      style={{ height: "39.2px" }}
                    />
                  )}
                  <div
                    className="dashboard-card-buttons custom-gradient me-2"
                    onClick={() => navigate("/user-profile")}
                    style={{
                      minWidth: "137px",
                      width: "137px",
                      height: "40px",
                    }}
                  >
                    <div className="dashboard-card-buttons-text">
                      <span>Cancel</span>
                    </div>
                  </div>
                  <GradientButton
                    text="Submit"
                    minWidth="137px"
                    onClick={() => setModal(true)}
                  />
                  <SubmitModal setModal={setModal} submitModal={submitModal} />
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default CreateDream;
