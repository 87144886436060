import React from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDream, setShowLoader, setUser } from "../../store/users";
import { register } from "../../services/api";

//swiper
import SwiperCore, { Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

import "../../styles/dreamerzLogin.css";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { toast } from "react-toastify";
import { UsersModule } from "src/types/redux";
import { pushEvent } from "../../utils";
import PublicNavBar from "src/components/NavBar/PublicNavBar";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Register = () => {
  const notify = (message) => toast.warning(message);
  const notifySuccess = (message) => toast.success(message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;

  const handleRegister = (event) => {
    event.preventDefault();
    dispatch(setShowLoader(true));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profileId");
    dispatch(setUser({}));
    dispatch(setDream({}));
    const { email, password, confirmedPassword } = event.target.elements;
    if (confirmedPassword.value === password.value) {
      register({ email: email.value, password: password.value }).then(
        (response) => {
          if (!response) {
            return;
          }
          toast.dismiss();
          toast.clearWaitingQueue();
          notifySuccess("Check your email");
          pushEvent("singup_homepage", "login_page");
        }
      );
    } else {
      dispatch(setShowLoader(false));
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Incorrect password");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <PublicNavBar />
      <div className="d-flex flex-column justify-content-around align-items-center padding-60">
        <h1 className="window-text text-center">Become a dreamer Now!</h1>
        <div className="login-form-container">
          <div style={{ padding: "35px" }}>
            <div className="form-header ">
              <h1 className="mb-3">Sign Up</h1>
              <h3>Enter your email or password to get full access.</h3>
            </div>
            <div className="form-body">
              <Form className="mt-2" onSubmit={handleRegister}>
                <Form.Group className="form-group mb-2">
                  <Form.Label>
                    <h3>Email address</h3>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    className="mb-0"
                    placeholder="Enter email"
                    style={{ color: "black" }}
                  />
                </Form.Group>
                <Form.Group className="form-group mb-2">
                  <Form.Label>
                    <h3>Password</h3>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    className="mb-0"
                    id="password"
                    placeholder="Password"
                    style={{ color: "black" }}
                  />
                </Form.Group>
                <Form.Group className="form-group mb-2">
                  <Form.Label>
                    <h3>Confirm Password</h3>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    className="mb-0"
                    id="confirmedPassword"
                    placeholder="Password"
                    style={{ color: "black" }}
                  />
                  <Form.Check className="d-inline-block mt-1 pt-1">
                    <Form.Check.Input
                      type="checkbox"
                      className="me-2"
                      id="customCheck1"
                      required
                    />
                    <Form.Check.Label style={{ paddingTop: "2px" }}>
                      I accept{" "}
                      <Link to="/terms-conditions">Terms and Conditions</Link>
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
                <div className="d-flex flex-column justify-content-start align-content-around w-100">
                  <button
                    className="dashboard-card-buttons custom-gradient p-0"
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    <span className="dashboard-card-buttons-text">Sign Up</span>
                  </button>
                  {showLoader && (
                    <div className="d-flex justify-content-center">
                      <img
                        src={loader}
                        alt="loader"
                        style={{ height: "50px" }}
                      />
                    </div>
                  )}
                </div>
              </Form>
            </div>
            <div className="form-footer text-center mt-1">
              <div
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer" }}
              >
                <span>
                  Already have an account ?
                  <span className="text-blue"> Log In</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
