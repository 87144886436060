import React from "react";
import { ReactComponent as Like } from "../../assets/images/socials/like.svg";
// import { ReactComponent as Send } from "../../assets/images/socials/send.svg";
import { ReactComponent as Comment } from "../../assets/images/socials/comment.svg";
// import { ReactComponent as Save } from "../../assets/images/socials/save.svg";
import { toast } from "react-toastify";
import "../../styles/Dreams.css";
import { SOCIALS_ENUM } from "../../consts/socialsEnum";
import { NewsfeedSocialInteractionProps } from "src/types/components-props";
import { useDispatch, useSelector } from "react-redux";
import { likePost } from "src/store/action";
import { useNavigate } from "react-router-dom";
import {
  AngelCommentData,
  CharityCommentData,
  DreamCommentData,
} from "src/types/redux";

const NewsfeedSocialInteraction = ({
  post,
  type,
  charity,
  dream,
  isCommentButton = true,
  openCommentModal,
  focusToInput = false,
  inputToFocus,
}: NewsfeedSocialInteractionProps) => {
  const notify = (message) => toast.error(message);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dreamComments = useSelector(
    (state: DreamCommentData) => state.comments.dreamComments[dream?.id] || {}
  );

  const charityComments = useSelector(
    (state: CharityCommentData) =>
      state.comments.charityComments[charity?.id] || {}
  );

  const angelComments = useSelector(
    (state: AngelCommentData) => state.comments.angelComments[post?.id] || {}
  );

  const handleLikeClick = async () => {
    try {
      await dispatch(likePost(post?.id || dream?.id || charity?.id, type));
    } catch (error) {
      notify("Something went wrong. Please try again later.");
    }
  };

  let commentsInput;
  if (focusToInput && inputToFocus !== null) {
    commentsInput = inputToFocus?.current?.querySelector(".comments-input");
  }
  return (
    <div className="public-dream-social-parts d-flex">
      <div className="dream-social-action d-flex justify-content-between">
        <div className="newsfeed-socials-left">
          <Like
            fill={
              dreamComments.userLiked ||
              charityComments.userLiked ||
              angelComments.userLiked
                ? "#B0E8E8"
                : "white"
            }
            stroke={
              dreamComments.userLiked ||
              charityComments.userLiked ||
              angelComments.userLiked
                ? "#7EC6CE"
                : "#828282"
            }
            className={
              dreamComments.userLiked ||
              charityComments.userLiked ||
              angelComments.userLiked
                ? ""
                : "like-icon-default-styles"
            }
            style={{ cursor: "pointer" }}
            onClick={handleLikeClick}
          />
          {isCommentButton && (
            <Comment
              fill="white"
              stroke="#828282"
              className="like-icon-default-styles"
              style={{ cursor: "pointer" }}
              onClick={() => {
                type === SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL
                  ? navigate(`/angel/${post?.id}`)
                  : focusToInput && commentsInput
                  ? commentsInput.focus()
                  : openCommentModal(charity?.id);
              }}
            />
          )}
          {/* <Send
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
          /> */}
        </div>
        <div className="newsfeed-socials-right">
          {/* <Save
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
          /> */}
        </div>
      </div>
      <div className="dream-stats-row d-flex">
        <div className="d-flex dream-profile-first-container">
          <p className="fw-bold">
            {dreamComments.likesCount ||
              charityComments.likesCount ||
              angelComments.likesCount ||
              0}
          </p>
          <p>Likes</p>
        </div>
        <div className="d-flex dream-profile-second-container">
          <p className="fw-bold">
            {dreamComments.count ||
              charityComments.count ||
              angelComments.count ||
              0}
          </p>
          <p>Comments</p>
        </div>
      </div>
    </div>
  );
};

export default NewsfeedSocialInteraction;
