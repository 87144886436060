import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "../../styles/Dashboard.css";
import { close } from "../../consts/SvgIcon";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  setAllDreamPayments,
  setDream,
  setShowLoader,
  setUser,
} from "../../store/users";
import {
  getPayOutsByDreamId,
  getProfileDream,
  getUser,
  payOut,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UsersModule } from "src/types/redux";
import { DreamDto } from "src/services/api/data-contracts";

const CashOut = (props) => {
  const dispatch = useDispatch();
  const notify = (message) => toast.success(message);
  const [paymentAPI, setPaymentApi] = useState(false);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const dream = useSelector((state: UsersModule) => state.usersModule)
    .dream as DreamDto;
  const [userData, setUserData] = useState({
    ...user,
    country:
      user?.country?.charAt(0).toUpperCase() + user?.country?.slice(1) || "",
  });
  const [country, setCountry] = useState(userData?.country);
  const [city, setCity] = useState(userData?.city);

  const handleEdit = (event) => {
    const target = event.target;
    const userDataClone = { ...userData, country: country, city: city };
    userDataClone[target.id] = target.value;
    setUserData(userDataClone);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setShowLoader(true));
    const { phoneNumber, address, address2, state, zipCode, iban, swift } =
      event.target.elements;
    payOut({
      dreamId: props.dreamId,
      phoneNumber: phoneNumber.value,
      address: address.value,
      address2: address2.value,
      state: state.value,
      zipCode: zipCode.value,
      iban: iban.value,
      swift: swift.value,
      city,
      country,
    }).then((res) => {
      if (!res) return;
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Success!");
      props.openCashOut(false);
      getPayOutsByDreamId(props.dreamId).then((res) => {
        dispatch(setAllDreamPayments(res?.data));
      });
      getUser().then((response) => {
        dispatch(setUser(response?.data));
        dispatch(setShowLoader(false));
      });
      getProfileDream().then((res) => {
        dispatch(setDream(res?.data));
      });
    });
  };
  return (
    <Modal
      centered
      backdrop={true}
      show={props.cashOut}
      onHide={() => props.openCashOut(false)}
      animation={true}
      contentClassName="border-0 cash-modal"
    >
      {props?.cashOut ? (
        <div className="w-100 h-100 d-flex flex-column justify-content-around">
          <Modal.Header
            className="price-content d-flex flex-column justify-content-around w-100 mb-2"
            style={{ borderBottom: "none", paddingTop: "30px" }}
          >
            <div className="w-100 d-flex justify-content-between mb-3">
              <div>
                <h3 className="fw-bold text-black">Cash Out</h3>
              </div>
              <div
                className="d-flex justify-content-end align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => props.openCashOut(false)}
              >
                {close()}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between">
              <h5 className="text-dark">Total amount: </h5>
              <h4 className="amount-due">{dream?.amount}$</h4>
            </div>
          </Modal.Header>

          <Modal.Body className="price-content">
            <div className="payment-form-container">
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>
                    <h3 className="payment-text">Phone Number*</h3>
                  </Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="text"
                      id="phoneNumber"
                      required
                      className="form-control border border-light"
                      placeholder="Phone Number"
                      value={userData?.phoneNumber || ""}
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      onChange={handleEdit}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="country" className="payment-text">
                    Country*
                  </Form.Label>
                  <CountryDropdown
                    id="country"
                    classes="form-control border border-light form-select region-dropdown"
                    value={country || ""}
                    onChange={(country) => setCountry(country)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="City" className="payment-text">
                    City*
                  </Form.Label>
                  <RegionDropdown
                    id="city"
                    classes="form-control border border-light form-select region-dropdown"
                    country={country}
                    value={city || ""}
                    onChange={(city) => setCity(city)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <h3 className="payment-text">State/Province/Region*</h3>
                  </Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="text"
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      required
                      className="form-control border border-light"
                      id="state"
                      placeholder="Province"
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <h3 className="payment-text">Recipient’s Legal Address*</h3>
                  </Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="text"
                      id="address"
                      required
                      className="form-control border border-light"
                      placeholder="Address"
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <h3 className="payment-text">
                      Apartment/Suite/Floor (optional)
                    </h3>
                  </Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="text"
                      id="address2"
                      className="form-control border border-light"
                      placeholder="Apartment"
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <h3 className="payment-text">Zip Code*</h3>
                  </Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="text"
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      required
                      className="form-control border border-light"
                      id="zipCode"
                      placeholder="Province"
                    />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    <h3 className="payment-text">Recipient Bank SWIFT Code*</h3>
                  </Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="text"
                      id="swift"
                      required
                      placeholder="Enter an 8- or 11 -digit code"
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      className="form-control border border-light"
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <h3 className="payment-text">Recipient IBAN*</h3>
                  </Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="text"
                      id="iban"
                      required
                      placeholder="IBAN"
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      className="form-control border border-light"
                    />
                  </div>
                </Form.Group>
                <div style={{ borderTop: "0 none", paddingBottom: "20px" }}>
                  <div className="w-100 text-center d-flex flex-column pb-2">
                    <span>A payment confirmation message will be sent</span>
                    <span>your email adress</span>
                  </div>
                  <div className="w-100 text-center">
                    <button
                      type="submit"
                      onClick={() => setPaymentApi(true)}
                      className="payment-button-confirm black-hover-dream "
                    >
                      Send
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </div>
      ) : (
        <div className="text-center">
          <Modal.Header>
            <div className="w-100 d-flex align-items-center justify-content-center">
              <h4
                style={{
                  fontSize: "24px",
                  fontWeight: "700px",
                  lineHeight: "28px",
                  marginTop: "25px",
                }}
              >
                {paymentAPI ? "Thank you for you payment" : "Checkout Failed"}
              </h4>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex align-items-center justify-content-center flex-column mb-3">
              <div className="d-flex justify-content-center align-items-center mb-3">
                <span>
                  {" "}
                  Lorem ipsum dolor sit amet, , consequuntur tenetur! Lorem
                  ipsum dolor sit amet, consectetur adipisicing elit
                </span>
              </div>
              {paymentAPI ? (
                <span className="fa-payment-accept" />
              ) : (
                <span className="fa-payment-failed" />
              )}
            </div>
          </Modal.Body>
        </div>
      )}
    </Modal>
  );
};
export default CashOut;
