import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import defaultImage from "../../assets/images/defaultUser.png";
import moment from "moment";
import "../../styles/Sliders.css";
import { calculateDreamProgress } from "../../utils/calculateDreamProgress";

export default function FulfilledDreams({ dream }) {
  const progress = calculateDreamProgress(dream);
  const avatarUrl = dream.images?.[0]?.avatarUrl || defaultImage;
  const navigate = useNavigate();

  return (
    <div className="dream-card shadow-card rounded-3">
      <div className="dream-card-header">
        <Link to={`/login`}>
          <img
            src={dream?.user?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="dream-avatar"
          />
        </Link>
        <div className="dream-name-and-date">
          <Link to={`/login`}>
            <span className="dream-card-name text-underline">
              {`${dream?.user?.firstName || "No Name"} ${
                dream.user?.lastName || ""
              }`}
            </span>
          </Link>
          <p className="dream-card-time-ago" style={{ marginTop: "-5px" }}>
            {moment(dream.createdAt)?.fromNow(true)} ago
          </p>
        </div>
      </div>
      <img
        className="dream-image cursor-pointer"
        src={avatarUrl}
        alt="Dream"
        onClick={() => navigate(`/login`)}
      />
      <div className="dream-card-main-container">
        <div
          className="text-dark cursor-pointer"
          style={{ width: "-webkit-fill-available" }}
        >
          <span className="my-span lh-sm dream-description">
            {dream?.title || "No Title"}
          </span>
        </div>
        <div>
          <div className="dream-progress-container">
            <ProgressBar
              onClick={() => navigate(`/login`)}
              style={{ width: "80%", height: "10px", cursor: "pointer" }}
              now={progress}
              variant={"gradient"}
              className={"black-hover"}
            />
            <p className="dream-percentage">
              {isNaN(progress) ? 0 : progress}%
            </p>
          </div>
          <div className="dream-fulfillment-info">
            <div className="dream-fulfillment-container">
              <p>Fulfilled</p>
              <p>{(dream.donations as unknown as number) || "0"}</p>
            </div>
            <div className="dream-fulfillment-container">
              <p>Received</p>
              <p>{dream.amountReceived || "0"}</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between sliders-button">
          <div
            className="custom-gradient sliders-button"
            onClick={() => navigate(`/login`)}
          >
            <div className="dashboard-card-buttons-text">
              <span>
                {`Fulfill ${
                  dream?.user?.gender === "FEMALE" ? "Her" : "His"
                } Dream`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
