export const scrollCarousel = (
  direction: string,
  containerScrollClass: string
) => {
  const containerScroll = document.querySelector(`.${containerScrollClass}`);
  
  if (direction === "right") {
    containerScroll.scrollLeft += 250;
  } else if (direction === "left") {
    containerScroll.scrollLeft += -250;
  }
};
