import { ReplyProps } from "src/types/comments-props";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import EmojiMenu from "../Partials/EmojiMenu";
import DefaultImage from "../../assets/images/defaultUser.png";
import { useDispatch, useSelector } from "react-redux";
import { CommentsReply, UsersModule } from "src/types/redux";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "../Tooltip/TooltipDetails";
import { setActiveReply } from "src/store/action";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const Reply = ({
  reply,
  parentComment,
  dreamId,
  user,
  autoFocus = true,
  onKeyEnter,
  handleReply,
  text,
  setText,
  isFulfilled,
}: ReplyProps) => {
  const profileId = localStorage.getItem("profileId");
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const commentReplyId = useSelector(
    (state: CommentsReply) => state.comments.commentsReply.parentReplyId
  );

  const tooltipKey = generateTooltipKey(reply?.user?.id);
  const [seeAllReply, setSeeAllReply] = useState(false);
  const [emojiPicker, showEmojiPicker] = useState(false);
  const [userReplyToReply, setUserReplyToReply] = useState<any>([]);
  const dispatch = useDispatch();
  const hasDream = useRoleCheck(reply?.user);

  const getAllChildren = (node) => {
    let allChildren = [];

    const addChildren = (currentNode) => {
      if (currentNode.children && currentNode.children.length) {
        currentNode.children.forEach((child) => {
          allChildren.push(child);
          addChildren(child);
        });
      }
    };

    addChildren(node);
    return allChildren.reverse();
  };

  useEffect(() => {
    setUserReplyToReply(getAllChildren(reply));
  }, [reply?.children, reply]);

  const repliesToThisReply = userReplyToReply
    ?.map((el, i) => {
      const lastElement =
        userReplyToReply?.length > 3
          ? !seeAllReply
            ? userReplyToReply?.length - 3
            : 0
          : 0;
      const tooltipKey = generateTooltipKey(el?.user?.id);
      return (
        <div className="reply-line-container" key={el?.id}>
          <div className="second-line-container">
            <div className="reply-line">
              {lastElement !== i ? <div className="small-line"></div> : ""}
              {lastElement !== i ? (
                <div className="rounded-line"></div>
              ) : (
                <div
                  className="rounded-line"
                  style={{ marginLeft: "19.5px" }}
                ></div>
              )}
            </div>
            {lastElement !== i ? <div className="continued-line"></div> : ""}
          </div>
          <div
            key={`comment-reply-${el?.id}`}
            id={`comment-reply-${el?.id}`}
            className="comment-reply"
            style={{ marginBottom: "8px" }}
          >
            <div className="d-flex justify-content-start gap-2">
              <div className="avatar-container">
                <div
                  className={`avatar-image  ${
                    !hasDream ? "gradient-border" : ""
                  }`}
                >
                  <Link to={`/dashboard/public-page/${el?.user?.id}`}>
                    <img
                      src={el?.user?.mainImageUrl || DefaultImage || ""}
                      alt="userimg"
                      className="avatar-35 rounded-circle"
                      style={{
                        objectFit: "cover",
                        cursor: "pointer",
                        zIndex: 10,
                      }}
                      id="reply-image"
                    />
                  </Link>
                </div>
              </div>
              <div
                className="d-flex flex-column comment-reply"
                style={{ width: "100%" }}
              >
                <div
                  className="d-flex flex-column justify-content-start reply-css"
                  style={{ marginBottom: "5px" }}
                >
                  <div className="d-flex flex-row">
                    <Link to={`/dashboard/public-page/${el?.user?.id}`}>
                      <span
                        className="user-name comment-user-name-text"
                        data-tooltip-id={tooltipKey}
                      >
                        {el?.user?.firstName} {el?.user?.lastName}
                      </span>
                    </Link>
                    {el?.user?.role === "ANGEL" ? (
                      <div className="tooltip-angel-badge comment-badge">
                        Dream Angel
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {profileId ? (
                    <TooltipDetails
                      userId={el?.user?.id}
                      isFulfilled={isFulfilled}
                      allFulfilled={allFulfilled}
                      tooltipKey={tooltipKey}
                    />
                  ) : (
                    <></>
                  )}
                  {/* <div className="d-flex text-gray ">
                    <span>
                      to:
                      <Link
                        className="comment-reply text-gray"
                        to={`/dashboard/public-page/${el?.user?.id}`}
                      >
                        <span>
                          {`${el?.replyUser?.firstName || " No name"} ${
                            el?.replyUser?.lastName || ""
                          }`}
                        </span>
                      </Link>
                    </span>
                  </div> */}
                  <span
                    className="comment-text-list text-wrap reply-message"
                    style={{
                      hyphens: "auto",
                      wordBreak: "break-word",
                    }}
                  >
                    {el?.message}
                  </span>
                </div>
                <div
                  className="w-100 d-flex justify-content-start cursor-pointer gap-2 align-items-baseline"
                  style={{ paddingLeft: "5px" }}
                >
                  <span className="comment-create-time cursor-pointer">
                    {moment(el?.createdAt).fromNow(true)} ago
                  </span>
                  <div
                    onClick={() => {
                      if (commentReplyId !== el?.id) {
                        setText("");
                        dispatch(setActiveReply(el?.id));
                      } else {
                        dispatch(setActiveReply(""));
                      }
                    }}
                  >
                    <span className="d-flex justify-content-start reply-hover-bottom-line comment-reply">
                      Reply
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {commentReplyId === el?.id && (
              <div className="d-flex w-100 gap-2 ">
                <div className="d-flex align-items-center w-100 gap-2">
                  <div>
                    <img
                      src={
                        user?.mainImageUrl ||
                        parentComment?.user?.images?.[0]?.avatarUrl ||
                        DefaultImage ||
                        ""
                      }
                      alt="userimg"
                      className="avatar-35 rounded-circle cursor-pointer"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="comment-reply-container mb-2 mt-2">
                    <div className="reply-name">
                      <span>
                        {el?.user?.firstName || "No Name"}{" "}
                        {el?.user?.lastName || ""}
                      </span>
                    </div>
                    <input
                      className="d-flex flex-nowrap m-1 reply-input "
                      style={{ border: "0" }}
                      autoFocus={true}
                      onKeyDown={(e) =>
                        onKeyEnter(e, dreamId, parentComment?.user?.id, el?.id)
                      }
                      onChange={(e) => setText(e.target.value)}
                      value={text}
                    />
                    <EmojiMenu
                      reply={true}
                      showEmojiPicker={showEmojiPicker}
                      emojiPicker={emojiPicker}
                      setText={setText}
                      sendMessage={(e) =>
                        handleReply(e, dreamId, parentComment?.user?.id, el?.id)
                      }
                      currentUserComment={text}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    })
    .reverse();

  return (
    <div
      key={`comment-reply-${reply?.id}`}
      id={`comment-reply-${reply?.id}`}
      className="comment-reply"
    >
      <div className="first-comment">
        <div className="avatar-container">
          <div
            className={`avatar-image  ${!hasDream ? "gradient-border" : ""}`}
          >
            <Link to={`/dashboard/public-page/${reply?.user?.id}`}>
              <img
                src={reply?.user?.mainImageUrl || DefaultImage || ""}
                alt="userimg"
                className="avatar-35 rounded-circle"
                style={{
                  objectFit: "cover",
                  cursor: "pointer",
                  zIndex: 10,
                }}
                id="reply-image"
              />
            </Link>
          </div>
          {userReplyToReply?.length ? (
            <div
              className={`avatar-line  ${!hasDream ? "avatar-line-angel" : ""}`}
            ></div>
          ) : (
            ""
          )}
        </div>

        <div
          className="d-flex flex-column comment-reply"
          style={{ width: "100%" }}
        >
          <div className="d-flex flex-column">
            <div
              className="d-flex flex-column justify-content-start reply-css"
              style={{ marginBottom: "5px" }}
            >
              <div className="d-flex flex-row">
                <Link to={`/dashboard/public-page/${reply?.user?.id}`}>
                  <span
                    className="user-name comment-user-name-text"
                    data-tooltip-id={tooltipKey}
                  >
                    {reply?.user?.firstName} {reply?.user?.lastName}
                  </span>
                </Link>
                {!hasDream ? (
                  <div className="tooltip-angel-badge comment-badge">
                    Dream Angel
                  </div>
                ) : (
                  ""
                )}
              </div>

              {profileId ? (
                <TooltipDetails
                  userId={reply?.user?.id}
                  isFulfilled={isFulfilled}
                  allFulfilled={allFulfilled}
                  tooltipKey={tooltipKey}
                />
              ) : (
                <></>
              )}
              {/* <div className="d-flex text-gray ">
                <span>
                  to:
                  <Link
                    className="comment-reply text-gray"
                    to={`/dashboard/public-page/${reply?.user?.id}`}
                  >
                    <span>
                      {`${reply?.replyUser?.firstName || " No name"} ${
                        reply?.replyUser?.lastName || ""
                      }`}
                    </span>
                  </Link>
                </span>
              </div> */}
              <span
                className="comment-text-list text-wrap reply-message"
                style={{
                  hyphens: "auto",
                  wordBreak: "break-word",
                }}
              >
                {reply?.message}
              </span>
            </div>
            <div
              className="w-100 d-flex justify-content-start cursor-pointer gap-2 align-items-baseline"
              style={{ paddingLeft: "5px" }}
            >
              <span className="comment-create-time cursor-pointer">
                {moment(reply?.createdAt).fromNow(true)} ago
              </span>
              <div
                onClick={() => {
                  if (commentReplyId !== reply?.id) {
                    setText("");
                    dispatch(setActiveReply(reply?.id));
                  } else {
                    dispatch(setActiveReply(""));
                  }
                }}
              >
                <span className="d-flex justify-content-start reply-hover-bottom-line comment-reply">
                  Reply
                </span>
              </div>
              {repliesToThisReply?.length > 3 ? (
                <div
                  className="d-flex"
                  style={{ paddingLeft: "8px", borderBottom: "2px" }}
                >
                  <span
                    onClick={() => setSeeAllReply((prev) => !prev)}
                    className="comment-reply see-all-comments"
                  >
                    {!seeAllReply ? "View All" : "Hide All"} (
                    {repliesToThisReply.length})
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="current-replies">
        <div className="replies-container">
          <div className="reply-input">
            {commentReplyId === reply?.id && (
              <div className="d-flex w-100 mb-2 mt-2">
                <div className="d-flex align-items-center w-100 gap-2">
                  <img
                    src={user?.mainImageUrl || DefaultImage || ""}
                    alt="userimg"
                    className="avatar-35 rounded-circle"
                    style={{ objectFit: "cover", cursor: "pointer" }}
                  />

                  <div className="comment-reply-container position-relative">
                    <div className="reply-name">
                      <span>
                        {parentComment?.user?.firstName || "No name"}{" "}
                        {parentComment?.user?.lastName || ""}
                      </span>
                    </div>
                    <input
                      className="d-flex flex-nowrap m-1 reply-input"
                      style={{ border: "0", paddingRight: "60px" }}
                      autoFocus={autoFocus}
                      onKeyDown={(e) =>
                        onKeyEnter(e, dreamId, reply?.user?.id, reply?.id)
                      }
                      onChange={(e) => setText(e.target.value)}
                      value={text}
                    />
                    <EmojiMenu
                      reply={true}
                      showEmojiPicker={showEmojiPicker}
                      emojiPicker={emojiPicker}
                      setText={setText}
                      sendMessage={(e) =>
                        handleReply(e, dreamId, reply?.user?.id, reply?.id)
                      }
                      currentUserComment={text}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {!seeAllReply && repliesToThisReply?.length ? (
            <div className="">{repliesToThisReply.splice(0, 3)}</div>
          ) : (
            ""
          )}
          {repliesToThisReply?.length && seeAllReply ? (
            <div className="">{repliesToThisReply}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Reply;
