import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PublicWingMakersCarousel from "src/components/PublicandInternShared/PublicWingMakersCarousel";
import {
  getDreamById,
  getDreamDonations,
  getPublicDreamDonations,
} from "src/services/api";
import defaultImage from "../../assets/images/defaultUser.png";
import coverImg from "../../assets/images/coverImg.png";
import { updateDreamLikes } from "src/store/action";
import { setBgLoader, setPublicReceived } from "src/store/users";
import { UsersModule } from "src/types/redux";
import { handleFulfillment } from "src/utils/isFulfilled";
import PricingModal from "../Pricing/PricingModal";
import { Card, Carousel, ProgressBar } from "react-bootstrap";
import ShareModal from "src/components/Partials/ShareModal";
import { shareType } from "src/consts/notifications";
import { shareTo } from "src/utils/share";
import ShareButton from "src/components/Buttons/ShareButton";
import GradientButton from "src/components/Buttons/GradientButton";
import moment from "moment";
import { SOCIALS_ENUM } from "src/consts/socialsEnum";
import NewsfeedSocialInteraction from "src/components/Partials/NewsfeedSocialInteraction";
import { commentType } from "src/consts/commentsTypeEnum";
import Comments from "src/components/Comments/Comments";
import DreamMakersModal from "src/components/DreamMakersModal";
import { fulfillCheck } from "src/utils/fulfillCheck";

export default function DreamPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;

  const [customModals, setCustomModal] = useState("");
  const [show, setShow] = useState(false);
  const [dreamId, setDreamId] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [link, setLink] = useState(process.env.REACT_APP_BASE_URL);

  const { data: dream, isLoading: dreamLoading } = useQuery({
    queryKey: ["internDream", id],
    queryFn: () => getDreamById(id).then((res) => res.data),
    enabled: !!id,
    onSuccess: (dream) => {
      getActionData(dream);
    },
  });

  const { data: users, isLoading: isDonationsLoading } = useQuery({
    queryKey: ["dreamPageDonationsUsers", id],
    queryFn: () => getDreamDonations(id, 1).then((res) => res.data.results),
    enabled: !!id,
  });

  if (dreamLoading || isDonationsLoading) {
    dispatch(setBgLoader(true));
    return <></>;
  }

  const getActionData = (dream) => {
    const likesCount = dream.likedDreamsByUsers.length;
    const likedBy = dream.likedDreamsByUsers.map((user) => user.id);
    const userLiked = likedBy.includes(localStorage.getItem("profileId"));
    dispatch(updateDreamLikes(dream?.id, likesCount, userLiked, likedBy));
  };

  const progress = Math.round((dream?.amountReceived * 100) / dream?.amount);
  const isDreamFulfilled = fulfillCheck(allFulfilled, dream, dream?.user?.id);
  const isFulfilled = (id) => {
    handleFulfillment(id, user, setDreamId, setCustomModal, dispatch);
  };

  const share = () => {
    setLink(`${link}/dream/${dream?.id}`);
    shareTo({
      link: `${link}/dream/${dream?.id}`,
      type: shareType.SHARE_DREAM,
      dreamId: dream?.id,
      replyUserId: user?.id,
      setShareModal,
    });
  };

  const progressClick = () => {
    dispatch(setPublicReceived({}));
    setShow((prev) => !prev);
    getPublicDreamDonations(dream?.id, 1).then((res) => {
      dispatch(setPublicReceived(res?.data));
    });
  };

  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img}>
        <img src={img} className="post-image cursor-pointer" alt="img" />
      </Carousel.Item>
    );
  };

  return (
    <div className="d-flex justify-content-center flex-column mt-2">
      <div className="wing-donation-page-card mb-5">
        <Card className="rounded-3 gap-card pb-0 shadow m-0">
          <Card.Body className="p-0 gap-post">
            <ShareModal
              customModals={shareModal}
              setCustomModal={setShareModal}
              sharedLink={link}
              post={dream}
              mainUser={user}
            />
            <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
              <div
                className="d-flex align-items-center gap-2 cursor-pointer user-image"
                onClick={() => {
                  navigate(`/dashboard/public-page/${dream?.user?.id}`);
                }}
              >
                <div>
                  <img
                    src={dream?.user?.mainImageUrl || defaultImage}
                    alt="profile-img"
                    className="avatar"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div className="d-flex flex-column ">
                  <span id="username" className="user-name">
                    {`${dream?.user?.firstName || "No name"} 
                        ${dream?.user?.lastName || ""}`}
                  </span>
                  <span className="time-ago">
                    {moment(dream?.createdAt)?.fromNow(true)} ago
                  </span>
                </div>
              </div>
              <div className="buttons-container">
                <ShareButton width="72px" onClick={share} />
                <GradientButton
                  minWidth="137px"
                  text={isDreamFulfilled ? "View Profile" : "Fulfill"}
                  onClick={() =>
                    isDreamFulfilled
                      ? navigate(`/dashboard/public-page/${dream?.user?.id}`)
                      : isFulfilled(dream?.id)
                  }
                />
              </div>
            </div>
            <Carousel
              indicators={dream?.images?.length > 1}
              interval={null}
              controls={dream?.images?.length > 1 && window.innerWidth > 500}
            >
              {dream?.images?.length
                ? dream?.images?.map((img) => carouselItem(img?.url))
                : carouselItem(coverImg)}
            </Carousel>
            <span className="my-span lh-sm text-dark">
              {dream?.title || "No Title"}
            </span>
            <div>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <ProgressBar
                  onClick={() => progressClick()}
                  now={progress}
                  variant={"gradient"}
                  className="black-hover post-progress-bar"
                />
                <span
                  className="fw-bold text-dark"
                  style={{ fontSize: "12px" }}
                >
                  {isNaN(progress) ? 0 : progress}%
                </span>
              </div>
              <div className="d-flex justify-content-between text-dark">
                <span>
                  Fulfilled <b>{dream?.donations || 0}</b>
                </span>
                <span>
                  Received <b>{dream?.amountReceived || 0}</b>
                </span>
              </div>
              <NewsfeedSocialInteraction
                dream={dream}
                type={SOCIALS_ENUM.PROFILE_DREAM}
              ></NewsfeedSocialInteraction>
              <Comments
                type={commentType.dream}
                user={user}
                mainImg={mainImg}
                isFulfilled={isFulfilled}
                dream={dream}
                postId={dream.id}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
      <PricingModal
        customModals={customModals}
        setCustomModal={setCustomModal}
        dreamId={dreamId}
      />
      <DreamMakersModal
        donations={publicReceived}
        show={show}
        setShow={setShow}
        dreamId={dream.id}
      />
      <PublicWingMakersCarousel users={users} isFulfilled={isFulfilled} />
    </div>
  );
}
