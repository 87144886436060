import { Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import defaultImage from "../assets/images/defaultUser.png";
import { close } from "../consts/SvgIcon";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import loaderGif from "../assets/images/page-img/page-load-loader.gif";
import {
  getDreamDonations,
  getPublicDreamDonations,
  getWingDonations,
} from "../services/api";
import {
  setAddProfileReceived,
  setAddPublicReceived,
  setAddWingDonations,
} from "../store/users";
import { useDisableBodyScroll } from "../hooks/useDisableBodyScroll";
import { UsersModule } from "src/types/redux";
import { DreamMakersModalProps } from "src/types/components-props";

let page2d = 1;
let loader = false;
const DreamMakersModal = ({
  donations,
  show,
  setShow,
  dreamId,
  wing,
  card,
  profile,
  wingCard,
}: DreamMakersModalProps) => {
  const handleClose = () => {
    setShow(false);
    setCurrentPage(1);
    page2d = 1;
  };

  const dispatch = useDispatch();
  const params = useParams();
  const [hasNextPage, setHasNextPage] = useState(donations?.meta?.hasNextPage);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const [currentPage, setCurrentPage] = useState(1);
  let myRef = useRef(null);
  useDisableBodyScroll(show);

  useEffect(() => {
    setHasNextPage(donations?.meta?.hasNextPage);
    setCurrentPage(donations?.meta?.page);
    page2d = donations?.meta?.page;
    loader = false;
  }, [donations?.meta?.page]);

  const loadMore = () => {
    if (donations?.results?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        if (params?.id) {
          if (wing) {
            getWingDonations(params?.id, page2d).then((response) => {
              dispatch(setAddWingDonations(response.data));
              setHasNextPage(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          } else {
            if (profile) {
              getPublicDreamDonations(dreamId, page2d).then((response) => {
                dispatch(setAddPublicReceived(response?.data));
                setHasNextPage(response?.data?.meta?.hasNextPage);
                loader = false;
                setCurrentPage(currentPage + 1);
              });
            } else {
              getPublicDreamDonations(params.id, page2d).then((response) => {
                dispatch(setAddPublicReceived(response?.data));
                setHasNextPage(response?.data?.meta?.hasNextPage);
                loader = false;
                setCurrentPage(currentPage + 1);
              });
            }
          }
        } else {
          if (card) {
            getPublicDreamDonations(dreamId, page2d).then((response) => {
              dispatch(setAddPublicReceived(response?.data));
              setHasNextPage(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          } else if (wingCard) {
            getWingDonations(dreamId, page2d).then((response) => {
              dispatch(setAddWingDonations(response.data));
              setHasNextPage(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          } else {
            getDreamDonations(dreamId, page2d).then((response) => {
              dispatch(setAddProfileReceived(response?.data));
              setHasNextPage(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          }
        }
      }
    }
  };
  useEffect(() => {}, [myRef.current]);
  return (
    <Modal
      backdropClassName={"bg-opacity-50t"}
      centered
      backdrop={true}
      show={show}
      onHide={handleClose}
      animation={true}
      className="d-flex justify-content-center"
      contentClassName="rounded-2 size-modal"
    >
      <Modal.Header className="d-flex justify-content-between p-4 cursor-pointer">
        <h5 className="fw-bold">Dream makers</h5>
        <div role="button" onClick={handleClose}>
          {close()}
        </div>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <ul
          ref={myRef}
          className="p-2 pb-0 overflow-auto m-0"
          style={{ width: "390px", height: "260px" }}
        >
          <InfiniteScroll
            loadMore={() => loadMore()}
            hasMore={hasNextPage}
            useWindow={false}
            getScrollParent={() => myRef.current}
            threshold={10}
          >
            {donations?.results?.length ? (
              donations?.results
                ?.filter((el) => el.user?.id !== user.id)
                ?.map((elem, index) => {
                  return (
                    <li
                      className="d-flex align-items-center justify-content-between flex-wrap m-2 mt-0"
                      key={index}
                    >
                      <div className="user-img img-fluid flex-shrink-0">
                        <Link
                          to={`/dashboard/public-page/${elem?.userId}`}
                          onClick={() => {
                            setShow(false);
                            setCurrentPage(1);
                            page2d = 1;
                          }}
                        >
                          
                          <div
                            className={`${
                              elem?.user?.role === "ANGEL"
                                ? "gradient-border"
                                : ""
                            }`}
                          >
                            <img
                              style={{ objectFit: "cover" }}
                              src={elem?.user?.mainImageUrl || defaultImage}
                              alt="story-img"
                              className="rounded-circle avatar-40"
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <Link
                          to={`/dashboard/public-page/${elem?.userId}`}
                          onClick={() => {
                            setShow(false);
                            setCurrentPage(1);
                            page2d = 1;
                          }}
                        >
                          <h6 className="font-hover">
                            {elem.user?.firstName || "No name"}{" "}
                            {elem.user?.lastName}
                          </h6>
                        </Link>
                      </div>
                    </li>
                  );
                })
            ) : (
              <div className="text-center text-black">
                <h6>No dream makers yet</h6>
              </div>
            )}
            {loader && (
              <div className="d-flex justify-content-center w-100">
                <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
              </div>
            )}
          </InfiniteScroll>
        </ul>
      </Modal.Body>
      <Modal.Footer className="p-4" />
    </Modal>
  );
};
export default DreamMakersModal;
