import { toast } from "react-toastify";
import { fulfill, getUser, getAllProfileDonations } from "../services/api";
import { setUser, setAllProfileFulfilled } from "../store/users";

export const handleFulfillment = async (
  id,
  user,
  setDreamId,
  setCustomModal,
  dispatch
) => {
  const notify = (message) => toast.success(message);

  if (!user?.balance) {
    setDreamId(id);
    setCustomModal("priceModal");
  } else {
    const res = await fulfill(id);
    if (res) {
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Success!");
    }
    const userData = await getUser();
    dispatch(setUser(userData?.data));
    const profileDonations = await getAllProfileDonations();
    dispatch(setAllProfileFulfilled(profileDonations?.data));
  }
};
