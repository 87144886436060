import React from "react";
import { useParams } from "react-router-dom";
import { getDreamById, getPublicDreamDonations } from "../../services/api";
import Layout from "../Public/components/Layout";
import DreamPost from "../../components/PublicandInternShared/DreamPost";
import { useCheckIsAuth } from "../../hooks/useCheckIsAuth";
import { setBgLoader } from "../../store/users";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import LiveYourDreamSection from "src/components/LandingPageComponents/LiveYourDreamSection";
import PublicWingMakersCarousel from "src/components/PublicandInternShared/PublicWingMakersCarousel";

const SharedDreamPage = () => {
  const { dreamId } = useParams();
  const token = useCheckIsAuth(`/dream-page/${dreamId}`, dreamId);
  const dispatch = useDispatch();

  const { data: dreamData, isLoading: publicDreamLoading } = useQuery({
    queryKey: ["publicDream", dreamId],
    queryFn: () => getDreamById(dreamId).then((res) => res.data),
    enabled: !!dreamId && !token,
  });

  const { data: users, isLoading: isDonationsLoading } = useQuery({
    queryKey: ["publicDreamPageDonationsUsers", dreamId],
    queryFn: () =>
      getPublicDreamDonations(dreamId, 1).then((res) => res.data.results),
    enabled: !!dreamId,
  });

  if (publicDreamLoading || isDonationsLoading) {
    dispatch(setBgLoader(true));
    return <></>;
  }

  if (token) return <div />;

  return (
    <Layout>
      <div className="wing-donation-page-card mt-2 mb-5">
        <DreamPost post={dreamData} />
      </div>
      <PublicWingMakersCarousel users={users} />
      <LiveYourDreamSection />
    </Layout>
  );
};
export default SharedDreamPage;
