import React from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/defaultUser.png";
import { ReactComponent as Globe } from "../../assets/images/tooltip/globe.svg";
import { ReactComponent as Spark } from "../../assets/images/tooltip/spark.svg";
import { fulfillCheck } from "src/utils/fulfillCheck";

const TooltipDreamer = ({ userInfo, isFulfilled, allFulfilled }) => {
  const navigate = useNavigate();
  const dream = userInfo?.dreams?.[0];
  const isDreamFulfilled = fulfillCheck(allFulfilled, dream, userInfo?.id);

  return (
    <>
      <div className="tooltip-user-details-box">
        <div className="tooltip-user-details">
          <img
            src={userInfo.mainImageUrl || defaultImage}
            alt="user-img"
            className="tooltip-user-img"
          />
          <div className="tooltip-user-name-location">
            <div
              className="tooltip-name cursor-pointer"
              onClick={() => navigate(`/dashboard/public-page/${userInfo?.id}`)}
            >
              {`${userInfo.firstName || "No Name"} ${userInfo.lastName || ""}`}
            </div>
            <div className="tooltip-details">
              {userInfo.city && userInfo.country ? (
                <div className="d-flex tooltip-gap-4">
                  <Globe />
                  <div>
                    Lives in
                    <span className="tooltip-600">{` ${userInfo.city}, ${userInfo.country}`}</span>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex tooltip-gap-4">
                <Spark />
                <div>
                  Fulfilled{" "}
                  <span className="tooltip-600">{`${
                    userInfo?.donations?.length || 0
                  } Dreams`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tooltip-user-dream">
          {userInfo && userInfo?.dreams
            ? userInfo?.dreams[0]?.title
            : "No Title"}
        </div>
      </div>
      <div className="tooltip-user-buttons-box">
        <div
          className={
            isDreamFulfilled
              ? "custom-gradient sliders-button"
              : "gradient-button sliders-button"
          }
          onClick={() => {
            if (isDreamFulfilled) {
              navigate(`/dashboard/public-page/${userInfo?.id}`);
            } else {
              if (dream) {
                isFulfilled(dream.id);
              }
            }
          }}
        >
          <div className="dashboard-card-buttons-text">
            {(() => {
              return isDreamFulfilled ? "View Profile" : "Fulfill";
            })()}
          </div>
        </div>
        {/* <div className="custom-gradient sliders-button">Message</div> */}
      </div>
    </>
  );
};
export default TooltipDreamer;
