import {Row} from "react-bootstrap";
import React from "react";
import {useNavigate} from "react-router-dom";
import NavBarComponent from "../../../components/NavBar/NavBar";
import {useSelector} from "react-redux";
import { UsersModule } from "src/types/redux";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const user = useSelector((state: UsersModule) => state.usersModule).user;

  return (
    <div>
      {user?.id ? (
        <NavBarComponent />
      ) : (
        <header className="login-header">
          <Row className="p-0 m-0 d-flex w-100 align-items-center">
            <div className="d-flex justify-content-between">
              <div className="d-flex ms-5">
                <div
                  className="fa-logo-icon cursor-pointer"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>
          </Row>
        </header>
      )}
      <div className="mt-4">
        <div className="d-flex justify-content-center mb-5">
          <h1 className="text-black fw-bold">Terms of use Dreamerz</h1>
        </div>
        <div className="terms-padding">
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Welcome to Dreamerz!</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              By using Dreamerz you agree to these terms. These are Dreamerz
              terms of use, and they apply to all users of the Dreamerz
              platform. “We,” “our” or “us” refers to Dreamerz Group LLC.
              “Dreamerz” refers to this platform and the services offered by us.
              By using Dreamerz you agree to these terms and to the other
              policies we post. Please read them carefully and let us know if
              you have any questions. For information about our data practices,
              please see our Privacy Policy. We can collect and use your
              information in accordance with those policies.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Your account</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              You must be at least 13 years old to register for an account. You
              are responsible for your account. When you create an account you
              must provide us with accurate information, in good faith, and you
              agree to keep your information updated if it changes. If you are
              under 13 years of age (16 in Europe), you are not authorized to
              use the Services, with or without registering. In addition, if you
              are under the age of majority in you jurisdiction (typically 18
              years of age), you may use Dreamerz, only with the approval of
              your parent or guardian. You are responsible for anything that
              occurs when anyone is signed in to your account, as well as the
              security of the account. Please contact us immediately if you
              believe your account is compromised. You can learn more about
              security on our Security Policy page.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Abusive conduct</h3>
            <p className=" text-dark" style={{ fontSize: "16px" }}>
              You are responsible for all activity on your account. If you
              violate our policies we may terminate your account. Don’t do
              anything illegal, abusive towards others, or any actions which may
              technically abuse our site. You can find more detailed information
              in the Security Policy. These policies cover most issues, but if
              you find a new and creative way to hurt Dreamerz or our community
              we may take action to prevent it. Dreamerz reserves the right to
              refuse, or suspend any Dream fulfillment or Wings Donations that
              we believe in our sole discretion may violate these Terms of Use
              or harm the interests of our Users, public or Dreamerz. Be
              responsible and don’t violate our policies.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Being a Dreamer</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              A Dreamer is a person who has its own dream mentioned and
              described clearly on the platform, that has already subscribed
              with one of the three packages and started to fulfill other dreams
              with sharing only one $tar (dollar) for the dream. All the dreams
              that you will fulfill will be collected on your profile and it
              will give you the option to double the amount for your dream. The
              more you give, the more you receive.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Membership</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              To become a Dreamer, you have to set your dream, describe it
              clearly for all users, set the amount for the dream that you
              expect to collect, upload photoes for the dream and start the
              fulfillment campaign. For fulfilling your dream you have to choose
              one of three monthly subscription packages and start helping
              others to fulfill their dreams by sharing one $tar (dollar) for a
              dream. The subscription packages a based on the monthly payment,
              and the difference between this packages are just number of the
              dreams that you plan to fulfill and the price of this packages for
              a month. The $tars that you will share for other dreams will be
              collected as fulfilled dreams on your profile, the number of
              fulfilled dreams that you will collect will give you the option to
              double the amount for your dream. Before entering the amount for
              your dream you must first think carefully about how much would you
              like to give to others and then get double back for your dream.
              The more you give, the more you recieve ! The dreams on the
              platform vary and we have limited control over the quality and
              specific dreams. We attempt to screen for fraudulent Dreamerz
              pages, but cannot guarantee the identity or the validity of any
              claims they make. We appreciate your help reporting suspicious
              Dreamerz pages so we can keep our platform safe.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Payments</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              As a Dreamer you make your membership available choosing one of
              the three subscription packages on Dreamerz. We handle payments
              issues such as fraud, chargebacks and resolution of payments
              disputes. We may also block or hold payments for violations of our
              policies or for compliance reasons, including collecting tax
              reporting information. When payments are delayed or blocked we try
              to communicate the reason to you promptly. If you have questions
              about a payments block, please reach out to us.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold  mb-2">Fees</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              As a Dreamer there are two fees associated with your membership on
              Dreamerz. The first is the platform fee, which varies depending on
              the subscription package that you have chosed. The second is the
              payment processing fee in US Dollars is 2.9 % plus $0.30 per
              successful monthly subscription payment. Depending on your
              location, some banks may charge you a foreign transaction fee for
              their subscription. Dreamerz does not control this charge, but it
              is typically around 3.0% .
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Tax</h3>
            <p className=" text-dark" style={{ fontSize: "16px" }}>
              You are responsible for reporting any income or withholding taxes
              which may be due as a result of payments received. It is solely
              your responsibility to assess, collect, report or remit the
              correct tax, if any, to the appropriate tax authority.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Being a Dream Angel</h3>
            <p className=" text-dark" style={{ fontSize: "16px" }}>
              A Dream Angel is a person who just fulfill's others dreams and
              share for wings donations. To become a Dream Angel, simply create
              an account, subscribe with one of the three packages and start to
              fulfill other dreams with sharing only one $tar (dollar) for the
              dream. The subscription packages a based on the monthly payment
              and it can be changed or refreshed whenever you want to. All the
              dreams that you fulfilled will be collected on your profile for
              your future dream. Dream Angel can easily become a Dreamer with
              just creating his own dream and start fulfilling it. The dreams on
              the platform vary and we have limited control over the quality and
              specific dreams. We attempt to screen for fraudulent Dreamerz
              pages, but cannot guarantee the identity or the validity of any
              claims they make. We appreciate your help reporting suspicious
              Dreamerz pages so we can keep our platform safe.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Dreams</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              To set a dream you just have to describe it clearly for all the
              Dreamers and Dream Angels, set the amount for the dream that you
              expect to collect, upload photoes for the dream and start the
              fulfillment campaign. All the sharing ammount for all the dreams
              are just one $tar (dollar) for a dream. The dreams can be edited
              and then verifiyed and accepted, or sent back for some
              modifications by Dreamerz. For cashing out your dream amount, you
              have to collect a half of the sum by fulfilling other members
              dreams. As a administration costs Dreamerz will charge for every
              fulfilled dream a 10% fee from the whole amount collected to a
              Dreamer.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Wings Donations</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              Wings Donations are campaignes organized by Dreamerz with the aim
              of charity, for helping people from all over the world. For every
              donation you can share only one $tar (dollar) and the Wings
              Donations are not collecteble for Dreamerz and Dream Angels, so
              the donations will be maid without anny expectaitions. As a
              administration costs Dreamerz will charge for every collected
              Wings Donations a 10% fee from the whole amount.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">
              Member Account, Password and Security
            </h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              You are responsible for maintaining the confidentiality of your
              password and account, if any, and are fully responsible for any
              and all activities that occur under your password or account. You
              agree to notify immediately Dreamerz, of any unauthorized use of
              your password or account or any other breatch of security.
              Dreamerz will not be liable for any loss or damage arising from
              your failure to comply with this Section.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Account deletion</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              You can delete your account if you like to. We can disable your
              account at our discretion. We can also cancel any membership
              subscriptions and remove any descriptions, comments or benefits at
              our discretion. You may not bring a claim against us for
              suspending or terminating another person’s account, and you agree
              you will not bring such a claim. These terms remain in effect even
              if you no longer have an account.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Your third-party apps</h3>
            <ul className="text-dark p-0" style={{ fontSize: "16px" }}>
              You may grant Dreamerz access to your third-party accounts, such
              as Facebook, Instagram in order for some Dreamerz features to
              operate. Each time you connect your third-party account, that
              third-party account will present a page that describes the
              information that Dreamerz can access. At any time, you can revoke
              Dreamerz's access to those accounts using the respective third
              party’s security settings page. These are the links for each
              service:
            </ul>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              Facebook
              <a href="src/pages/Public/pages/About/TermsAndConditions#">
                {" "}
                <u>Terms of Service Privacy Policy Revoke Dreamerz's Access</u>
              </a>
            </li>
            <li className="text-dark p-0 ms-2" style={{ fontSize: "16px" }}>
              Instagram
              <a href="src/pages/Public/pages/About/TermsAndConditions#">
                {" "}
                <u>Terms of Service Privacy Policy Revoke Dreamerz's Access</u>
              </a>
            </li>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Dreamerz's creations</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              You can use our copyrights or trademarks to promote your Dreamerz
              page, but can’t use them for anything else without our permission.
              Our creations are protected by copyright, trademark and trade
              secret laws. Some examples of our creations are the text on the
              site, our logo, and our codebase. We grant you a license to use
              our logo and other copyrights or trademarks to promote your
              Dreamerz page. You may not otherwise use, reproduce, distribute,
              perform, publicly display or prepare derivative works of our
              creations unless we give you permission in writing.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Indemnity</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              If we are sued because of your use of or conduct on Dreamerz, you
              have to help pay for it. You will indemnify us from all losses and
              liabilities, including legal fees, that arise from these terms or
              relate to your use of Dreamerz. We reserve the right to exclusive
              control over the defense of a claim covered by this clause. If we
              use this right then you will help us in our defense. Your
              obligation to indemnify under this clause also applies to our
              subsidiaries, affiliates, officers, directors, employees, agents
              and third party service providers.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Dreamerz's creations</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              You can use our copyrights or trademarks to promote your Dreamerz
              page, but can’t use them for anything else without our permission.
              Our creations are protected by copyright, trademark and trade
              secret laws. Some examples of our creations are the text on the
              site, our logo, and our codebase. We grant you a license to use
              our logo and other copyrights or trademarks to promote your
              Dreamerz page. You may not otherwise use, reproduce, distribute,
              perform, publicly display or prepare derivative works of our
              creations unless we give you permission in writing.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Warranty disclaimer</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              We do our best to make sure Dreamerz works as expected, but stuff
              happens. Dreamerz is provided “as is” and without warranty of any
              kind. Any warranty of merchantability, fitness for a particular
              purpose, non-infringement, and any other warranty is excluded to
              the greatest extent permitted by law. The disclaimers of warranty
              under this clause also apply to our subsidiaries, affiliates and
              third party service providers.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Limit of liability</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              If you lose money as a result of using Dreamerz, any payment to
              you is limited to the amount of money we have earned through your
              use of Dreamerz. To the extent permitted by law, we are not liable
              to you for any incidental, consequential or punitive damages
              arising out of these terms, or your use or attempted use of
              Dreamerz. To the extent permitted by law, our liability for
              damages is limited to the amount of money we have earned through
              your use of Dreamerz. We are specifically not liable for losses
              associated with unfulfilled benefits and from losses caused by
              conflicting contractual agreements. For this clause “we” and “our”
              are defined to include our subsidiaries, affiliates, officers,
              directors, employees, agents and third party service providers.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Dispute resolution</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              If you have a problem please let us know. Any disputes with us
              must be resolved in Lewes under Delaware law.We encourage you to
              contact us if you have an issue. If a dispute does arise out of
              these terms or in relation to your use of Dreamerz, then the
              dispute will be resolved in the federal or state courts located in
              Lewes, Delaware. Both parties consent to the exclusive
              jurisdiction and venue of the Lewes courts for the purpose of
              resolving any such dispute.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-black fw-bold mb-2">Everything else</h3>
            <p className="text-dark" style={{ fontSize: "16px" }}>
              These terms are the final word on Dreamerz's policies, and we will
              let you know if there are any changes. These terms and any
              referenced policies are the entire agreement between you and us,
              and supersede all prior agreements. If any provision of these
              terms is held to be unenforceable, then that provision is modified
              to the extent necessary to enforce it. If a provision cannot be
              modified, it is severed from these terms, and all other provisions
              remain in force. If either party fails to enforce a right provided
              by these terms, it does not waive the ability to enforce any
              rights in the future. We may sometimes make changes to these terms
              and policies. If we make material changes that adversely affect
              your rights, then we will let you know before the changes come
              into effect. Continuing to use Dreamerz after a change means you
              accept the new terms or policies. These terms are an agreement
              with Dreamerz Group LLC., 16192 Coastal Highway Lewes, Delaware.
              If you use accessibility tools and have questions or concerns,
              please contact us at{" "}
              <a href="src/pages/Public/pages/About/TermsAndConditions#">
                <u>accessibility@dreamerz.net</u>
              </a>
              .<br />
              Effective immediately for users on Dreamerz from July 20 th, 2022.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditions;
