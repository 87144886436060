import { Dropdown, Image } from "react-bootstrap";
import defaultImage from "../../assets/images/defaultUser.png";
import React, { useEffect, useRef, useState } from "react";
import { getSearchedUsers } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { closeIcon } from "../../consts/SvgIcon";
import { SearchBarProps } from "src/types/components-props";

const SearchBar = ({
  user,
  mobile,
  allUsers,
  setAllUsers,
  inputValue,
  setInputValue,
  setShowCanvas,
  ...props
}: SearchBarProps) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(null);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        if (e.target.value) {
          getSearchedUsers(e.target.value).then((res) => {
            return setAllUsers([...res.data]);
          });
        } else {
          setAllUsers([]);
        }
      }, 1000)
    );
  };
  const goToUser = (url) => {
    mobile && setShowCanvas(false);
    setShow(false);
    setInputValue("");
    setAllUsers([]);
    navigate(url);
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (show && ref.current && !ref.current.contains(e.target as Node)) {
        if (ref2.current && !ref2.current.contains(e.target as Node)) {
          setAllUsers([]);
          setShow(false);
          setInputValue("");
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [show]);

  return (
    <Dropdown {...props}>
      <div ref={ref} className="lh-1" onClick={() => setShow(true)}>
        <input
          style={{ width: "100%" }}
          type="text"
          placeholder={mobile ? "Type what you’re looking for" : "Search..."}
          className={`border-none ${
            mobile ? "search-nav-input-mobile" : "search-nav-input"
          }`}
          value={inputValue}
          onChange={(e) => handleChange(e)}
        />
        {mobile && (
          <div
            className="as-read"
            style={{ position: "absolute", right: "8px", top: "8px" }}
            onClick={() => {
              setInputValue("");
              setAllUsers([]);
            }}
          >
            {closeIcon()}
          </div>
        )}
      </div>
      <Dropdown.Menu
        show={show}
        rootCloseEvent="click"
        style={mobile ? { width: "100%" } : { width: "300px" }}
        className="rounded-2 sub-drop pb-2 mt-2 shadow-card border-0"
      >
        <div
          ref={ref2}
          style={{ width: "98%", maxHeight: "300px" }}
          className="overflow-auto"
        >
          {allUsers?.length ? (
            allUsers
              ?.filter((dr) => dr?.id !== user.id)
              .map((el) => {
                return (
                  <div
                    style={
                      allUsers?.length === 1
                        ? { cursor: "pointer" }
                        : {
                            borderBottom: "1px solid #F2F2F2",
                            cursor: "pointer",
                          }
                    }
                    onClick={() => goToUser(`/dashboard/public-page/${el?.id}`)}
                    className="d-flex align-items-center justify-content-start w-100 p-1 ps-3"
                    key={el?.id}
                  >
                    <div className="me-1 d-flex align-items-center">
                      <Image
                        className="rounded-circle avatar-40"
                        style={{ objectFit: "cover" }}
                        src={el?.mainImageUrl || defaultImage}
                        alt="user image"
                      />
                    </div>
                    <div className="ms-1">
                      <h6 className="mb-0 font-hover lh-1 text-dark">
                        {el?.firstName || "No name"} {el?.lastName}
                      </h6>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="d-flex justify-content-center align-items-center p-2">
              <h6>No results...</h6>
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default SearchBar;
