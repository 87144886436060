import React from "react";
import { ReactComponent as Like } from "../../assets/images/socials/like.svg";
// import { ReactComponent as Send } from "../../assets/images/socials/send.svg";
import { ReactComponent as Comment } from "../../assets/images/socials/comment.svg";
// import { ReactComponent as Save } from "../../assets/images/socials/save.svg";
import "../../styles/Dreams.css";
import { useNavigate } from "react-router-dom";

export default function PublicSocials({ likesCount, commentCount }) {
  const navigate = useNavigate();
  return (
    <div
      className="public-dream-social-parts d-flex"
      style={{ marginTop: "12px" }}
    >
      <div className="dream-social-action d-flex justify-content-between">
        <div className="newsfeed-socials-left">
          <Like
            fill={"white"}
            stroke={"#828282"}
            className={"like-icon-default-styles"}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/login")}
          />
          <Comment
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/login`)}
          />
          {/* <Send
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
          /> */}
        </div>
        <div className="newsfeed-socials-right">
          {/* <Save
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
          /> */}
        </div>
      </div>
      <div className="dream-stats-row d-flex">
        <div className="d-flex dream-profile-first-container">
          <p className="fw-bold">{likesCount || 0}</p>
          <p>Likes</p>
        </div>
        <div className="d-flex dream-profile-second-container">
          <p className="fw-bold">{commentCount || 0}</p>
          <p>Comments</p>
        </div>

        {/* <div className="d-flex dream-profile-third-container">
          <p>Saved</p>
          <p>0 times</p>
        </div> */}
      </div>
    </div>
  );
}
