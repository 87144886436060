import { useState, useCallback } from 'react';

const useSharedTab = () => {
  const [tab, setTab] = useState('third');
  const setTabCallback = useCallback((e) => {
    setTab(e);
    window.scrollTo(0, 0);
  }, []);

  return { tab, setTabCallback };
};

export default useSharedTab;
