import { Link, useNavigate } from "react-router-dom";
import { Carousel, ProgressBar } from "react-bootstrap";
import defaultImage from "../../../assets/images/defaultUser.png";
import coverImg from "../../../assets/images/coverImg.png";
import moment from "moment";
import React, { useState } from "react";
import { setPublicReceived } from "../../../store/users";
import { useDispatch, useSelector } from "react-redux";
import { getPublicDreamDonations } from "../../../services/api";
import DreamMakersModal from "../../DreamMakersModal";
import "../../../styles/Sliders.css";
import { UsersModule } from "../../../types/redux";
import { calculateDreamProgress } from "../../../utils/calculateDreamProgress";
import useIsMobile from "../../../hooks/useIsMobile";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "src/components/Tooltip/TooltipDetails";
import { fulfillCheck } from "src/utils/fulfillCheck";

const DreamerFulfilledCard = ({ dream, isFulfilled, allFulfilled }) => {
  const progress = calculateDreamProgress(dream);
  const isDreamFulfilled = fulfillCheck(allFulfilled, dream, dream?.user?.id);
  const tooltipKey = generateTooltipKey(dream?.user?.id);
  const isMobile = useIsMobile(768);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;
  const progressClick = () => {
    dispatch(setPublicReceived({}));
    setShow((prev) => !prev);
    getPublicDreamDonations(dream?.id, 1).then((res) => {
      dispatch(setPublicReceived(res?.data));
    });
  };

  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img}>
        <img src={img} className="dreamer-fulfilled-image" alt="img" />
      </Carousel.Item>
    );
  };

  return (
    <div className="profile-fulfilled-dream-card shadow-card rounded-3">
      <div className="profile-fulfilled-dream-card-header">
        <Link to={`/dashboard/public-page/${dream?.user?.id}`}>
          <img
            src={dream?.user?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="dreamer-fulfill-avatar"
          />
        </Link>
        <div className="dreamer-fulfill-name-and-date">
          <Link to={`/dashboard/public-page/${dream?.user?.id}`}>
            <span
              className="dream-card-name name-overflow-cards text-underline"
              data-tooltip-id={tooltipKey}
            >
              {`${dream?.user?.firstName || "No Name"} ${
                dream?.user?.lastName || ""
              }`}
            </span>
          </Link>
          <p className="dream-card-time-ago" style={{ marginTop: "-5px" }}>
            {moment(dream?.createdAt)?.fromNow(true)} ago
          </p>
        </div>
        <TooltipDetails
          userId={dream?.user?.id}
          isFulfilled={isFulfilled}
          allFulfilled={allFulfilled}
          tooltipKey={tooltipKey}
        />
      </div>
      <Carousel
        indicators={false}
        interval={null}
        controls={dream?.images?.length > 1}
      >
        {dream.images?.length
          ? dream.images?.map((img) => carouselItem(img?.avatarUrl))
          : carouselItem(coverImg)}
      </Carousel>
      <div className="dream-card-main-container">
        <div
          className="text-dark cursor-pointer"
          style={{ width: "-webkit-fill-available" }}
        >
          <span className="my-span lh-sm dream-description">
            {dream?.title || dream?.user?.dreams?.[0]?.title || "No Title"}
          </span>
        </div>
        <div>
          <div
            className="dream-fulfill-card-progress-container"
            style={{ gap: "14px" }}
          >
            <ProgressBar
              onClick={() => progressClick()}
              style={{ width: "inherit", height: "10px", cursor: "pointer" }}
              now={progress}
              variant={"gradient"}
              className={"black-hover"}
            />
            <p className="dream-percentage">
              {isNaN(progress) ? 0 : progress}%
            </p>
          </div>
          {publicReceived?.results?.length > 0 && (
            <DreamMakersModal
              donations={publicReceived}
              show={show}
              setShow={setShow}
              dreamId={dream?.id}
              card={true}
            />
          )}
          {!isMobile && (
            <div className="dream-fulfillment-info">
              <div className="dream-fulfillment-container">
                <p>Fulfilled</p>
                <p>{dream?.donations || "0"}</p>
              </div>
              <div className="dream-fulfillment-container">
                <p>Received</p>
                <p>{dream?.amountReceived || "0"}</p>
              </div>
            </div>
          )}
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div
            className="dashboard-card-buttons custom-gradient"
            style={{ width: "100%" }}
            onClick={() =>
              isDreamFulfilled
                ? navigate(`/dashboard/public-page/${dream?.user?.id}`)
                : isFulfilled(dream?.id)
            }
          >
            <div className="dashboard-card-buttons-text">
              <span>
                {isDreamFulfilled
                  ? "View Profile"
                  : `Fulfill ${
                      dream?.user?.gender === "FEMALE" ? "Her" : "His"
                    } Dream`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamerFulfilledCard;
