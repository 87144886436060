import { useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";

export const useRoleCheck = (users) => {
  const isAdminChecked = useSelector(
    (state: UsersModule) => state.usersModule
  ).admins;

  if (users && users.role === "DREAMER") {
    return true;
  } else if (users && users.role === "ANGEL") {
    return false;
  } else if (users && users.role === "ADMIN") {
    const admin = isAdminChecked.find((user) => user.id === users.id);
    if (admin) {
      return admin.hasDream;
    }
  }
};
