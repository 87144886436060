import React from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/mobile.css";

//swiper
import SwiperCore, { Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";
import loader from "../../assets/images/page-img/page-load-loader.gif";
//services
import { getUser, login, verifyEmail } from "../../services/api";
import { setDream, setIsAuth, setShowLoader, setUser } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";

//img
import "../../styles/dreamerzLogin.css";
import "../../styles/Dashboard.css";
import { toast } from "react-toastify";
import PublicNavBar from "../../components/NavBar/PublicNavBar";
import { UsersModule } from "src/types/redux";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Login = () => {
  const notify = (message) => toast.warning(message);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;

  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(setShowLoader(true));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profileId");
    dispatch(setUser({}));
    dispatch(setDream({}));
    const { email, password } = event.target.elements;
    if (!email.value || !password.value) {
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Please fill in Password and Email in order to Sign In");
      dispatch(setShowLoader(false));
    } else {
      login({ email: email.value, password: password.value }).then(
        (response) => {
          if (!response) {
            return;
          }
          dispatch(setIsAuth(true));
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          getUser().then((res) => {
            if (res?.data?.isEmailConfirmed) {
              localStorage.setItem("profileId", res.data.id);
              navigate("/newsfeed");
            } else {
              dispatch(setIsAuth(false));
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("profileId");
              verifyEmail(res?.data?.email);
              toast.dismiss();
              toast.clearWaitingQueue();
              notify("Please check your Email");
              dispatch(setShowLoader(false));
            }
          });
        }
      );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <PublicNavBar />
      <div className="d-flex flex-column justify-content-around align-items-center padding-60">
        <h1 className="window-text text-center">Become a dreamer Now!</h1>
        <div className="login-form-container">
          <div style={{ padding: "35px" }}>
            <div className="form-header">
              <h1 className="mb-3">Sign in</h1>
              <h3>Enter your email or password to get full access.</h3>
            </div>
            <div className="form-body">
              <Form className="mt-2" onSubmit={handleLogin}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <h3>Email address</h3>
                  </Form.Label>
                  <Form.Control
                    style={{ color: "black" }}
                    type="email"
                    className="mb-0 text-dark"
                    id="email"
                    placeholder="Enter email"
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>
                    <h3>Password</h3>
                  </Form.Label>
                  <Link to="/forgot-password" className="float-end mt-1">
                    Forgot password?
                  </Link>
                  <Form.Control
                    type="password"
                    className="mb-0 text-dark"
                    id="password"
                    placeholder="Password"
                    style={{ color: "black" }}
                  />
                  <Form.Check className="d-inline-block mt-2 pt-1">
                    <Form.Check.Input
                      type="checkbox"
                      className="me-2"
                      id="customCheck11"
                    />
                    <Form.Check.Label style={{ paddingTop: "3px" }}>
                      Remember Me
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
                <div className="d-flex flex-column justify-content-start align-content-around w-100">
                  <button
                    className="dashboard-card-buttons custom-gradient p-0 mb-2  "
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    <span className="dashboard-card-buttons-text">Sign in</span>
                  </button>
                  {showLoader && (
                    <div className="d-flex justify-content-center w-100">
                      <img
                        src={loader}
                        alt="loader"
                        style={{ height: "50px" }}
                      />
                    </div>
                  )}
                </div>
                <div
                  onClick={() => navigate("/register")}
                  style={{
                    textAlign: "center",
                    marginBottom: "1rem",
                    cursor: "pointer",
                  }}
                >
                  <span className="dark-color d-inline-block line-height-2">
                    Don't have an account ?
                    <span className="text-blue"> Sign up</span>
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
