import { Card } from "react-bootstrap";
import React from "react";
import { useParams } from "react-router-dom";
import { getAngelNewsfeedPostById } from "../../services/api";
import Layout from "../Public/components/Layout";
import DreamAngelPost from "../../components/PublicandInternShared/DreamAngelPost";
import FulfilledDreamsCarousel from "../../components/PublicandInternShared/FulfilledDreamsCarousel";
import { useCheckIsAuth } from "../../hooks/useCheckIsAuth";
import { setBgLoader } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/pages/AngelPage.css";
import PublicPostComments from "src/components/PublicandInternShared/PublicPostComments";
import { commentType } from "src/consts/commentsTypeEnum";
import { useQuery } from "@tanstack/react-query";
import PublicSocials from "src/components/Partials/PublicSocials";
import { AngelCommentData } from "src/types/redux";

const SharedDreamAngelPage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const { data: angelData, isLoading: angelIsLoading } = useQuery({
    queryKey: ["publicDreamAngelData", userId],
    queryFn: () => getAngelNewsfeedPostById(userId).then((res) => res?.data),
    enabled: !!userId,
  });

  const token = useCheckIsAuth(
    `/dashboard/angel/${angelData?.id}`,
    angelData?.user?.id
  );

  const angelComments = useSelector(
    (state: AngelCommentData) =>
      state.comments.angelComments[angelData?.id] || {}
  );

  if (angelIsLoading) {
    dispatch(setBgLoader(true));
    return <></>;
  }

  if (token) return <div />;

  return (
    <Layout>
      <div className="angel-page-card mt-2 mb-5">
        <Card className="rounded-3 shadow m-0 mobile-padding pb-0">
          <Card.Body className="p-0">
            <DreamAngelPost post={angelData} />
            <PublicSocials
              commentCount={angelComments.count}
              likesCount={angelData?.likedNewsFeedCount}
            />
            <PublicPostComments type={commentType.angel} dreamId={userId} />
          </Card.Body>
        </Card>
      </div>
      <FulfilledDreamsCarousel userId={angelData?.user?.id} />
    </Layout>
  );
};
export default SharedDreamAngelPage;
