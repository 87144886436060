import React, { useState } from "react";
import { Carousel, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileWingDonations,
  getPublicWingDonations,
  getUser,
  wingDonation,
} from "../../../services/api";
import {
  setProfileWingDonations,
  setPublicWingDonations,
  setUser,
} from "../../../store/users";
import { toast } from "react-toastify";
import PricingModal from "../../../pages/Pricing/PricingModal";
import { UsersModule } from "../../../types/redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import coverImg from "../../../assets/images/coverImg.png";

const WingFulfilledCard = ({ dream, params }) => {
  const navigate = useNavigate();
  const notify = (message) => toast.success(message);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const progress = Math.round(
    (dream?.charity?.amountReceived * 100) / dream?.charity?.amount
  );
  const dispatch = useDispatch();
  const [customModals, setCustomModal] = useState("");
  const wingDonationsProfile = useSelector(
    (state: UsersModule) => state.usersModule
  ).profileWingDonations;

  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img}>
        <img src={img} className="wing-fulfil-image-style" alt="img" />
      </Carousel.Item>
    );
  };

  const handleWingDonation = (id) => {
    if (!user.balance) {
      setCustomModal("priceModal");
    } else {
      wingDonation(id).then((res) => {
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getProfileWingDonations(1).then((res) => {
          dispatch(setProfileWingDonations(res.data));
        });
        getUser().then((response) => {
          dispatch(setUser(response?.data));
        });
        if (params?.id) {
          getPublicWingDonations(params.id, 1).then((res) => {
            dispatch(setPublicWingDonations(res?.data?.results));
          });
        }
      });
    }
  };
  return (
    <div className="charity-profile-fulfilled-card shadow-card rounded-3">
      <PricingModal
        customModals={customModals}
        setCustomModal={setCustomModal}
      />
      <div className="gradient-bg-charity w-100">
        <span className="text-dark">🕊 Wings Donation</span>
      </div>
      <div className="charity-profile-fulfilled-header">
        <div
          className="charity-profile-fulfilled-name name-overflow-cards text-underline cursor-pointer"
          onClick={() => navigate(`/wing-donation/${dream?.charity?.id}`)}
        >
          {dream?.charity?.title || "No Name"}{" "}
        </div>
        <div className="charity-profile-fulfilled-time">
          {moment(`${dream?.createdAt}`)?.fromNow(true)} ago
        </div>
      </div>
      <div>
        <Carousel
          indicators={false}
          interval={null}
          controls={dream?.charity.images?.length > 1}
        >
          {dream?.charity.images?.length
            ? dream?.charity?.images?.map((img) => carouselItem(img?.avatarUrl))
            : carouselItem(coverImg)}
        </Carousel>
      </div>
      <div className="charity-profile-fulfilled-container">
        <div className="text-dark">
          <span style={{ height: "35px" }} className="my-span lh-sm">
            {dream?.charity?.description}
          </span>
        </div>
        <div>
          <div className="dream-progress-container" style={{ gap: "14px" }}>
            <ProgressBar
              style={{ width: "inherit", height: "10px", cursor: "pointer" }}
              now={progress}
              variant={"gradient"}
              className={"black-hover"}
            />
            <p className="dream-percentage">
              {isNaN(progress) ? 0 : progress}%
            </p>
          </div>
          <div className="charity-profile-fulfilled-received">
            Received
            <span className="fw-bold">{dream?.charity?.amountReceived}</span>
          </div>
        </div>
        <div
          className="dashboard-card-buttons custom-gradient w-100"
          style={{ height: "40px", margin: "0" }}
          onClick={() =>
            wingDonationsProfile?.results?.find(
              (d) => d.charity?.id === dream?.charity?.id
            )
              ? navigate(`/wing-donation/${dream?.charity?.id}`)
              : handleWingDonation(dream?.charity?.id)
          }
        >
          <div className="dashboard-card-buttons-text">
            <span>
              {wingDonationsProfile?.results?.find(
                (d) => d.charity.id === dream?.charity?.id
              )
                ? "View"
                : "Donate"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WingFulfilledCard;
