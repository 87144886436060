import React, { useEffect, useState } from "react";
import "../../styles/mobile.css";
import "../../styles/carousel-mobile.css";
import "../../styles/landing-page.css";
import { logo } from "../../consts/SvgIcon";
import { useNavigate } from "react-router-dom";

import "react-multi-carousel/lib/styles.css";
import GradientButton from "../../components/Buttons/GradientButton";
import MainTitle from "src/components/LandingPageComponents/MainTitle";
import VideoAboutDreamerz from "src/components/LandingPageComponents/VideoAboutDreamerz";
import StepsSection from "src/components/LandingPageComponents/StepsSection";
import CarouselSection from "src/components/LandingPageComponents/CarouselSection";
import DreamerOrAngelSection from "src/components/LandingPageComponents/DreamerOrAngelSection";
import SecuredDonationSection from "src/components/LandingPageComponents/SecuredDonationSection";
import WingDonationSection from "src/components/LandingPageComponents/WingDonationSection";
import ProjectPurposeSection from "src/components/LandingPageComponents/ProjectPurposeSection";
import TeamTestimonialsSection from "src/components/LandingPageComponents/TeamTestimonialsSection";
import CarouselPurposeSection from "src/components/LandingPageComponents/CarouselPurposeSection";
import QuestionsSection from "src/components/LandingPageComponents/QuestionsSection";
import LiveYourDreamSection from "src/components/LandingPageComponents/LiveYourDreamSection";

const LandingPage = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY !== 0) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div className="landing-page-container">
      <div>
        <div className="main-background-image">
          <div
            className={`${show ? "nav-landing" : "nav-landing-white"} w-100`}
          >
            <div>{logo()}</div>
            <div className="d-flex landing-nav-buttons-container">
              <a
                className={
                  show
                    ? "custom-gradient-button-space"
                    : "custom-gradient-button-white"
                }
                style={{
                  minWidth: "115px",
                }}
                href="/login"
              >
                <span
                  className={`custom-gradient-button-space-text ${
                    show ? "" : "text-color-black"
                  } `}
                >
                  Log In
                </span>
              </a>
              <GradientButton
                onClick={() => navigate("/register")}
                text="Sign Up"
                minWidth={"115px"}
                textClassName="gradient-sing-up-text"
                className={`gradient-button ${
                  show ? "gradient-button" : "landing-sign-up-btn"
                }`}
              />
            </div>
          </div>
          <MainTitle />
        </div>
        <VideoAboutDreamerz />
        <CarouselPurposeSection />
        <StepsSection />
        <CarouselSection />
        <DreamerOrAngelSection />
        <SecuredDonationSection />
        <WingDonationSection />
        <ProjectPurposeSection />
        <TeamTestimonialsSection />
        <LiveYourDreamSection />
        <QuestionsSection />
      </div>
    </div>
  );
};
export default LandingPage;
