import React from "react";
import Reminder from "./Reminder";
import Wing from "./Wing";
import Interaction from "./Interaction";
import Commented from "./Commented";
import { getNotification, patchNotificationById } from "../../services/api";
import { setNotifications } from "../../store/users";
import { useDispatch } from "react-redux";
import {
  commentedTypes,
  othersTypes,
  reminderTypes,
  wingTypes,
} from "../../consts/notifications";

const Index = ({ el, setShow }) => {
  const dispatch = useDispatch();

  const changeNotificationSeenState = () => {
    !el?.seen &&
      patchNotificationById(el.id).then(() =>
        getNotification(1).then((r) =>
          dispatch(setNotifications(r?.data?.results))
        )
      );
  };

  return (
    <div onClick={changeNotificationSeenState}>
      {Object.keys(reminderTypes).includes(el.type) && (
        <Reminder el={el} setShow={setShow} />
      )}
      {Object.keys(wingTypes).includes(el.type) && (
        <Wing el={el} setShow={setShow} />
      )}
      {Object.keys(commentedTypes).includes(el.type) && (
        <Commented el={el} setShow={setShow} />
      )}
      {Object.keys(othersTypes).includes(el.type) && (
        <Interaction el={el} setShow={setShow} />
      )}
    </div>
  );
};
export default Index;
