import React, { lazy, Suspense } from "react";
import GradientButton from "../Buttons/GradientButton";
import { useNavigate } from "react-router-dom";
import lottie from "../../assets/images/lottie.webp";
import "../../styles/landing-page.css";
const AnimationLottie = lazy(() => import("./AnimationLottie"));

const MainTitle = () => {
  const navigate = useNavigate();
  return (
    <div className="desktop-bg-space d-flex align-items-center justify-content-between">
      <div className="main-info d-flex flex-column justify-content-center">
        <div className="header-network">The Social Network For Dreams</div>
        <div className="main-title-title-container">
          <h1 className="main-title-title">
            Welcome to Dreamerz — The Place Where Dreams Come True
          </h1>
        </div>
        <p className="main-title-description">
          Realize your dreams by supporting others. Donate $1 to dreams you love
          and gain 10x visibility for yours, worldwide. Attract more donors and
          achieve your dreams faster — together!
        </p>
        <div style={{ width: "187px" }} className="main-btn-container">
          <GradientButton
            onClick={() => navigate("/login")}
            text="Start My Journey"
            style={{ height: "52px", width: "187px", fontSize: "18px" }}
            className="start-gradient-button"
            textClassName="start-gradient-button-text"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center lottie-container">
        <Suspense
          fallback={<img src={lottie} alt="My dream has come true" className="image-one" />}
        >
          <AnimationLottie />
        </Suspense>
      </div>
    </div>
  );
};

export default MainTitle;
