import { warningReminder } from "../../consts/SvgIcon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { reminderTypes } from "../../consts/notifications";

const Reminder = ({ el, setShow }) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    if (el.type) {
      navigate(`/${reminderTypes[el.type]}`);
    }
    setShow(false);
  };
  return (
    <div className="notification-item-container-reminder" onClick={navigateTo}>
      <div className="d-flex gap-2">
        <div className="yellow-reminder">{warningReminder()}</div>
        <div className="div">
          {el.type === "SUBSCRIBE_REMINDER" ? (
            <div>
              <span className="fs-5 text-dark" style={{ color: "#A8A6A6" }}>
                <b>Subscribe</b> {el?.title?.split(" ").slice(1).join(" ")}
              </span>
            </div>
          ) : (
            <span
              className={
                el.type === "ADMIN_NOTIFICATION"
                  ? "fs-5 text-black"
                  : "fs-5 text-dark"
              }
            >
              {el?.title}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default Reminder;
