import React from "react";
import defaultImage from "../../assets/images/defaultUser.png";
import { ReactComponent as Globe } from "../../assets/images/tooltip/globe.svg";
import { ReactComponent as Spark } from "../../assets/images/tooltip/spark.svg";
import { useNavigate } from "react-router-dom";

const TooltipAngel = ({ userInfo }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="tooltip-angel-img-box">
        <div className="tooltip-angel-img-border">
          <img
            src={userInfo.mainImageUrl || defaultImage}
            alt="user-img"
            className="tooltip-angel-img"
          />
        </div>
        <div>
          <div
            className="tooltip-name cursor-pointer"
            onClick={() => navigate(`/dashboard/public-page/${userInfo?.id}`)}
          >
            {`${userInfo.firstName || "No Name"} ${userInfo.lastName || ""}`}
          </div>
          <div className="tooltip-angel-badge">Dream Angel</div>
        </div>
      </div>
      <div className="tooltip-user-name-location">
        <div className="tooltip-details">
          {userInfo.city && userInfo.country ? (
            <div className="d-flex tooltip-gap-4">
              <Globe />
              <div>
                Lives in
                <span className="tooltip-600">{` ${userInfo.city}, ${userInfo.country}`}</span>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex tooltip-gap-4">
            <Spark />
            <div>
              Fulfilled{" "}
              <span className="tooltip-600">{`${userInfo.donations.length} Dreams`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="tooltip-user-buttons-box">
        <div
          className="custom-gradient sliders-button tooltip-angel-button-text"
          onClick={() => navigate(`/dashboard/public-page/${userInfo?.id}`)}
        >
          View Profile
        </div>
      </div>
    </>
  );
};
export default TooltipAngel;
