import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Inject microsoft clarity in production mode
if (process.env.REACT_APP_ENV === "prod") {
  const script = document.createElement("script");
  script.innerHTML = eval(`
    (function(c,l,a,r,i,t,y){            
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "nk2vebrn6q");
      `);
  document.getElementsByTagName("head")[0].appendChild(script);
}

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("newsfeed")) {
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    }
  }, [location]);

  return <>{props.children} </>;
};

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);
