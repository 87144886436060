import React, { useState } from "react";
import { useSelector } from "react-redux";
import useIsMobile from "../../../hooks/useIsMobile";
import BigImageModal from "../../Partials/BigImageModal";
import { ProgressBar } from "react-bootstrap";
import DreamMakersModal from "../../DreamMakersModal";
import SocialInteraction from "../../Partials/SocialInteraction";
import { SOCIALS_ENUM } from "../../../consts/socialsEnum";
import CommentsNew from "../../Comments/CommentsNew";
import defaultImage from "../../../assets/images/defaultUser.png";
import moment from "moment";
import { UsersModule } from "src/types/redux";
import { UserAboutProps } from "src/types/profile-props";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import { DreamDto } from "src/services/api/data-contracts";
import Carousel from "react-multi-carousel";

const RealizedDreams = ({ user, dream, isFulfilled }: UserAboutProps) => {
  const [isActive, setActive] = useState(false);
  const isMobile = useIsMobile(768);
  const isMobileforReceived = useIsMobile(500);
  const [dreamBigImages, setDreamBigImages] = useState(false);
  const [currentIndex, setIndex] = useState(null);

  const handleDreamBigImages = (index) => {
    setDreamBigImages(true);
    setIndex(index);
  };
  const progressBarValue = dream
    ? calculateDreamProgress(dream as DreamDto)
    : 0;

  const [show, setShow] = useState(false);
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;
  const toggleClass = () => {
    setActive(!isActive);
  };

  const responsive = {
    laptop: {
      breakpoint: { max: 5000, min: 866 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 865, min: 701 },
      items: 3.5,
    },
    mobile: {
      breakpoint: { max: 700, min: 601 },
      items: 3,
    },
    mobileS: {
      breakpoint: { max: 600, min: 501 },
      items: 2.5,
    },
    mobileXS: {
      breakpoint: { max: 500, min: 200 },
      items: 2,
    },
  };

  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  return (
    <div className="about-already-fulfilled-container">
      <div className="d-flex justify-content-between mb-3">
        <div className="about-title">Previously Realized Dreams</div>
        <div className="cursor-pointer tooltipes">
          <img src="/interact/info.svg" alt="" onClick={toggleClass} />
          {isMobile ? (
            <div
              className={isActive ? "tooltiptextes-mobile" : "tooltip-remove"}
            >
              <span>
                After your current dream is fulfilled, you’ll be able to create
                a new dream. You can only have one active dream at a time, but
                once it’s achieved, you’re free to dream again! 💫
              </span>
            </div>
          ) : (
            <div className="tooltiptextes">
              <span>
                After your current dream is fulfilled, you’ll be able to create
                a new dream. You can only have one active dream at a time, but
                once it’s achieved, you’re free to dream again! 💫
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="about-already-fulfilled-box d-flex shadow-card mb-3">
        {!isMobileforReceived ? (
          <div className="d-flex justify-content-between align-items-center">
            <div className="about-fullfilled-img-name cursor-pointer">
              <img
                src={user?.mainImageUrl || defaultImage}
                alt=""
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  objectFit: "cover",
                }}
              />
              <div className="about-fulfilled-name-box">
                {user.firstName} {user.lastName}
              </div>
            </div>
            <div className="about-custom-gradient">
              <div className="about-fulfilled-date-button ">
                <img
                  src="/interact/realized-spark.svg"
                  alt=""
                  style={{ width: "18px" }}
                />
                Realized {moment(dream.updatedAt)?.fromNow(true)} ago
              </div>
            </div>
          </div>
        ) : (
          <div className=" justify-content-between align-items-center">
            <div className="about-custom-gradient">
              <div className="about-fulfilled-date-button ">
                <img
                  src="/interact/realized-spark.svg"
                  alt=""
                  style={{ width: "18px" }}
                />
                Realized {moment(dream.updatedAt)?.fromNow(true)} ago
              </div>
            </div>
            <div className="about-fullfilled-img-name cursor-pointer">
              <img
                src={user?.mainImageUrl || defaultImage}
                alt=""
                style={{ borderRadius: "50%", width: "40px" }}
              />
              <div className="about-fulfilled-name-box">
                {user.firstName} {user.lastName}
              </div>
            </div>
          </div>
        )}
        <div className="about-fulfilled-dream-description">
          {dream.title || ""}
        </div>
        {dream.images.length > 0 && (
          <div className="pb-0 pt-0">
            {dreamBigImages && (
              <BigImageModal
                index={currentIndex}
                openBigImages={setDreamBigImages}
                dream={dream}
              />
            )}
            <div className="d-flex flex-row flex-wrap">
              <Carousel
                responsive={responsive}
                draggable
                itemClass="new-carousel-dream-item"
                className="new-carousel-dream-images"
              >
                {dream?.images?.map((img, index) => (
                  <div key={img?.id || index} className="carousel-dream-image">
                    <img
                      draggable="false"
                      src={img?.avatarUrl}
                      alt="gallery"
                      className="public-dream-photo"
                      onClick={() => handleDreamBigImages(index)}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}

        <div className="pb-0 pt-0">
          <div className="dream-photo-title">Dream Details</div>
          <div className="dream-scope-bar">
            <div className="dream-bar-box d-flex align-items-center justify-content-between">
              <ProgressBar
                className="dream-progress-bar-style"
                variant={"gradient"}
                now={progressBarValue || 0}
                onClick={() => setShow((prev) => !prev)}
              />
              <DreamMakersModal
                donations={publicReceived}
                show={show}
                setShow={setShow}
                dreamId={dream.id}
                profile={true}
              />
              <p>{progressBarValue || "0"}%</p>
            </div>
            <div className="d-flex justify-content-between dream-scope-fulfilled">
              <div className="dream-scope-boxes">
                <p>Scope</p>
                <p className="fw-bold">
                  {dream?.amount ? dream?.amount + "$" : "0$"}
                </p>
              </div>
              <div className="dream-scope-boxes">
                <p>Received</p>
                <p className="fw-bold">{dream.amountReceived}</p>
              </div>
            </div>
          </div>
          <SocialInteraction
            post={dream}
            type={SOCIALS_ENUM.PROFILE_DREAM}
          ></SocialInteraction>
        </div>
        <div className="about-comments-line-bar">
          <CommentsNew
            user={user}
            dreamId={dream.id}
            mainImg={mainImg}
            dream={dream}
            page="about"
            isFulfilled={isFulfilled}
          />
        </div>
      </div>
    </div>
  );
};
export default RealizedDreams;
