import React from "react";
import { missingData } from "../../consts/items";
import { MissingDataProps } from "src/types/profile-props";

const MissingData = ({ type, dreamId, isFulfilled }: MissingDataProps) => {
  const content = missingData.find((item) => item.type === type);
  return (
    <div className="missing-data-container">
      <div className="missing-data-top-content">
        <img src="/interact/fallingStar.svg" alt="" />
        <div className="missing-data-content-title">{content.title}</div>
      </div>
      <div className="missing-data-content">
        <p>{content.content}</p>
      </div>
      {content.button ? (
        <div
          className="missing-data-button"
          onClick={() => isFulfilled(dreamId)}
        >
          {content.button}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MissingData;
