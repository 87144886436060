import React from "react";
import { Route, Routes } from "react-router-dom";
import { navRoutes, nonNavRoutes } from "./index";
import NavBarComponent from "../components/NavBar/NavBar";
import { useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";

const AppRouter = () => {
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const NavRoute = (Component) => {
    return (
      <div>
        {!!user.id && <NavBarComponent />}
        <Component />
      </div>
    );
  };

  return (
    <Routes>
      {navRoutes.map((route) => (
        <Route
          element={NavRoute(route.component)}
          path={route.path}
          key={route.path}
        />
      ))}
      {nonNavRoutes.map((route) => (
        <Route element={route.component} path={route.path} key={route.path} />
      ))}
    </Routes>
  );
};
export default AppRouter;
