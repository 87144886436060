import React, { ForwardRefRenderFunction, useState } from "react";
import { Modal, Carousel } from "react-bootstrap";
import "../../styles/Dashboard.css";
import coverImg from "../../assets/images/coverImg.png";
import { BigImageModalProps } from "src/types/components-props";

const BigImageModal: ForwardRefRenderFunction<unknown, BigImageModalProps> = (
  props,
  ref
) => {
  const [dream] = useState(props.dream);
  const [index] = [props.index];
  return (
    <Modal
      size="lg"
      centered
      backdrop={true}
      show={!!props.openBigImages}
      onHide={() => props.openBigImages(false)}
      className="big-image-popup"
    >
      <span
        onClick={() => props.openBigImages(false)}
        className="fa-close-image modal-default-close"
      />
      <Carousel
        indicators={false}
        interval={null}
        controls={dream?.images?.length > 1}
        nextIcon={
          <span className="carousel-outside-right-button fa-rightArrow-icon" />
        }
        prevIcon={
          <span className="carousel-outside-left-button fa-leftArrow-icon" />
        }
        defaultActiveIndex={index}
      >
        {dream?.images?.length ? (
          dream?.images?.map((img) => (
            <Carousel.Item key={img.url}>
              <img
                src={img.url}
                style={{
                  width: "100%",
                  height: "588px",
                  objectFit: "contain",
                  borderRadius: "10px 0 0 10px",
                }}
                alt="Responsive"
              />
            </Carousel.Item>
          ))
        ) : (
          <img
            src={coverImg}
            className="img-fluid"
            style={{
              width: "100%",
              height: "588px",
              objectFit: "contain",
              borderRadius: "10px 0 0 10px",
            }}
            alt="Responsive"
          />
        )}
      </Carousel>
    </Modal>
  );
};

export default BigImageModal;
